import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react';
import './eds-tabs.scss';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useMatches } from 'react-router';
import { useNavigate, usePermissions } from '../../../features';
import _ from 'lodash';

export function EdsTabs({ contained = false, headerTabs = false, ...props }) {
    const { isAllowed } = usePermissions();

    const [renderedTabs] = useState(
        props.tabs.filter((tab) => {
            if (!_.isUndefined(tab.permissions)) {
                if (_.isFunction(tab.permissions)) {
                    return tab.permissions();
                } else {
                    return isAllowed(tab.permissions);
                }
            }
            return true;
        })
    );
    const [selectedIndex, setSelectedIndex] = useState(props.initTab ?? 0);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const matches = useMatches();

    let selectedTabIndex = 0;
    let handleTabChange;
    let onClick;
    let getTabPanel = (tab, index) => {
        return selectedIndex === index && <>{tab.panel}</>;
    };

    if (props.router) {
        selectedTabIndex = renderedTabs.findIndex((tab) => {
            if (
                matches.length > 0 &&
                matches[matches.length - 1]?.handle?.index &&
                tab.index
            ) {
                return true;
            }

            const foundElement = pathname.endsWith(`/${tab.slug}`);
            if (!foundElement && tab.hasChildren) {
                // try to find the slug in the rest of the URL
                const foundParentElements = pathname.split('/');
                return (
                    foundParentElements.findLastIndex(
                        (item) => item === tab.slug
                    ) !== -1
                );
            }

            return foundElement;
        });
        onClick = (tab, index) => {
            if (index !== selectedTabIndex) {
                navigate(tab.slug);
            }
        };
        getTabPanel = (_tab, index) => {
            return (
                selectedTabIndex === index && (
                    <Suspense>
                        <Outlet />
                    </Suspense>
                )
            );
        };
    } else {
        handleTabChange = (evt) => {
            setSelectedIndex(evt.selectedIndex);
        };
    }

    const getTabPanelStyle = (tab) => {
        let classes = ['eds-tab-panel'];
        if (tab.transparent) {
            classes.push('transparent');
        }
        if (contained) {
            classes.push('contained');
        }
        return classes.join(' ');
    };
    return (
        <>
            <Tabs
                selectedIndex={props.router ? selectedTabIndex : selectedIndex}
                onChange={handleTabChange}
            >
                <TabList
                    contained={contained}
                    aria-label={t(
                        '4abe8372bb94a1754e9a612da9a1b166',
                        'List of tabs'
                    )}
                    activation="manual"
                    className={headerTabs ? 'header-tabs' : ''}
                >
                    {renderedTabs.map((tab, index) => (
                        <Tab
                            renderIcon={tab.icon}
                            key={index}
                            disabled={tab.disabled}
                            onClick={() => {
                                if (onClick) {
                                    onClick(tab, index);
                                }
                            }}
                        >
                            {tab.label}
                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {renderedTabs.map((tab, index) => (
                        <TabPanel className={getTabPanelStyle(tab)} key={index}>
                            {getTabPanel(tab, index)}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </>
    );
}
