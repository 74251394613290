import { useTranslation } from 'react-i18next';
import {
    EdsFormColumn,
    EdsFormGroup,
    EdsInlineForm,
    EdsTextArea,
} from '../../../eds';
import { Row } from '@carbon/react';
import _ from 'lodash';
import {
    EdsAlarmActionType,
    EdsCaregiverTypes,
    postAlarmAction,
    useAuth,
    useNotifications,
} from '../../../../features';
import { DateTime } from 'luxon';

function ActionRemarkInlineForm({ alarmId, caregiver, onCloseCallback }) {
    const { t } = useTranslation();
    const { attributes } = useAuth();
    const { showSuccess, showError } = useNotifications();

    const formDefinition = {
        actionRemark: {
            validation: {
                required: false,
            },
        },
    };

    const handleClose = () => {
        if (_.isFunction(onCloseCallback)) {
            onCloseCallback();
        }
    };
    const onCancel = () => {
        handleClose();
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }

        const requestData = {
            id: form.id,
            timestamp: DateTime.now().toJSON(),
            operatorName: attributes?.user?.name,
            actionNumber: EdsAlarmActionType.UserDefined,
            remark: form.actionRemark,
            ...(caregiver?.type === EdsCaregiverTypes.PersonalCaregiver && {
                personalCaregiverId: caregiver?.id,
            }),
            ...(caregiver?.type === EdsCaregiverTypes.ProfessionalCaregiver && {
                professionalCaregiverId: caregiver?.id,
            }),
        };

        try {
            const response = await saveData(requestData);
            handleClose();

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '18a093083fc263a2e179b2571f604d93',
                    '{{entity}} is saved',
                    {
                        entity: t(
                            'ab8ef9e254f5b0bb5e6d1ee96e7420f9',
                            'Action remark'
                        ),
                    }
                ),
            });

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    const saveData = async (form) => {
        if (!form.id) {
            return await postAlarmAction(alarmId, form);
        }
    };

    return (
        <EdsInlineForm
            formDefinition={formDefinition}
            onCancel={onCancel}
            onSubmit={onSubmit}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsTextArea
                            name="actionRemark"
                            label={t(
                                'ab8ef9e254f5b0bb5e6d1ee96e7420f9',
                                'Action remark'
                            )}
                            helperText={t(
                                '57560e00dea420a93fa90c7343a6807d',
                                'Note and comment from the caregiver (ETA or other plans)'
                            )}
                            rows={5}
                        ></EdsTextArea>
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsInlineForm>
    );
}

export default ActionRemarkInlineForm;
