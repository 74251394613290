import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { EdsWizard, EdsWizardOrientation } from '../../../../components/eds';
import ClientSearchStep from './client-search-step';
import { useModal } from '../../../../features';
import DeviceSearchStep from './device-search-step';

export const CreateAlarmWizard = ({ modalId, onSubmit }) => {
    const formRef = useRef(null);
    const deviceTableRef = useRef(null);
    const wizardRef = useRef(null);
    const { remove } = useModal();
    const [selectedClient, setSelectedClient] = useState({});

    useEffect(() => {
        deviceTableRef.current?.fetchInitData();
    }, [selectedClient]);

    return (
        <EdsWizard
            orientation={EdsWizardOrientation.Horizontal}
            onCancelClick={() => {
                remove(modalId);
            }}
            onSubmit={async (_event, form, isValid) => {
                if (!isValid) {
                    return false;
                }

                let deviceId = form.device?.deviceId;

                const rowData = deviceTableRef?.current?.getRowData(deviceId);
                const deviceCode = rowData?.deviceCode;
                const residenceId = rowData?.residenceId;

                if (_.isFunction(onSubmit)) {
                    const response = await onSubmit(
                        {
                            ...form,
                            deviceCode,
                            residenceId,
                        },
                        isValid
                    );
                    remove(modalId);
                    return response;
                } else {
                    remove(modalId);
                    return true;
                }
            }}
            ref={{
                formRef: formRef,
                wizardRef: wizardRef,
            }}
        >
            <ClientSearchStep
                id="select-client"
                selectedRow={selectedClient}
                selectedRowCallback={setSelectedClient}
                deviceTableRef={deviceTableRef}
            />
            <DeviceSearchStep ref={deviceTableRef} id="select-device" />
        </EdsWizard>
    );
};
