import { useAlarms, useSoftphone } from '../../../features';
import './eds-phone-link.scss';
import { PhoneOutgoing } from '@carbon/icons-react';
import _ from 'lodash';

export function EdsPhoneLink(props) {
    const { setDialInput } = useSoftphone();
    const { operableAlarm } = useAlarms();

    // TODO UMO-800 Allow CRM dialout without alarm context
    if (_.isNil(operableAlarm?.id)) {
        return <div>{props.phoneNumber}</div>;
    }

    return (
        <div
            className="eds-phone-link"
            onClick={() => {
                setDialInput(props.phoneNumber);
            }}
        >
            <div className="link-icon">{<PhoneOutgoing size="16" />}</div>
            <div>{props.phoneNumber}</div>
        </div>
    );
}
