import { getLogger, useAuth } from '../../features';
import { Button } from '@carbon/react';
import './scss/login-page.scss';
import { Navigate, useSearchParams } from 'react-router';
import {
    EdsUISimpleContainer,
    EdsUISimpleContainerButtons,
} from '../../components/';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../features/react/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import {
    isInvalidReferer,
    setReferer,
} from '../../features/utils/referer-redirect';

const logger = getLogger('LoginPage');

function LoginPage() {
    const [searchParams] = useSearchParams();
    const { login, isAuthenticated } = useAuth();
    const { t } = useTranslation();
    const documentTitle = useDocumentTitle();
    const referer = searchParams.get('r');

    useEffect(() => {
        document.title = documentTitle;
    }, [documentTitle]);

    logger.log('isAuthenticated', isAuthenticated);
    if (isAuthenticated) {
        if (!_.isNil(referer) && !isInvalidReferer(referer)) {
            logger.log('Redirect to referer', referer);
            setReferer(referer);
            return <Navigate to={referer} />;
        }

        return <Navigate to="/" />;
    }

    return (
        <div id="login-page">
            <EdsUISimpleContainer logo={true}>
                <EdsUISimpleContainerButtons>
                    <Button
                        onClick={() => {
                            login(referer);
                        }}
                    >
                        {t('d56b699830e77ba53855679cb1d252da', 'Login')}
                    </Button>
                </EdsUISimpleContainerButtons>
            </EdsUISimpleContainer>
        </div>
    );
}

export default LoginPage;
