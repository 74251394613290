import { useTranslation } from 'react-i18next';
import { EdsWizardStep } from '../../../eds';
import AdditionalInformationFormGroup from './additional-information-form-group';

function AdditionalInformationStep({ id, showHasKey = true }) {
    const { t } = useTranslation();

    return (
        <EdsWizardStep
            id={id}
            label={t(
                '4eec452dee7d15acfb78a023b487bf19',
                'Additional information'
            )}
        >
            <AdditionalInformationFormGroup
                inStep={true}
                showHasKey={showHasKey}
            />
        </EdsWizardStep>
    );
}

export default AdditionalInformationStep;
