import _ from 'lodash';
import './eds-badge.scss';

export const EdsBadgeKind = Object.freeze({
    Counter: 'COUNTER',
    Indicator: 'INDICATOR',
    HeaderIndicator: 'HEADER_INDICATOR',
});

export function EdsBadge({
    kind = EdsBadgeKind.Counter,
    danger,
    count,
    children,
}) {
    if (_.isNil(count) || count < 1) {
        return children;
    }

    const getStyle = () => {
        const classes = ['eds-badge'];

        switch (kind) {
            case EdsBadgeKind.Counter:
                classes.push('eds-counter');
                break;
            case EdsBadgeKind.Indicator:
                classes.push('eds-indicator');
                break;
            case EdsBadgeKind.HeaderIndicator:
                classes.push('eds-header-indicator');
                break;
        }

        if (danger) {
            classes.push('danger');
        }

        return classes.join(' ');
    };

    return (
        <div className="eds-badge-container">
            {children}
            <span className={getStyle()} role="status">
                {kind === EdsBadgeKind.Counter ? count : undefined}
            </span>
        </div>
    );
}
