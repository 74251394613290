import { useTranslation } from 'react-i18next';
import {
    EdsBadgeKind,
    EdsContainerTable,
    EdsTableCheckmark,
    EdsTooltip,
} from '../../../eds';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
    EdsCaregiverTypes,
    getCareTeamNotes,
    getPersonalCaregiverNotes,
    getProfessionalCaregiverNotes,
    postCareTeamNote,
    postPersonalCaregiverNote,
    postProfessionalCaregiverNote,
    useAlarms,
    useModal,
    useSoftphone,
} from '../../../../features';
import {
    EdsCareRegistrationState,
    getEdsCareRegistrationStateByName,
} from './eds-careprovider-types';
import { EdsColoredLabel } from '../../../eds/eds-colored-label/eds-colored-label';
import { UmoNotesTable } from '../umo-notes-table';
import { Home, Schematics, UserProfile } from '@carbon/icons-react';

const UmoAlarmCareProviders = ({
    visibleHeaderKeys = [
        'linkedTo',
        'name',
        'team',
        'type',
        'status',
        'available',
        // TODO UMO-739 Enable when available in UDS response.
        // 'hasKey',
    ],
    title,
    getDataCallback,
    ...props
}) => {
    const { t } = useTranslation();
    const { selectedCaregiver, updateSelectedCaregiver } = useAlarms();
    const { showModal } = useModal();
    const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
    const [hasData, setHasData] = useState();
    const [selected, setSelected] = useState();
    const tableRef = useRef(null);
    const { setDialInput } = useSoftphone();

    useEffect(() => {
        const rowId = getRowId(selected);
        updateSelectedCaregiver(
            [
                EdsCaregiverTypes.ProfessionalCaregiver,
                EdsCaregiverTypes.PersonalCaregiver,
            ].includes(rowId?.type)
                ? rowId
                : null
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    useEffect(() => {
        if (_.isNil(selectedCaregiver)) {
            return;
        }

        const id = getKey(selectedCaregiver?.linkedTo, selectedCaregiver?.id);
        const statusValue = tableRef?.current?.getColumnValueString(
            id,
            'status'
        );
        const newStatus = [
            EdsCareRegistrationState.Finished.name,
            EdsCareRegistrationState.Cancelled.name,
        ].includes(selectedCaregiver?.careRegistrationState)
            ? ''
            : t(
                  getEdsCareRegistrationStateByName(
                      selectedCaregiver?.careRegistrationState
                  )?.label
              );

        if (newStatus !== statusValue) {
            tableRef?.current?.updateTableRowField(id, 'status', newStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCaregiver]);

    const getKey = (linkedTo, id) => {
        return `${linkedTo}_${id}`;
    };

    const getRowId = (rowId, includeRowData = false) => {
        if (_.isNil(rowId)) {
            return null;
        }

        const rowData = tableRef?.current?.getRowData(rowId);

        const idParts = rowData?.id?.split('_');
        const result = {
            id: _.nth(idParts, 1) ?? rowData.id,
            linkedTo: _.nth(idParts, 0),
            type: rowData?.caregiverType,
            ...(includeRowData && {
                rowData,
            }),
        };

        return result;
    };

    const headers = [
        {
            key: 'linkedTo',
            header: '',
            width: '50px',
            minWidth: '50px',
        },
        {
            key: 'name',
            header: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
            search: 'searchByName',
        },
        {
            key: 'team',
            header: t('f894427cc1c571f79da49605ef8b112f', 'Team'),
            search: 'searchByTeam',
        },
        {
            key: 'type',
            header: t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
            search: 'searchByType',
        },
        {
            key: 'status',
            header: t('9acb44549b41563697bb490144ec6258', 'Status'),
            search: 'searchByStatus',
        },
        {
            key: 'available',
            header: t('f24431ce9f1b8885678b1ed611c4c214', 'Availability'),
            search: 'searchByAvailable',
        },
        {
            key: 'relation',
            header: t('2617c9384e4bb1af369e034ffd4490c9', 'Relation'),
            search: 'searchByRelation',
        },
        {
            key: 'hasKey',
            header: t('3c6e0b8a9c15224a8228b9a98ca1531d', 'Key'),
            width: '100px',
        },
    ];

    const getVisibleHeaders = () => {
        if (!visibleHeaderKeys) {
            return headers;
        }

        return visibleHeaderKeys.map((key) =>
            headers.find((h) => h.key === key)
        );
    };

    const isVisible = (headerKey) => {
        if (!visibleHeaderKeys) {
            return true;
        }
        return visibleHeaderKeys.includes(headerKey);
    };

    const mapCareProvider = (careProvider) => {
        if (!_.isNil(careProvider?.professionalCaregiverId)) {
            return {
                caregiverType: EdsCaregiverTypes.ProfessionalCaregiver,
                id: getKey(
                    careProvider?.linkedTo,
                    careProvider?.professionalCaregiverId
                ),
                ...(isVisible('name') && {
                    name: careProvider?.professionalCaregiverName,
                }),
                ...(isVisible('team') && {
                    team: (
                        <EdsColoredLabel label={careProvider?.careTeamName} />
                    ),
                }),
                ...(isVisible('linkedTo') && {
                    linkedTo: mapLinkedToIcon(careProvider?.linkedTo),
                }),
            };
        } else if (!_.isNil(careProvider?.careTeamId)) {
            return {
                caregiverType: EdsCaregiverTypes.Team,
                id: getKey(careProvider?.linkedTo, careProvider?.careTeamId),
                ...(isVisible('name') && {
                    name: careProvider?.careTeamName,
                }),
                ...(isVisible('team') && {
                    team: (
                        <EdsColoredLabel label={careProvider?.careTeamName} />
                    ),
                }),
                ...(isVisible('linkedTo') && {
                    linkedTo: mapLinkedToIcon(careProvider?.linkedTo),
                }),
                disabled: true,
            };
        } else if (!_.isNil(careProvider?.personalCaregiverId)) {
            return {
                caregiverType: EdsCaregiverTypes.PersonalCaregiver,
                id: getKey('Client', careProvider?.personalCaregiverId),
                ...(isVisible('name') && {
                    name: careProvider?.combinedName,
                }),
            };
        }
    };

    const mapLinkedToIcon = (linkedTo) => {
        const size = 16;
        switch (linkedTo) {
            case 'Client':
                return (
                    <EdsTooltip
                        label={t(
                            'fb7ee57051a13acf33a2232440846622',
                            'Linked to client'
                        )}
                    >
                        <UserProfile size={size} />
                    </EdsTooltip>
                );
            case 'Residence':
                return (
                    <EdsTooltip
                        label={t(
                            '6e61450b2e7cea2f4af7569e48b25dcf',
                            'Linked to residence'
                        )}
                    >
                        <Home size={size} />
                    </EdsTooltip>
                );
            case 'Scheme':
                return (
                    <EdsTooltip
                        label={t(
                            '63e449e56f63c9690ac7c8c58992101c',
                            'Linked to scheme'
                        )}
                    >
                        <Schematics size={size} />
                    </EdsTooltip>
                );
        }
    };

    const mappingCallback = (data) => {
        return {
            ...mapCareProvider(data),
            ...(isVisible('type') && {
                type: data?.caregiverType,
            }),
            ...(isVisible('status') && {
                status: t(
                    getEdsCareRegistrationStateByName(
                        data.careRegistrationStatus
                    )?.label
                ),
            }),
            ...(isVisible('available') && {
                available:
                    data.isAvailable === true
                        ? t('e4894ca167b08880bfc35862f18575eb', 'Available')
                        : undefined,
            }),
            ...(isVisible('available') && {
                relation: data?.relationType,
            }),
            ...(isVisible('hasKey') && {
                hasKey: <EdsTableCheckmark value={data?.hasKey} />,
            }),
            hasActiveNotes: data?.hasActiveNotes,
            phoneNumbers: data?.phoneNumbers,
        };
    };

    const getData = async () => {
        let data = await getDataCallback();
        setHasData(!_.isEmpty(data));
        return data;
    };

    if (
        hideWhenNoData &&
        !_.isUndefined(hasData) &&
        _.isBoolean(hasData) &&
        !hasData
    ) {
        return null;
    }

    const showNotesModal = (row) => {
        const { id, type, rowData } = getRowId(row?.id, true);

        const getNotes = async (params) => {
            switch (type) {
                case EdsCaregiverTypes.ProfessionalCaregiver:
                    return getProfessionalCaregiverNotes(id, params);
                case EdsCaregiverTypes.Team:
                    return getCareTeamNotes(id, params);
                case EdsCaregiverTypes.PersonalCaregiver:
                    return getPersonalCaregiverNotes(id, params);
                default:
                    return [];
            }
        };

        showModal({
            title:
                t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes') +
                ' - ' +
                rowData.name,
            secondaryButtonText: t('716f6b30598ba30945d84485e61c1027', 'Close'),
            size: 'lg',
            hidePrimaryButton: true,
            onSecondarySubmit: async (event, removeModal) => {
                const notesAvailable = !_.isEmpty(
                    await getNotes({ isValid: true })
                );

                tableRef?.current?.updateTableRowField(
                    row?.id,
                    'hasActiveNotes',
                    notesAvailable
                );
                removeModal();
            },
            children: (
                <UmoNotesTable
                    getDataCallback={getNotes}
                    postDataCallback={(note) => {
                        switch (type) {
                            case EdsCaregiverTypes.ProfessionalCaregiver:
                                return postProfessionalCaregiverNote(id, note);
                            case EdsCaregiverTypes.Team:
                                return postCareTeamNote(id, note);
                            case EdsCaregiverTypes.PersonalCaregiver:
                                return postPersonalCaregiverNote(id, note);
                            default:
                                return [];
                        }
                    }}
                />
            ),
        });
    };

    return (
        <EdsContainerTable
            ref={tableRef}
            hidden={_.isUndefined(hasData)}
            title={title}
            selected={[selected]}
            enableRadioSelection={true}
            onRowClick={(row) => {
                if (row.id !== selected) {
                    setSelected(row.id);
                }
            }}
            onSelectionButtonClick={(row) => {
                if (row.id !== selected) {
                    setSelected(row.id);
                }
            }}
            headers={getVisibleHeaders()}
            getDataCallback={getData}
            mappingCallback={mappingCallback}
            onNotesClick={showNotesModal}
            notesProps={(row) => {
                const rowData = tableRef?.current?.getRowData(row?.id);
                return {
                    badgeKind: EdsBadgeKind.Indicator,
                    count: rowData?.hasActiveNotes ? 1 : 0,
                };
            }}
            onCallClick={(row, index, phoneNumber) => {
                setDialInput(phoneNumber);
            }}
            callProps={(row) => {
                const rowData = tableRef?.current?.getRowData(row?.id);
                return {
                    disabled: _.isEmpty(rowData?.phoneNumbers),
                    phoneNumbers: rowData?.phoneNumbers,
                };
            }}
            {...props}
        />
    );
};

export const UmoAlarmProfessionalCaregivers = (props) => {
    return <UmoAlarmCareProviders hideWhenNoData={false} {...props} />;
};

export const UmoAlarmPersonalCaregivers = (props) => {
    return (
        <UmoAlarmCareProviders
            visibleHeaderKeys={[
                'name',
                'type',
                'relation',
                // TODO UMO-739 Enable when available in UDS response.
                // 'status',
                'available',
                // TODO UMO-739 Enable when available in UDS response.
                // 'hasKey',
            ]}
            hideWhenNoData={false}
            {...props}
        />
    );
};
