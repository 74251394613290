import { UmoAlarmsTable } from '../umo-alarms-table';
import {
    searchFilterStore,
    searchFilterStoreForFollowUp,
    searchFilterStoreForHistory,
} from '../../../../features/uds/alarm';
import { useTranslation } from 'react-i18next';
import {
    EdsSortDirection,
    EdsTableFilterAlarmCriteriaType,
    EdsTableFilterType,
} from '../../../eds';
import { getAlarmTypes, getSchemes } from '../../../../features';
import _ from 'lodash';
import { forwardRef } from 'react';

export const UmoSearchAlarmTable = forwardRef(
    (
        {
            visibleFilterIds = [
                'deviceCode',
                'timeStamp',
                'cli',
                'criterion',
                'alarmTypeId',
                'status',
                'schemeUID',
            ],
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();

        const getLastHours = () => {
            return [
                {
                    id: 1,
                    text: t(
                        '49a76d879c0e772bb9c4bfffb79ee7f9',
                        'Last 12 hours'
                    ),
                    hours: 12,
                },
                {
                    id: 2,
                    text: t('f9fe0a428fd785ad3d19fd637dc6b282', 'Last day'),
                    hours: 24,
                },
                {
                    id: 3,
                    text: t('6a3ce09b1960d5596249947a231a7776', 'Last 7 days'),
                    hours: 168,
                },
                {
                    id: 4,
                    text: t('0295639119cdb22010b837e2cf76bf82', 'Last 30 days'),
                    hours: 720,
                },
            ];
        };

        const availableFilters = [
            {
                id: 'deviceCode',
                name: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
                type: EdsTableFilterType.Range,
            },
            {
                id: 'timeStamp',
                name: t('01c76cca45a8dc7a3cd3097e734ee65b', 'Received on'),
                type: EdsTableFilterType.DateRange,
            },
            {
                id: 'cli',
                name: t('9b88e58612797d6b989681a16621ad63', 'Phone number'),
                type: EdsTableFilterType.PhoneNumber,
            },
            {
                id: 'criterion',
                name: t('0013d044b4cc94f3638cbd5e3753022f', 'Alarm criteria'),
                type: EdsTableFilterType.AlarmCriteria,
                getDataCallback: async () => {
                    return [
                        {
                            id: EdsTableFilterAlarmCriteriaType.Alarm,
                            name: t(
                                '6486b4e98228b83d4b13d54febe5f170',
                                'Alarm'
                            ),
                        },
                        {
                            id: EdsTableFilterAlarmCriteriaType.TechnicalAlarm,
                            name: t(
                                '49155a18a3aabdd0182d51cf89aa6885',
                                'Technical alarm'
                            ),
                        },
                        {
                            id: EdsTableFilterAlarmCriteriaType.CustomRanges,
                            name: t(
                                'ad8e9ec398490b7b96cb8a9640288416',
                                'Custom range(s)'
                            ),
                        },
                        {
                            id: EdsTableFilterAlarmCriteriaType.SpecificCriteria,
                            name: t(
                                '74fb39e1813c7bd59d050bdec07a67e3',
                                'Specific criteria'
                            ),
                        },
                    ];
                },
            },
            {
                id: 'alarmTypeId',
                name: t('906eb66e47713fb446120ba0c8622552', 'Alarm type'),
                type: EdsTableFilterType.MultiSelect,
                getDataCallback: async () => getAlarmTypes(),
            },
            {
                id: 'status',
                name: t('cb9e711ec122e4424382a750d89b2210', 'Alarm status'),
                type: EdsTableFilterType.MultiSelect,
                getDataCallback: async () => {
                    return [
                        {
                            id: 'Received',
                            name: t(
                                'c5946eb9400717fd6f40e26e36cdb498',
                                'Received'
                            ),
                        },
                        {
                            id: 'Active',
                            name: t(
                                'c76a5e84e4bdee527e274ea30c680d79',
                                'Active'
                            ),
                        },
                        {
                            id: 'FollowUp',
                            name: t(
                                'ff853246d679191f35c7c966204d0ec9',
                                'Follow-up'
                            ),
                        },
                        {
                            id: 'Closed',
                            name: t(
                                '349e686330723975502e9ef4f939a5ac',
                                'Closed'
                            ),
                        },
                    ];
                },
                compareItems: () => {
                    // Keep ordering of list
                    return 0;
                },
            },
            {
                id: 'schemeUID',
                name: t('41323917ef8089432959a3c33269debf', 'Scheme'),
                type: EdsTableFilterType.Dropdown,
                getDataCallback: async () => getSchemes(),
                mappingCallback: (data) => {
                    if (_.isUndefined(data)) {
                        return {};
                    }
                    return { id: data.schemeUID, text: data.name };
                },
            },
            {
                id: 'lastHours',
                name: t(
                    '2ace1261357cc190ae28c098ba465c04',
                    'Created or updated in'
                ),
                type: EdsTableFilterType.Dropdown,
                showOperator: false,
                getDataCallback: getLastHours,
                mappingCallback: (data) => {
                    if (_.isUndefined(data)) {
                        return {};
                    }
                    return data;
                },
                customQueryParamsFormatter: (params, values) => {
                    if (!_.isUndefined(values)) {
                        const lastHour = _.find(
                            getLastHours(),
                            (o) => o.id === values
                        );

                        if (!_.isNil(lastHour?.hours)) {
                            return {
                                ...params,
                                lastHours: lastHour.hours,
                            };
                        }
                    }
                    return params;
                },
            },
        ];

        const getVisibleFilters = () => {
            if (!visibleFilterIds) {
                return availableFilters;
            }

            return visibleFilterIds.map((id) =>
                availableFilters.find((f) => f.id === id)
            );
        };

        return (
            <UmoAlarmsTable
                ref={ref}
                searchFilterStore={searchFilterStore}
                searchTooltipKeywords={[
                    t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
                    t('9b88e58612797d6b989681a16621ad63', 'Phone number'),
                ]}
                availableFilters={getVisibleFilters()}
                visibleHeaderKeys={[
                    'timeStamp',
                    'criteria',
                    'deviceCode',
                    'locationDescription',
                    'client',
                    'residence',
                    'alarmTypeName',
                    'cli',
                    'remark',
                    'status',
                ]}
                enableContainer={false}
                {...props}
            />
        );
    }
);
UmoSearchAlarmTable.displayName = 'UmoSearchAlarmTable';

export const UmoSearchHistoryAlarmTable = forwardRef((props, ref) => {
    return (
        <UmoSearchAlarmTable
            ref={ref}
            searchFilterStore={searchFilterStoreForHistory}
            visibleFilterIds={['criterion', 'alarmTypeId', 'lastHours']}
            visibleHeaderKeys={[
                'criteria',
                'timeStamp',
                'client',
                'deviceCode',
                'operator',
                'remark',
                'alarmTypeName',
                'account',
            ]}
            baseFilter="status in ('Closed')"
            searchAllAccounts={true}
            allowSort={true}
            defaultSort={{
                key: 'timeStamp',
                order: EdsSortDirection.Descending,
            }}
            {...props}
        />
    );
});
UmoSearchHistoryAlarmTable.displayName = 'UmoSearchHistoryAlarmTable';

export const UmoSearchFollowUpAlarmTable = forwardRef((props, ref) => {
    return (
        <UmoSearchAlarmTable
            ref={ref}
            searchFilterStore={searchFilterStoreForFollowUp}
            visibleFilterIds={['criterion', 'alarmTypeId', 'lastHours']}
            visibleHeaderKeys={[
                'criteria',
                'timeStamp',
                'client',
                'deviceCode',
                'operator',
                'remark',
                'alarmTypeName',
                'account',
            ]}
            baseFilter="status in ('FollowUp')"
            searchAllAccounts={true}
            allowSort={true}
            defaultSort={{
                key: 'timeStamp',
                order: EdsSortDirection.Ascending,
            }}
            {...props}
        />
    );
});
UmoSearchFollowUpAlarmTable.displayName = 'UmoSearchFollowUpAlarmTable';
