import { IdleTimerProvider } from 'react-idle-timer';
import { useAuth } from './context';
import { getLogger } from '../utils';
import { useModal } from '../modal';
import { memo } from 'react';

const logger = getLogger('IdleTimer');

export const IdleTimer = memo(({ children }) => {
    const { logout, isAuthenticated } = useAuth();
    const { showIdleModal } = useModal();

    if (!isAuthenticated) {
        return children;
    }

    const onIdle = () => {
        logger.log('onIdle triggered -> logout');
        logout();
    };

    const onPrompt = (e, idleTimer) => {
        logger.log('show the idle prompt', e, idleTimer);
        showIdleModal(idleTimer, logout);
    };

    logger.log('Setup Idle Timer provider');

    return (
        <IdleTimerProvider
            timeout={parseInt(window.idle_timeout)}
            promptBeforeIdle={parseInt(window.prompt_before_idle)}
            throttle={parseInt(window.throttle_idle)}
            onIdle={onIdle}
            onPrompt={onPrompt}
        >
            {children}
        </IdleTimerProvider>
    );
});
IdleTimer.displayName = 'IdleTimer';
