import { Outlet } from 'react-router';
import { AlarmReadPermissionSet, Restricted } from '../../../features';
import FollowUpPage from './follow-up-page';

const FollowUpPageRoutes = {
    path: 'follow-up',
    element: (
        <Restricted toAnyAccount={AlarmReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    children: [
        {
            index: true,
            element: <FollowUpPage />,
        },
    ],
};

export default FollowUpPageRoutes;
