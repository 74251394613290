import { IconButton } from '@carbon/react';
import _ from 'lodash';
import './umo-alarm-control-icon-button.scss';

export const UmoAlarmControlIconButtonType = Object.freeze({
    Success: 'Success',
    Danger: 'Danger',
});

export function AlarmControlIconButton({
    size = 16,
    icon,
    label,
    disabled,
    type,
    onClick,
    isSelected,
}) {
    const getIcon = (Icon) => {
        if (_.isUndefined(Icon)) {
            return null;
        }
        return <Icon size={size} />;
    };

    const getStyle = () => {
        let classes = ['alarm-control-icon-button'];
        if (type === UmoAlarmControlIconButtonType.Success) {
            classes.push('success');
        } else if (type === UmoAlarmControlIconButtonType.Danger) {
            classes.push('danger');
        }

        return classes.join(' ');
    };

    return (
        <IconButton
            className={getStyle()}
            label={label}
            kind={'ghost'}
            disabled={disabled}
            isSelected={isSelected}
            size={'sm'}
            onClick={(event) => {
                if (_.isFunction(onClick)) {
                    onClick(event);
                }
                event.stopPropagation();
            }}
        >
            {getIcon(icon)}
        </IconButton>
    );
}

export function AlarmControlIconButtonWrapper({ children }) {
    return <div className="alarm-control-icon-button-wrapper">{children}</div>;
}
