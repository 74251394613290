import { forwardRef } from 'react';
import { EdsForm } from '../../../eds';
import { AdditionalInfoFormGroup } from './additional-info-form-group';

const AdditionalInfoForm = forwardRef((props, ref) => {
    const onSubmit = async (_event, mappedForm, isValid, form) => {
        if (!isValid) {
            throw false;
        }

        return { mappedForm, form };
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={props.initValues}
            onSubmit={onSubmit}
            ref={ref}
        >
            <AdditionalInfoFormGroup
                prefix={props.prefix}
                compactView={false}
                {...props}
            />
        </EdsForm>
    );
});
AdditionalInfoForm.displayName = 'AdditionalInfoForm';

export default AdditionalInfoForm;
