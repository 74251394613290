export const AlarmsActions = {
    AddEventListener: 'ADD_EVENT_LISTENER',
    RemoveEventListener: 'REMOVE_EVENT_LISTENER',
    UpdateOperableAlarm: 'UPDATE_OPERABLE_ALARM',
    UpdateSelectedCaregiver: 'UPDATE_SELECTED_CAREGIVER',
    UpdateAlarmQueue: 'UPDATE_ALARM_QUEUE',
    UpdateActiveAlarmQueue: 'UPDATE_ACTIVE_ALARM_QUEUE',
};

export const alarmsReducer = (state, action) => {
    switch (action.type) {
        case AlarmsActions.AddEventListener: {
            return {
                ...state,
                eventListeners: [...state.eventListeners, action.payload],
            };
        }
        case AlarmsActions.RemoveEventListener: {
            return {
                ...state,
                eventListeners: state.eventListeners.filter(
                    (listener) => listener.id !== action.payload.id
                ),
            };
        }
        case AlarmsActions.UpdateOperableAlarm: {
            return {
                ...state,
                operableAlarm: action.payload,
            };
        }
        case AlarmsActions.UpdateSelectedCaregiver: {
            return {
                ...state,
                selectedCaregiver: action.payload,
            };
        }

        case AlarmsActions.UpdateAlarmQueue: {
            return {
                ...state,
                alarmQueue: action.payload,
                alarmQueueCount: action?.payload?.length ?? 0,
            };
        }

        case AlarmsActions.UpdateActiveAlarmQueue: {
            return {
                ...state,
                activeAlarmQueue: action.payload,
                activeAlarmQueueCount: action?.payload?.length ?? 0,
            };
        }
    }
};
