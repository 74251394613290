import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatches, useParams, useRouteLoaderData } from 'react-router';
import { useNavigate as useNavigateNative } from 'react-router';
import {
    alarmDetailsRouterDataId,
    getLink,
    isExternalURL,
    isValidUrl,
} from '../utils';
import { useModal } from '../modal';
import { useAuth } from '../auth';
import { getLastUsedAccountId } from '../utils/last-used-tenants';

/**
 * useEffect hook to only fire on updates after component mount
 *
 * @param {function} func Function body of the useEffect hook
 * @param {array} deps dependencies to listen on
 */
export const useEffectOnUpdate = (func, deps) => {
    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) {
            func();
        } else {
            mounted.current = true;
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

/**
 * useEffect hook to only fire on mount of component
 *
 * @param {function} func Function body of the useEffect hook
 */
export const useEffectOnMount = (func) => {
    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            func();
        }
    }, [func]);
};

/**
 *  Hook to fire when docment title changes based on the current route
 *
 * @param {string} title page title to add to the document title
 */
export function useDocumentTitle(title) {
    const { t } = useTranslation();
    const matches = useMatches();
    const [documentTitle, setDocumentTitle] = useState('');

    useEffect(() => {
        let titles = getSubTitles();

        if (!_.isUndefined(title)) {
            titles.push(title);
        }

        titles.push(
            t('9817dc02b06ab3ce8ee7c573a43e3a74', 'UMOcx Agent Portal')
        );
        setDocumentTitle(titles.join(' - '));
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matches]);

    const getSubTitles = () => {
        const subTitles = matches
            .filter((match) => {
                return Boolean(match?.handle?.crumb);
            })
            .toReversed()
            .map((match) => {
                return {
                    title: match.handle.crumb(t, match.data),
                    documentTitleParent:
                        match?.handle?.documentTitleParent || false,
                };
            });

        const parentIndex = subTitles.findIndex((item) => {
            return item.documentTitleParent;
        });

        return subTitles.slice(0, parentIndex).map((item) => {
            return item.title;
        });
    };

    return documentTitle;
}

export const useNavigate = () => {
    const navigate = useNavigateNative();

    const params = useParams();
    const accountId = useAccountId();
    const { showTextModal } = useModal();
    const { t } = useTranslation();

    return (to, ...other) => {
        if (isExternalURL(to)) {
            showTextModal({
                title:
                    params.title ??
                    t('270442279d15bb2ced1ee5c611412731', 'Please confirm'),
                lines: [
                    t(
                        '7cdfd52d7f4928e0984e46dab2510395',
                        'You are about to navigate to an external URL'
                    ),
                    to,
                    t(
                        '6623750dc95cc78a30f2780271333b79',
                        'Do you want to continue?'
                    ),
                ],
                size: 'xs',
                primaryButtonText: t('a6105c0a611b41b08f1209506350279e', 'Yes'),
                secondaryButtonText: t(
                    '7fa3b767c460b54a2be4d49030b349c7',
                    'No'
                ),
                onRequestSubmit: async (_event, removeModal) => {
                    redirectExternal(to);
                    removeModal();
                },
            });
        } else {
            let navigateTo = to;
            if (isValidUrl(to)) {
                //Remove the origin from the 'to' if it is a valid URL. It removes the protocol and host which are both not needed in de navigat() method.
                navigateTo = to.replace(new URL(to).origin, '');
            }
            return navigate(getLink(navigateTo, accountId), ...other);
        }
    };
};

export const useAccountId = () => {
    const params = useParams();
    const alarmData = useRouteLoaderData(alarmDetailsRouterDataId);
    const { attributes } = useAuth();

    return !_.isUndefined(alarmData?.accountId)
        ? alarmData?.accountId
        : !_.isUndefined(params?.accountId)
        ? params?.accountId
        : getLastUsedAccountId(attributes?.tenant_id);
};

const redirectExternal = (to) => {
    window.open(to, '_blank', 'noreferrer');
};
