import { useTranslation } from 'react-i18next';
import {
    EdsCheckbox,
    EdsDatePicker,
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
    EdsNumberInput,
    EdsPhoneNumber,
    EdsTextArea,
    EdsTextInput,
} from '../../../../components';
import { Row, CheckboxGroup } from '@carbon/react';
import {
    getDeviceByCode,
    getDeviceLocations,
    getDeviceManagers,
    getDeviceTypes,
    getGroups,
    postDeviceValidate,
    postDeviceRangeValidate,
    getDeviceRange,
    addLeadingZeros,
    getDate,
    useEffectOnMount,
    getAccountSettings,
    getFilteredDeviceStates,
} from '../../../../features';
import { useRef, useState } from 'react';
import _ from 'lodash';

export const EdsDeviceFormMode = Object.freeze({
    Normal: 'NORMAL',
    Range: 'RANGE',
    ResidenceRange: 'RESIDENCE_RANGE',
});

export function DeviceDetailsStepForm({
    formMode = EdsDeviceFormMode.Normal,
    devicePrefix,
    numberOfDigits,
    getDeviceRangeCallback,
    readyToLink = false,
    ...props
}) {
    const [formDefinition, setFormDefinition] = useState([]);
    const { t } = useTranslation();
    const deviceTypeRef = useRef(null);
    const deviceStateRef = useRef(null);
    const deviceLocationRef = useRef(null);
    const deviceManagerRef = useRef(null);
    const groupRef = useRef(null);
    const [serialNumberRequired, setSerialNumberRequired] = useState(false);
    const [phoneNumberRequired, setPhoneNumberRequired] = useState(false);

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    const deviceTypeMappingCallback = (data) => {
        return {
            id: data.id,
            text: data.name,
            isSerialNumberRequired: data.isSerialNumberRequired,
            isSerialNumberUnique: data.isSerialNumberUnique,
            isDevicePhoneNumberRequired: data.isDevicePhoneNumberRequired,
        };
    };

    const validateRange = async (value, testContext, field) => {
        let formValues = testContext?.options?.context?.form?.device;
        let numberOfDevices;
        let range;

        if (_.isFunction(getDeviceRangeCallback)) {
            range = getDeviceRangeCallback(value);
            numberOfDevices = !_.isNull(range) ? range.rangeSize : 0;
        } else {
            numberOfDevices = parseInt(formValues?.numberOfDevices);
            range = getDeviceRange(value, numberOfDevices);
        }

        if (
            _.isNaN(numberOfDevices) ||
            !_.isNumber(numberOfDevices) ||
            numberOfDevices < 2
        ) {
            return true;
        }

        if (_.isNull(range)) {
            let fieldName = '';
            if (field === 'deviceCode') {
                fieldName = t(
                    '57d50ee4873d42049cc021a5df38b750',
                    'Device code'
                );
            } else if (field === 'serialNumber') {
                fieldName = t(
                    'c9f4029ce9dcf8ff7f8f1b70edead843',
                    'Serial number'
                );
            }

            return testContext.createError({
                message: t(
                    'd0008f759c883d3e25fe8e97ca226d39',
                    '{{field}} must end on a range start number',
                    { field: fieldName }
                ),
            });
        }

        // Number positions in device code must fit all device codes that will be generated (does not apply for serial numbers)
        if (
            field === 'deviceCode' &&
            range.end.toString().length > range.numberOfDigits
        ) {
            let exampleCode =
                range.prefix +
                addLeadingZeros(range.start, range.end.toString().length);
            return testContext.createError({
                message: t(
                    'bff510c9e78a18a2a11f603d2a5aa894',
                    "Number in device code '{{deviceCode}}' does not provide enough positions for '{{lastCode}}', for example use '{{exampleCode}}'",
                    {
                        deviceCode: value,
                        lastCode: range.last,
                        exampleCode,
                    }
                ),
            });
        }

        let response = await postDeviceRangeValidate({
            ...(!_.isEmpty(formValues?.deviceCode) && {
                deviceCode: formValues?.deviceCode,
            }),
            ...(!_.isEmpty(formValues?.serialNumber) && {
                serialNumber: formValues?.serialNumber,
            }),
            numberOfDevices: numberOfDevices,
            deviceTypeId: formValues?.deviceTypeId?.id,
            deviceStateId: formValues?.deviceStateId?.id,
            deviceLocationId: formValues?.deviceLocationId?.id,
            groupId: formValues?.groupId?.id,
        });
        if (_.isArray(response) && _.isEmpty(response)) {
            return true;
        }

        if (field === 'deviceCode') {
            if (
                _.findIndex(response, [
                    'code',
                    'B1176', //DeviceCode should be unique
                ]) !== -1
            ) {
                let fieldPlural = t(
                    '8d8786da7c027a8fefe3cb2f300ce433',
                    'device codes'
                );
                return testContext.createError({
                    message: t(
                        '69980d6def78630f12c6f3058b0fb442',
                        'One or more {{fieldPlural}} in range are in use',
                        { fieldPlural }
                    ),
                });
            }
        } else if (field === 'serialNumber') {
            if (
                _.findIndex(response, [
                    'code',
                    'B1028', //Serial number should be unique
                ]) !== -1
            ) {
                let fieldPlural = t(
                    'c05284c183bc93aba5fbb29ad080bf29',
                    'serial numbers'
                );
                return testContext.createError({
                    message: t(
                        '69980d6def78630f12c6f3058b0fb442',
                        'One or more {{fieldPlural}} in range are in use',
                        { fieldPlural }
                    ),
                });
            }
        }

        return true;
    };

    const getDeviceDefaultState = async () => {
        const accountSettings = await getAccountSettings();

        const defaultState = readyToLink
            ? accountSettings?.linkDeviceDefaultState
            : accountSettings?.unlinkDeviceDefaultState;

        return defaultState ? deviceStateMappingCallback(defaultState) : {};
    };

    const deviceStateMappingCallback = (data) => {
        return {
            id: data.id,
            text: data.name,
            readyForPlacement: data.readyForPlacement,
        };
    };

    const getFormDefinition = async () => {
        let newFormDefinition = {
            wizardStepId: props.wizardStepId,
            ...(formMode !== EdsDeviceFormMode.ResidenceRange && {
                deviceCode: {
                    validation: {
                        max: 20,
                        required: true, // Capitals, no space allowed. Check if device code exists
                        tests: [
                            {
                                name: 'device-code-must-be-unique',
                                message: '', // Custom error using testContext.createError(...)
                                func: async (value, testContext) => {
                                    if (!value) {
                                        return true;
                                    }
                                    if (
                                        props?.initValues?.device?.deviceCode
                                            ?.value
                                    ) {
                                        if (
                                            value ===
                                            props.initValues.device.deviceCode
                                                .value
                                        ) {
                                            return true;
                                        }
                                    }

                                    if (formMode === EdsDeviceFormMode.Range) {
                                        // Check range of device codes
                                        return await validateRange(
                                            value,
                                            testContext,
                                            'deviceCode'
                                        );
                                    } else {
                                        // Check single device code
                                        let device = await getDeviceByCode(
                                            value
                                        );
                                        if (device === null) {
                                            return true;
                                        }
                                        return testContext.createError({
                                            message: t(
                                                '4a9afa1c7a6f314204001b3240f115c7',
                                                'This device code is already in use'
                                            ),
                                        });
                                    }
                                },
                            },
                        ],
                    },
                },
            }),
            deviceTypeId: {
                validation: {
                    type: 'dropdown',
                    required: true,
                    tests: [
                        {
                            name: 'valid-device-type-for-range',
                            message: t(
                                '86ca4f6f259dfa25de49bcbe96c5b572',
                                'Device type cannot be used for new range, phone number is required for this type'
                            ),
                            func: async (value) => {
                                if (
                                    !value ||
                                    formMode === EdsDeviceFormMode.Normal
                                ) {
                                    return true;
                                }
                                if (value.isDevicePhoneNumberRequired) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                },
                mapping: 'id',
                dependents: ['serialNumber', 'phoneNumber'],
            },
            serialNumber: {
                validation: {
                    max: 20,
                    required: false, // Depending on type mandatory and/or unique
                    tests: [
                        {
                            name: 'serial-number-mandatory-for-type',
                            message: t(
                                'deae60ad2e96c5c13c301e77cc3e4ce9',
                                'Serial number is required for this device type'
                            ),
                            func: async (value, testContext) => {
                                if (value) {
                                    return true;
                                }
                                let deviceType =
                                    testContext?.options?.context?.form?.device
                                        ?.deviceTypeId;

                                if (deviceType?.isSerialNumberRequired) {
                                    return false;
                                }
                                return true;
                            },
                        },
                        {
                            name: 'serial-number-unique-for-type',
                            message: '', // Custom error using testContext.createError(...)
                            func: async (value, testContext) => {
                                if (!value) {
                                    return true;
                                }

                                let deviceId =
                                    testContext?.options?.context?.form?.device
                                        ?.id;

                                let deviceType =
                                    testContext?.options?.context?.form?.device
                                        ?.deviceTypeId;

                                if (
                                    !deviceType?.isSerialNumberUnique &&
                                    formMode === EdsDeviceFormMode.Normal
                                ) {
                                    return true;
                                }

                                if (formMode !== EdsDeviceFormMode.Normal) {
                                    // Check range of serial numbers
                                    return await validateRange(
                                        value,
                                        testContext,
                                        'serialNumber'
                                    );
                                } else {
                                    // Check single serial number
                                    let validatedValue =
                                        await postDeviceValidate({
                                            ...(!_.isUndefined(deviceId) && {
                                                deviceId: deviceId,
                                            }),
                                            serialNumber: value,
                                            deviceTypeId: deviceType.id,
                                        });

                                    if (
                                        !_.isEmpty(validatedValue) &&
                                        _.isArray(validatedValue)
                                    ) {
                                        if (
                                            _.findIndex(validatedValue, [
                                                'code',
                                                'B1028', //Serial number should be unique
                                            ]) === -1
                                        ) {
                                            return true;
                                        }
                                        return testContext.createError({
                                            message: t(
                                                'b66ec73721c9fd30c1a8b7a91557acaf',
                                                'Serial number must be unique for this device type'
                                            ),
                                        });
                                    }
                                }

                                return true;
                            },
                        },
                    ],
                },
            },
            description: {
                validation: {
                    max: 62,
                    required: false,
                },
            },
            deviceStateId: {
                value: await getDeviceDefaultState(),
                validation: {
                    type: 'dropdown',
                    required: true,
                    tests: [
                        {
                            name: 'invalid-device-state',
                            message: t(
                                'de681b2cc903f98208acac3b9575fee6',
                                'Invalid device state'
                            ),
                            func: async (value) => {
                                if (_.isEmpty(value) || !readyToLink) {
                                    return true;
                                }
                                return value?.readyForPlacement;
                            },
                        },
                    ],
                },
                mapping: 'id',
            },
            deviceLocationId: {
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            groupId: {
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            remark: {
                validation: {
                    max: 256,
                    required: false,
                },
            },
            purchaseDate: {
                validation: {
                    type: 'datepicker',
                    required: false,
                },
            },
            purchasePrice: {
                validation: {
                    type: 'cost',
                    required: false,
                },
            },
            warrantyDate: {
                validation: {
                    type: 'datepicker',
                    required: false,
                },
            },
            batteryDate: {
                validation: {
                    type: 'datepicker',
                    required: false,
                },
            },
        };

        if (formMode === EdsDeviceFormMode.Range) {
            newFormDefinition.numberOfDevices = {
                value: 2,
                validation: {
                    type: 'number',
                    min: 2,
                    max: 500,
                    required: true,
                },
                dependents: ['deviceCode', 'serialNumber'],
            };
        } else if (formMode === EdsDeviceFormMode.Normal) {
            newFormDefinition.phoneNumber = {
                validation: {
                    type: 'phone-number',
                    max: 50,
                    required: false, // Depending on type mandatory and phone number cannot be re-used
                    tests: [
                        {
                            name: 'phonne-number-mandatory-for-type',
                            message: t(
                                '9a44214d640738a25cc289a89f2d3c7d',
                                'Phone number is required for this device type'
                            ),
                            func: async (value, testContext) => {
                                if (value) {
                                    return true;
                                }
                                let deviceType =
                                    testContext?.parent?.deviceTypeId;
                                if (deviceType?.isDevicePhoneNumberRequired) {
                                    return false;
                                }
                                return true;
                            },
                        },

                        {
                            name: 'phone-number-unique',
                            message: t(
                                '8b375d90f74912d524a0cff06e8066ae',
                                'Phone number is already in use'
                            ),
                            func: async (value, testContext) => {
                                if (!value) {
                                    return true;
                                }

                                const testValue = value.replace(/[^+\d]/g, ''); //strip all the character that are not digits or +
                                if (testValue.length < 8) {
                                    // to also except eq +6903190
                                    return true;
                                }

                                let deviceId =
                                    testContext?.options?.context?.form?.device
                                        ?.id;

                                let validatedValue = await postDeviceValidate({
                                    ...(!_.isUndefined(deviceId) && {
                                        deviceId: deviceId,
                                    }),
                                    phoneNumber: value,
                                });

                                if (
                                    !_.isEmpty(validatedValue) &&
                                    _.isArray(validatedValue)
                                ) {
                                    return _.findIndex(validatedValue, [
                                        'code',
                                        'B1015', //cannot be re-used
                                    ]);
                                }
                                return true;
                            },
                        },
                    ],
                },
            };

            newFormDefinition.deviceManagerId = {
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            };

            newFormDefinition.isOwnedByOrganization = false;
        }

        return newFormDefinition;
    };

    const getGroupDropdown = () => {
        return (
            <EdsDropdown
                ref={groupRef}
                type={EdsDropdownType.ComboBox}
                name={'groupId'}
                label={t('db0f6f37ebeb6ea09489124345af2a45', 'Group')}
                getDataCallback={getGroups}
            ></EdsDropdown>
        );
    };

    return (
        <EdsFormGroup
            formDefinition={formDefinition}
            prefix="device"
            inStep={props.wizardStepId ?? false}
            label={t('aba021ee861b02244c8f5f51b5084eb7', 'Device details')}
        >
            {formMode !== EdsDeviceFormMode.ResidenceRange && (
                <Row>
                    <EdsFormColumn>
                        <EdsTextInput
                            uppercase={true}
                            nospaces={true}
                            name="deviceCode"
                            label={
                                formMode === EdsDeviceFormMode.Range
                                    ? t(
                                          '856e99fff53e351c5ef789609045d7ee',
                                          'First device code'
                                      )
                                    : t(
                                          '57d50ee4873d42049cc021a5df38b750',
                                          'Device code'
                                      )
                            }
                        />
                    </EdsFormColumn>
                    {formMode === EdsDeviceFormMode.Range && (
                        <EdsFormColumn>
                            <EdsNumberInput
                                name="numberOfDevices"
                                label={t(
                                    '5b11a25403b1e2fee675cdc86ff857a2',
                                    'Number of devices'
                                )}
                            ></EdsNumberInput>
                        </EdsFormColumn>
                    )}
                </Row>
            )}
            {formMode === EdsDeviceFormMode.ResidenceRange && (
                // Both devicePrefix and numberOfDigits are defined and set in ResidenceDetailsStepForm
                <Row>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="devicePrefix"
                            label={t(
                                'c2de4402e355d9b73e9e95fba4b5c268',
                                'Device prefix'
                            )}
                            uncontrolledValue={devicePrefix}
                            readOnly={true}
                        />
                    </EdsFormColumn>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="numberOfDigits"
                            label={t(
                                '77a6c00d482cedc9b2df6e07461c6e6e',
                                'Number of digits'
                            )}
                            uncontrolledValue={numberOfDigits}
                            readOnly={true}
                        />
                    </EdsFormColumn>
                </Row>
            )}
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={deviceTypeRef}
                        type={EdsDropdownType.ComboBox}
                        name={'deviceTypeId'}
                        label={t(
                            '25c58563ac9a9e4d07c44dcb5ccaef38',
                            'Device type'
                        )}
                        getDataCallback={() => {
                            return getDeviceTypes({
                                isExcludedFromInsertList: false,
                            });
                        }}
                        mappingCallback={deviceTypeMappingCallback}
                        onChangeCallback={(event) => {
                            setSerialNumberRequired(
                                !!event.selectedItem?.isSerialNumberRequired
                            );
                            setPhoneNumberRequired(
                                !!event.selectedItem
                                    ?.isDevicePhoneNumberRequired
                            );
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsTextInput
                        name="description"
                        label={t(
                            '67daf92c833c41c95db874e18fcb2786',
                            'Description'
                        )}
                    />
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsTextInput
                        name="serialNumber"
                        label={
                            formMode === EdsDeviceFormMode.Normal
                                ? t(
                                      'c9f4029ce9dcf8ff7f8f1b70edead843',
                                      'Serial number'
                                  )
                                : t(
                                      '4dcd578311c9518ad8f968ff0387f26d',
                                      'First serial number'
                                  )
                        }
                        forceRequired={serialNumberRequired}
                    />
                </EdsFormColumn>
                <EdsFormColumn>
                    {/* Show group dropdown here instead of phone number when in range mode */}
                    {formMode !== EdsDeviceFormMode.Normal ? (
                        getGroupDropdown()
                    ) : (
                        <EdsPhoneNumber
                            name="phoneNumber"
                            label={t(
                                '9b88e58612797d6b989681a16621ad63',
                                'Phone number'
                            )}
                            forceRequired={phoneNumberRequired}
                        />
                    )}
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={deviceStateRef}
                        type={EdsDropdownType.ComboBox}
                        name={'deviceStateId'}
                        label={t(
                            '100de37890376212fdea338fb9703bb4',
                            'Device state'
                        )}
                        getDataCallback={async () =>
                            await getFilteredDeviceStates(
                                readyToLink &&
                                    _.isNil(props?.initValues?.device?.id)
                            )
                        }
                        mappingCallback={deviceStateMappingCallback}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={deviceLocationRef}
                        type={EdsDropdownType.ComboBox}
                        name={'deviceLocationId'}
                        label={t(
                            'c328b9ce4d9b140f9f441bf0d0bcc691',
                            'Device location'
                        )}
                        getDataCallback={() => {
                            return getDeviceLocations({
                                isInactive: false,
                            });
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsDatePicker
                        name="purchaseDate"
                        label={t(
                            '5071d3a7f981bce456aaa16184cee8dd',
                            'Purchase date'
                        )}
                        minDate={getDate({ minusYears: 120 })}
                        maxDate={getDate()}
                    />
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsNumberInput
                        name="purchasePrice"
                        label={t(
                            '6977c293ce5621d4676070ef934ccf23',
                            'Purchase price'
                        )}
                        cost={true}
                    />
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsDatePicker
                        name="warrantyDate"
                        label={t(
                            '9959097423b1c355c8431d8cda72ccf8',
                            'Warranty valid until'
                        )}
                    />
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDatePicker
                        name="batteryDate"
                        label={t(
                            'feb291f0b7bb72df13ea13febd72ea45',
                            'Battery expire date'
                        )}
                    />
                </EdsFormColumn>
            </Row>

            {/* Show group dropdown here when in single device mode */}
            {formMode === EdsDeviceFormMode.Normal && (
                <>
                    <Row>
                        <EdsFormColumn>{getGroupDropdown()}</EdsFormColumn>
                        <EdsFormColumn>
                            <EdsDropdown
                                ref={deviceManagerRef}
                                type={EdsDropdownType.ComboBox}
                                name={'deviceManagerId'}
                                label={t(
                                    '13f7fd60b26a98dd00c96d482ca56e8f',
                                    'Device manager'
                                )}
                                getDataCallback={getDeviceManagers}
                            ></EdsDropdown>
                        </EdsFormColumn>
                    </Row>
                    <Row>
                        <EdsFormColumn>
                            <CheckboxGroup legendText="">
                                <EdsCheckbox
                                    labelText={t(
                                        '7f53034ec146816482c8b759d5812f58',
                                        'Owned by organisation'
                                    )}
                                    name="isOwnedByOrganization"
                                />
                            </CheckboxGroup>
                        </EdsFormColumn>
                    </Row>
                </>
            )}
            <Row>
                <EdsFormColumn fullWidth>
                    <EdsTextArea
                        name="remark"
                        label={t('911c185c8f3c475cdca2ef9cf12166da', 'Remark')}
                        enableCounter={true}
                        maxCount={formDefinition.remark?.validation?.max}
                        rows={2}
                    ></EdsTextArea>
                </EdsFormColumn>
            </Row>
        </EdsFormGroup>
    );
}
