import './eds-form-group.scss';
import { useEffect, useState } from 'react';
import { FormGroup, Stack } from '@carbon/react';
import FormGroupContext from '../form-group-context';
import { useForm } from '../';
import _ from 'lodash';
import { EdsLoading } from '../../..';

export function EdsFormGroup({
    label = '',
    prefix,
    formDefinition,
    inStep = false,
    inFilter = false,
    sidePadding,
    ...props
}) {
    const { addFormDefinition } = useForm();
    const [formDefinitionAdded, setFormDefinitionAdded] = useState(false);

    const formatLegendId = (legendText) => {
        return 'eds-form-group_' + legendText.toLowerCase();
    };

    const getPrefixedName = (name) => {
        let inputName = name;
        if (prefix && prefix !== '') {
            inputName = prefix + '.' + inputName;
        }
        return inputName;
    };

    const getStyle = () => {
        let classes = ['eds-form-group'];
        if (label !== '') {
            classes.push('legend');
        }
        if (inStep) {
            classes.push('step');
        }
        if (inFilter) {
            classes.push('filter');
        }
        if (sidePadding) {
            classes.push('side-padding');
        }
        return classes.join(' ');
    };

    const usePrefix = (name) => {
        if (_.isArray(name)) {
            return name.map((val) => {
                return getPrefixedName(val);
            });
        } else {
            return getPrefixedName(name);
        }
    };

    useEffect(() => {
        if (
            (!prefix && !formDefinition) ||
            formDefinitionAdded ||
            _.isEmpty(formDefinition)
        ) {
            return;
        }

        addFormDefinition(formDefinition, prefix);
        setFormDefinitionAdded(true);
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDefinition]);

    if (!formDefinitionAdded && !_.isUndefined(formDefinition)) {
        return (
            <FormGroup className={getStyle()} legendText={label}>
                <EdsLoading small={true} inline={false} delay={true} />
            </FormGroup>
        );
    }

    return (
        <FormGroup
            className={getStyle()}
            legendId={formatLegendId(label).replaceAll(' ', '-')}
            legendText={label}
            disabled={props?.disabled}
        >
            <FormGroupContext.Provider value={{ prefix, usePrefix }}>
                <Stack gap={5}>{props.children}</Stack>
            </FormGroupContext.Provider>
        </FormGroup>
    );
}
