import { useAuth } from '../../features';
import { Button } from '@carbon/react';
import './scss/login-page.scss';
import { useTranslation } from 'react-i18next';
import {
    EdsUISimpleContainer,
    EdsUISimpleContainerButtons,
} from '../../components';
import { Navigate } from 'react-router';

function LogoutPage() {
    const { logout } = useAuth();
    const { t, isAuthenticated } = useTranslation();

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div id="login-page">
            <EdsUISimpleContainer logo={true}>
                <EdsUISimpleContainerButtons>
                    <Button onClick={() => logout()}>
                        {t('4236a440a662cc8253d7536e5aa17942', 'Logout')}
                    </Button>
                </EdsUISimpleContainerButtons>
            </EdsUISimpleContainer>
        </div>
    );
}

export default LogoutPage;
