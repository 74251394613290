import axios from '../utils/axios';
import { memo, useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router';
import { accountIdHeaderName, getLogger } from '../utils';

const logger = getLogger('AccountIdApiSetter');

export const AccountIdApiSetter = memo(({ children }) => {
    const params = useParams();

    useEffect(() => {
        if (!_.isUndefined(params.accountId)) {
            logger.log(
                'set new account id header',
                params,
                axios.defaults.headers.common
            );

            axios.defaults.headers.common[accountIdHeaderName] =
                params.accountId;
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.accountId]);

    return children;
});
AccountIdApiSetter.displayName = 'AccountIdApiSetter';
