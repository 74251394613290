import { useTranslation } from 'react-i18next';
import { EdsPage, UmoSearchHistoryAlarmTable } from '../../../components';
import { useNavigate } from 'react-router';
import { useRef } from 'react';
import {
    accountIdHeaderName,
    getAlarm,
    getLogger,
    postReopenAlarm,
    useAuth,
    useNotifications,
} from '../../../features';
import _ from 'lodash';
import axios from '../../../features/utils/axios';

const logger = getLogger('HistoryPage');

function HistoryPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { attributes } = useAuth();
    const tableRef = useRef();
    const { showSuccess, showError } = useNotifications();

    const getAccountIdFromRow = (rowId) => {
        const rowData = tableRef?.current?.getRowData(rowId);
        const account = attributes?.accounts?.find(
            (item) => item?.name === rowData?.account
        );
        return account?.id;
    };

    const setAccountId = async (accountId) => {
        return (axios.defaults.headers.common[accountIdHeaderName] = accountId);
    };

    return (
        <EdsPage title={t('3cd15f8f2940aff879df34df4e5c2cd1', 'History')}>
            <UmoSearchHistoryAlarmTable
                ref={tableRef}
                title={null}
                onRowClick={(row) => {
                    const accountId = getAccountIdFromRow(row?.id);
                    if (!_.isNil(accountId)) {
                        navigate(`/account/${accountId}/crm/alarm/${row.id}`);
                    }
                }}
                showActionButtonsAsOverflowMenu={true}
                onNavigateToClick={async (row) => {
                    const alarmId = row?.id;
                    const accountId = getAccountIdFromRow(alarmId);
                    await setAccountId(accountId);

                    const alarm = await getAlarm(alarmId, accountId);

                    try {
                        await postReopenAlarm({
                            alarm: {
                                account_id: alarm?.accountIdentifier,
                                correlation_id: alarm?.dataCorrelationId,
                                device_code: alarm?.deviceCode,
                                device_id: alarm?.deviceId ?? 0,
                                client_id: alarm?.clientId ?? 0,
                                residence_id: alarm?.residenceId ?? 0,
                                alarm_id: alarmId,
                            },
                        });

                        showSuccess({
                            title: t(
                                '4e57415c18b99db452165b30706630f4',
                                'Reopened'
                            ),
                            content: t(
                                '5cdf99c0edea674238dc70a11cbac34f',
                                'The alarm is reopened'
                            ),
                        });
                        navigate('/alarm');
                    } catch (error) {
                        showError({
                            title: t(
                                'cb5e100e5a9a3e7f6d1fd97512215282',
                                'Error'
                            ),
                            content: t(
                                'df0a9d46baf7315909e4389a04786e3d',
                                'Oops something went wrong'
                            ),
                        });
                        logger.warn('[postReopenAlarm] error:', error.message);
                    }
                }}
                navigateToLabel={t(
                    '4927d3429836bc62df5fd6f20a65e5ee',
                    'Reopen alarm'
                )}
            />
        </EdsPage>
    );
}

export default HistoryPage;
