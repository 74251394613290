import { Button, TextArea } from '@carbon/react';
import { Time } from '@carbon/icons-react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm, useFormGroup } from '../eds-form';
import _ from 'lodash';

import './eds-text-area.scss';
import { debounce, useAuth } from '../../../features';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

export const EdsTextArea = forwardRef((props, ref) => {
    const {
        name,
        label,
        forceRequired,
        placeholder,
        enableCounter,
        counterMode,
        maxCount,
        rows,
        helperText,
    } = props;
    const {
        getFormValue,
        handleFormChange,
        handleOnBlur,
        formatLabel,
        isInvalid,
        getInvalidText,
    } = useForm();
    const { prefix, usePrefix } = useFormGroup();

    let prefixedName = usePrefix(name) ?? name;
    const { updateFormValues } = useForm();

    const updateText = (text) => {
        let formChanges = {};
        formChanges[prefixedName] = text;
        updateFormValues(formChanges);
    };

    const appendText = (text = '') => {
        if (!_.isEmpty(text)) {
            const currentText = getValue() ?? '';
            const newText = [currentText.trim(), text]
                .filter(Boolean)
                .join(' ');
            updateText(newText);
        }
    };

    useImperativeHandle(ref, () => ({
        updateText,
        appendText,
        getValue,
    }));

    const getValue = () => {
        const value = getFormValue(name, prefix);

        if (!_.isUndefined(value) && !_.isNull(value)) {
            if (!_.isPlainObject(value)) {
                return value;
            }

            if ('value' in value) {
                return value[value];
            }
        }
        return '';
    };

    return (
        <TextArea
            className="eds-text-area"
            id={prefixedName}
            name={prefixedName}
            labelText={
                _.isNil(label)
                    ? ''
                    : formatLabel(label, name, prefix, forceRequired)
            }
            placeholder={placeholder}
            value={getValue()}
            helperText={helperText}
            rows={rows}
            enableCounter={enableCounter}
            counterMode={counterMode}
            maxCount={maxCount}
            onChange={handleFormChange}
            onBlur={handleOnBlur}
            invalid={isInvalid(prefixedName)}
            invalidText={getInvalidText(prefixedName)}
        ></TextArea>
    );
});
EdsTextArea.displayName = 'EdsTextArea';

export const EdsTextAreaTimestamp = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const textAreaRef = useRef();
    const onClickTimeoutId = useRef(null);
    const { attributes } = useAuth();

    const timestampFormat = t(
        'c063848f55ed9fb9f201e31ab3fb3ad6',
        'MM/dd/yyyy h:mm:ss a'
    );

    const addTimestampLabel = t(
        '1a726e590fc8920a9d62cd4738b7563b',
        'Add timestamp'
    );

    const getRef = () => {
        return !_.isNil(ref) ? ref : textAreaRef;
    };

    const onClickCallback = () => {
        debounce(onClickTimeoutId, () => addTimestamp(), {
            trailing: false,
            delay: 500,
        });
    };

    const addTimestamp = () => {
        if (_.isNil(getRef()?.current)) {
            return;
        }

        const timestamp = DateTime.now().toFormat(timestampFormat);
        const user = attributes?.user?.name;

        getRef()?.current?.appendText(
            `${
                !_.isEmpty(getRef()?.current.getValue()) ? '\n' : ''
            }[${timestamp} | ${user}] `
        );
    };

    return (
        <div className="eds-text-area-timestamp">
            <EdsTextArea {...props} ref={getRef()} />
            <Button
                renderIcon={Time}
                iconDescription={addTimestampLabel}
                kind={props.kind ?? 'tertiary'}
                size={props.size ?? 'sm'}
                hasIconOnly={props.hasIconOnly ?? false}
                tooltipAlignment="start"
                onClick={onClickCallback}
            >
                {addTimestampLabel}
            </Button>
        </div>
    );
});
EdsTextAreaTimestamp.displayName = 'EdsTextAreaTimestamp';
