import { useRouteLoaderData } from 'react-router';
import { EdsContainer, EdsMaps } from '../../../components';
import { useTranslation } from 'react-i18next';
import { getMapMarkers } from '../../../features/utils/maps';

function MapTab() {
    const { t } = useTranslation();
    const { googleMapsSettings, devicePositions } = useRouteLoaderData(
        'alarm-detail/location'
    );

    return (
        <EdsContainer title={t('1d78dc8ed51214e518b5114fe24490ae', 'Map')}>
            <EdsMaps
                apiKey={googleMapsSettings?.apiKey}
                markers={getMapMarkers(devicePositions)}
            />
        </EdsContainer>
    );
}

export default MapTab;
