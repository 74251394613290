import { FlexGrid, Form, Stack } from '@carbon/react';
import './eds-form.scss';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import {
    debounce,
    extractFormSchemaFromDefinition,
    getLogger,
    useEffectOnMount,
} from '../../../features';
import EdsFormProvider from './eds-form-provider';
import { useForm } from './';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { EdsButton, EdsButtonSet } from '../eds-button/eds-button';

const logger = getLogger('EdsForm');

const EdsFormContent = forwardRef((props, ref) => {
    const submitTimeoutId = useRef(null);
    const { t } = useTranslation();
    const {
        getMappedForm,
        addFormDefinition,
        setInitFormValues,
        replaceFormValues,
        form,
        checkIsFormChanged,
        checkInputs,
        checkInputsSection,
    } = useForm();

    useEffect(() => {
        addFormDefinition(props.formDefinition);
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formDefinition]);

    useEffectOnMount(() => {
        setInitFormValues(props.initValues);
    });

    const resolveHandleSubmitForm = async (
        resolve,
        handleSubmitFormFunc,
        event
    ) => {
        return resolve(handleSubmitFormFunc(event));
    };

    const submitForm = async (event) => {
        return new Promise((resolve) => {
            debounce(submitTimeoutId, resolveHandleSubmitForm, {
                params: [resolve, handleSubmitForm, event],
            });
        });
    };

    /*eslint no-undef: "off"*/
    const handleSubmitForm = async (event) => {
        const isValid = await checkInputs();
        const mappedForm = await getMappedForm(form);
        if (_.isFunction(props.onSubmit)) {
            return props.onSubmit(event, mappedForm, isValid, form);
        }

        return true;
    };

    const cancelForm = () => {
        if (_.isFunction(props.onCancel)) {
            props.onCancel();
        }
    };

    const getState = () => {
        return form;
    };

    const validateForm = async () => {
        logger.log('validateForm');
        return await checkInputs();
    };

    const validateFormWithConfig = async (scheme = {}) => {
        logger.log('validateFormWithConfig', scheme);
        return await checkInputsSection(scheme);
    };

    const setSavedState = (state) => {
        if (!_.isUndefined(props.formDefinition)) {
            logger.log(props.formDefinition);
        }
        replaceFormValues(state);
    };

    const getInitialValues = (initialConfig) => {
        return extractFormSchemaFromDefinition(initialConfig);
    };

    const resetForm = () => {
        replaceFormValues(props.initValues);
    };

    const isFormChanged = () => {
        return checkIsFormChanged();
    };

    useImperativeHandle(ref, () => ({
        submitForm,
        isFormChanged,
        getState,
        validateForm,
        validateFormWithConfig,
        setSavedState,
        resetForm,
        getInitialValues,
    }));

    const getStyle = () => {
        let classes = ['eds-form'];
        if (props?.inlineForm) {
            classes.push('eds-inline-form');
        }
        return classes.join(' ');
    };

    return (
        <div className={getStyle()}>
            <Form
                onSubmit={(event) => {
                    event.preventDefault();
                }}
            >
                <FlexGrid fullWidth>
                    <Stack gap={6}>{props.children}</Stack>
                    {(!props.hideDefaultSubmit || props.showDefaultCancel) && (
                        <EdsButtonSet
                            align={
                                !props.hideDefaultSubmit &&
                                props.showDefaultCancel
                                    ? 'space-between'
                                    : 'right'
                            }
                        >
                            {props.showDefaultCancel && (
                                <EdsButton kind="tertiary" onClick={cancelForm}>
                                    {t(
                                        '10aec35353f9c4096a71c38654c3d402',
                                        'Cancel'
                                    )}
                                </EdsButton>
                            )}
                            {!props.hideDefaultSubmit && (
                                <EdsButton onClick={submitForm}>
                                    {t(
                                        '43781db5c40ecc39fd718685594f0956',
                                        'Save'
                                    )}
                                </EdsButton>
                            )}
                        </EdsButtonSet>
                    )}
                </FlexGrid>
            </Form>
        </div>
    );
});
EdsFormContent.displayName = 'EdsFormContent';

export const EdsForm = forwardRef((props, ref) => {
    return (
        <EdsFormProvider {...props}>
            <EdsFormContent {...props} ref={ref} />
        </EdsFormProvider>
    );
});
EdsForm.displayName = 'EdsForm';

export const EdsInlineForm = forwardRef((props, ref) => {
    return (
        <EdsForm
            ref={ref}
            inlineForm={true}
            showDefaultCancel={true}
            hideDefaultSubmit={false}
            {...props}
        />
    );
});
EdsInlineForm.displayName = 'EdsInlineForm';
