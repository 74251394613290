import {
    EdsContainerGrid,
    UmoClientContractInfoGrid,
    UmoClientInsuranceInfoGrid,
    UmoClientPersonalInfoGrid,
    UmoPhoneNumbersTable,
    UmoResidenceInfoGrid,
} from '../../../components';
import { useRouteLoaderData } from 'react-router';
import { getClientPhoneNumbers } from '../../../features';

function ClientTab() {
    const { residence } = useRouteLoaderData('alarm-detail/id');
    const { client } = useRouteLoaderData('alarm-detail/clients/id');

    return (
        <EdsContainerGrid>
            <UmoClientPersonalInfoGrid entity={client} />

            <UmoPhoneNumbersTable
                showIndirectPhoneNumbers={true}
                getDataCallback={() =>
                    getClientPhoneNumbers(client.id, { includeIndirect: true })
                }
                onDeleteClick={undefined}
                onEditClick={undefined}
                onHeaderAddClick={undefined}
            />

            <UmoResidenceInfoGrid residence={residence} />
            <UmoClientContractInfoGrid entity={client} />
            <UmoClientInsuranceInfoGrid entity={client} />
        </EdsContainerGrid>
    );
}

export default ClientTab;
