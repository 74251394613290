import './umo-control-phone.scss';
import {
    Asterisk,
    DirectionMerge,
    DirectionRight_01,
    Hashtag,
    Microphone,
    Number_0,
    Number_1,
    Number_2,
    Number_3,
    Number_4,
    Number_5,
    Number_6,
    Number_7,
    Number_8,
    Number_9,
    Pause,
    Phone,
    PhoneOff,
    PhoneVoice,
    Play,
    Switcher,
    UserAvatar,
} from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { UmoControlPanelComponent } from '../umo-control-panel-component/umo-control-panel-component';
import { TextInput } from '@carbon/react';

import { createRef, useEffect, useState } from 'react';
import {
    AlarmControlIconButton,
    AlarmControlIconButtonWrapper,
    UmoAlarmControlIconButtonType,
} from '../umo-alarm-control-icon-button/umo-alarm-control-icon-button';
import {
    extractFormSchemaFromDefinition,
    toJSDate,
    useAlarms,
    useSoftphone,
    SoftphoneConnectionState,
} from '../../../../features';
import _ from 'lodash';
import { EdsNotification } from '../../../eds';
import { DateTime } from 'luxon';

const phoneNumberSchema = extractFormSchemaFromDefinition({
    phoneNumber: { validation: { type: 'phone-number' } },
});

export function UmoAlarmControlPhone() {
    const { t } = useTranslation();
    const { operableAlarm } = useAlarms();
    const {
        holdCall,
        registerSip,
        connectionState,
        sessions,
        remoteStreams,
        dialInput,
        dialoutHandle,
        unholdCall,
        getActiveSession,
        dialpadInput,
        setDialInput,
        startDialout,
        endDialout,
    } = useSoftphone();
    const [showConnectionState, setShowConnectionState] =
        useState(connectionState);
    const [sessionsList, setSessionsList] = useState([]);
    const [currentDialInput, setCurrentDialInput] = useState(dialInput);
    const [isStartDialoutDisabled, setIsStartDialoutDisabled] = useState(
        _.isEmpty(dialInput) || _.isNil(operableAlarm?.id)
    );
    const [isEndDialoutDisabled, setIsEndDialoutDisabled] = useState(
        _.isEmpty(dialoutHandle)
    );
    const [isDialerDisabled, setIsDialerDisabled] = useState(
        // TODO UMO-800 Allow CRM dialout without alarm context
        _.isNil(operableAlarm?.id)
    );
    const [isDialerExpanded, setIsDialerExpanded] = useState(false);
    const [audioRefs, setAudioRefs] = useState({});

    useEffect(() => {
        registerSip();
    }, [registerSip]);

    useEffect(() => {
        setShowConnectionState(connectionState);
    }, [connectionState]);

    useEffect(
        () => {
            const inputWasEmpty = _.isEmpty(currentDialInput);
            // Update current input value when changed
            if (dialInput !== currentDialInput) {
                setCurrentDialInput(dialInput);
            }
            // Expand dialer when dialinput is filled in from softphone provider
            if (
                inputWasEmpty &&
                !_.isEmpty(dialInput) &&
                !isDialerExpanded &&
                !isDialerDisabled
            ) {
                setIsDialerExpanded(true);
            }
        },
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dialInput, isDialerDisabled, isDialerExpanded, setIsDialerExpanded]
    );

    useEffect(() => {
        // TODO UMO-800 Allow CRM dialout without alarm context
        if (_.isNil(operableAlarm?.id)) {
            setIsDialerDisabled(true);
            setIsDialerExpanded(false);
        } else {
            setIsDialerDisabled(false);
        }
    }, [operableAlarm, setIsDialerDisabled, setIsDialerExpanded]);

    useEffect(() => {
        let isValid;
        if (_.isEmpty(currentDialInput)) {
            isValid = false;
        } else {
            isValid = phoneNumberSchema.isValidSync({
                phoneNumber: currentDialInput,
            });
        }
        // TODO UMO-800 Allow CRM dialout without alarm context
        setIsStartDialoutDisabled(!isValid || _.isNil(operableAlarm?.id));
    }, [setIsStartDialoutDisabled, currentDialInput, operableAlarm]);

    useEffect(() => {
        setIsEndDialoutDisabled(_.isEmpty(dialoutHandle));
    }, [setIsEndDialoutDisabled, dialoutHandle]);

    useEffect(
        () => {
            if (currentDialInput !== dialInput) {
                setDialInput(currentDialInput);
            }
        },
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentDialInput]
    );

    useEffect(() => {
        // TODO UMO-801 Refactor sessions list
        let newSessionsList = [];
        for (let index in sessions) {
            let session = sessions[index];
            let identity = session.remote_identity;
            let title;
            if (!_.isEmpty(identity.display_name)) {
                title = identity.display_name;
            } else if (!_.isEmpty(identity.uri?.user)) {
                title = identity.uri.user;
            } else {
                title = session.id;
            }
            // TODO Sessions are not updated when on hold changes, this does not work
            let onHold = session.isOnHold();
            let sessionItem = {
                id: session.id,
                title: title,
                type: session.direction,
                date: session.start_time,
                onHold: onHold.local || onHold.remote,
            };
            newSessionsList.push(sessionItem);
        }
        setSessionsList(newSessionsList);
    }, [sessions]);

    useEffect(() => {
        if (_.isEmpty(remoteStreams)) {
            return;
        }
        let newAudioRefs = { ...audioRefs };

        let activeRemoteStreams = remoteStreams.filter(
            (remoteStream) => remoteStream.active
        );

        let activeRemoteStreamIds = activeRemoteStreams.map(
            (remoteStream) => remoteStream.id
        );

        let removeAudioRefIds = Object.keys(newAudioRefs).filter(
            (id) => activeRemoteStreamIds.indexOf(id) === -1
        );

        // Remove audio reference for inactive or remote streams that don't exist anymore
        for (let index in removeAudioRefIds) {
            let removeId = removeAudioRefIds[index];
            delete newAudioRefs[removeId];
        }

        // Add audio reference for active streams if they do not exist
        for (let index in activeRemoteStreams) {
            let remoteStream = remoteStreams[index];
            if (_.isNil(audioRefs[remoteStream.id])) {
                let audio = new Audio();
                audio.autoplay = true;
                audio.srcObject = remoteStream;
                newAudioRefs[remoteStream.id] = createRef(audio);
            }
        }

        setAudioRefs(newAudioRefs);
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteStreams]);

    const toggleDialerExpanded = () => {
        setIsDialerExpanded(!isDialerExpanded && !isDialerDisabled);
    };

    const getErrorNotificiationMessage = () => {
        if (showConnectionState === SoftphoneConnectionState.NoCredentials) {
            return t('6ad2fbffac42e7a764d02a739893a7ea', 'No SIP credentials');
        } else if (
            showConnectionState === SoftphoneConnectionState.Disconnected
        ) {
            return t('7de610296ee275bdaa320614942a0e72', 'No connection');
        } else if (
            showConnectionState === SoftphoneConnectionState.Unregistered
        ) {
            return t('7c0dccbfbb2879f5adf194147ec7b791', 'Not registered');
        } else {
            return '';
        }
    };

    return (
        <UmoControlPanelComponent
            icon={PhoneVoice}
            title={t('f7a42fe7211f98ac7a60a285ac3a9e87', 'Phone')}
            className="alarm-control-phone"
        >
            {showConnectionState !== SoftphoneConnectionState.Connected && (
                <EdsNotification
                    className="control-panel"
                    notification={{
                        inline: true,
                        kind: 'error',
                        title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                        hideCloseButton: true,
                        content: getErrorNotificiationMessage(),
                    }}
                />
            )}
            {Object.keys(audioRefs).map((key) => (
                <audio ref={audioRefs[key]} key={key} />
            ))}
            <div className="calls-list">
                {/* <AlarmControlCallItem
                    title={'Gaby van de Valk Boume Valk Bouman'}
                    type={'Personal alarm'}
                    date={new Date()}
                    onClick={() => {
                        holdCall();
                    }}
                />
                <AlarmControlCallItem
                    title={'Adam Andrew'}
                    type={'Outgoing call'}
                    date={new Date()}
                    onHold={true}
                    onClick={() => {
                        unholdCall();
                    }}
                /> */}
                {sessionsList.map((session, index) => (
                    <AlarmControlCallItem
                        key={index}
                        title={session.title}
                        type={session.type}
                        date={session.date}
                        onHold={session.onHold}
                        onClick={() => {
                            if (session.onHold) {
                                unholdCall(session.id);
                            } else {
                                holdCall(session.id);
                            }
                        }}
                    />
                ))}
            </div>
            <div className="call-buttons">
                <AlarmControlIconButtonWrapper>
                    <AlarmControlIconButton
                        icon={Microphone}
                        label={t('def9cbe59ce34853e7081f62b095e348', 'Mute')}
                        disabled={true}
                    />
                    <AlarmControlIconButton
                        icon={DirectionMerge}
                        label={t('65464c31b2e6ac04da1fcaa37c9bd9c7', 'Merge')}
                        disabled={true}
                    />
                    <AlarmControlIconButton
                        icon={DirectionRight_01}
                        label={t('965dbaac085fc891bfbbd4f9d145bbc8', 'Forward')}
                        disabled={true}
                    />
                    <AlarmControlIconButton
                        icon={UserAvatar}
                        label={t(
                            '4136a932eb7724a00cb87c3fb9e1ea1d',
                            'Contacts'
                        )}
                        disabled={true}
                    />
                    <AlarmControlIconButton
                        icon={Switcher}
                        label={t('1a6c0578a769fb76b1627dc2d300d706', 'Dialpad')}
                        onClick={toggleDialerExpanded}
                        isSelected={isDialerExpanded}
                        disabled={isDialerDisabled}
                    />
                    {_.isNil(getActiveSession()) ? (
                        <AlarmControlIconButton
                            icon={Phone}
                            label={t(
                                '6de514dbc026d5badb01f8ea18ffcc9b',
                                'Dial'
                            )}
                            type={UmoAlarmControlIconButtonType.Success}
                            onClick={startDialout}
                            disabled={isStartDialoutDisabled}
                        />
                    ) : (
                        <AlarmControlIconButton
                            icon={PhoneOff}
                            label={t(
                                'cc6145b2f3c30b5214a7a2e86d739f68',
                                'Hang up'
                            )}
                            type={UmoAlarmControlIconButtonType.Danger}
                            onClick={endDialout}
                            disabled={isEndDialoutDisabled}
                        />
                    )}
                </AlarmControlIconButtonWrapper>
            </div>

            {isDialerExpanded && (
                <div className="call-dialer">
                    <TextInput
                        name="number"
                        labelText=" "
                        id="number"
                        value={currentDialInput}
                        onChange={(event) => {
                            if (!_.isNil(event?.target?.value)) {
                                setCurrentDialInput(event.target.value);
                            }
                        }}
                    />
                    <div className="dialer-numpad">
                        <AlarmControlIconButtonWrapper>
                            <AlarmControlDialerNumpadKey
                                icon={Number_1}
                                label={'1'}
                                onClick={() => dialpadInput(1)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_2}
                                label={'2'}
                                onClick={() => dialpadInput(2)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_3}
                                label={'3'}
                                onClick={() => dialpadInput(3)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_4}
                                label={'4'}
                                onClick={() => dialpadInput(4)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_5}
                                label={'5'}
                                onClick={() => dialpadInput(5)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_6}
                                label={'6'}
                                onClick={() => dialpadInput(6)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_7}
                                label={'7'}
                                onClick={() => dialpadInput(7)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_8}
                                label={'8'}
                                onClick={() => dialpadInput(8)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_9}
                                label={'9'}
                                onClick={() => dialpadInput(8)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Asterisk}
                                size={16}
                                label={'*'}
                                onClick={() => dialpadInput('*')}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Number_0}
                                label={'0'}
                                onClick={() => dialpadInput(0)}
                            />
                            <AlarmControlDialerNumpadKey
                                icon={Hashtag}
                                size={16}
                                label={'#'}
                                onClick={() => dialpadInput('#')}
                            />
                        </AlarmControlIconButtonWrapper>
                    </div>
                </div>
            )}
        </UmoControlPanelComponent>
    );
}

export function AlarmControlDialerNumpadKey({
    size = 20,
    icon,
    label,
    onClick,
}) {
    return (
        <div className="dialer-numpad-key">
            <AlarmControlIconButton
                icon={icon}
                size={size}
                label={label}
                onClick={onClick}
            />
        </div>
    );
}

export function AlarmControlCallItem({ title, type, date, onHold, onClick }) {
    const [dtStart, setDtStart] = useState();
    const [duration, setDuration] = useState('00:00');

    const calculateAlarmAge = () => {
        const now = DateTime.now();
        setDuration(now.diff(dtStart).toFormat('mm:ss'));
    };

    useEffect(() => {
        if (!_.isUndefined(dtStart)) {
            const timer = setTimeout(() => {
                calculateAlarmAge();
            }, 1000);

            return () => clearTimeout(timer);
        }
    });

    useEffect(() => {
        const jsdate = toJSDate(date, true);
        if (_.isDate(jsdate)) {
            setDtStart(DateTime.fromJSDate(jsdate));
        }
    }, [date]);

    useEffect(() => {
        if (!_.isNil(dtStart)) {
            calculateAlarmAge();
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dtStart]);

    const getStyle = () => {
        let classes = ['call-wrapper'];
        if (onHold) {
            classes.push('on-hold');
        }

        return classes.join(' ');
    };

    return (
        <div className={getStyle()}>
            <div className="call-details">
                <div className="detail-header">{title}</div>
                <div>
                    {duration} {type}
                </div>
            </div>
            <div className="call-buttons">
                <AlarmControlIconButtonWrapper>
                    {onHold ? (
                        <AlarmControlIconButton
                            icon={Play}
                            label="Resume call"
                            onClick={onClick}
                        />
                    ) : (
                        <AlarmControlIconButton
                            icon={Pause}
                            label="Pause call"
                            onClick={onClick}
                        />
                    )}
                </AlarmControlIconButtonWrapper>
            </div>
        </div>
    );
}
