import { useRef, useState } from 'react';
import {
    EdsContainerTable,
    EdsTableCheckmark,
    UmoImageView,
    UmoPdfView,
} from '../../..';
import { useTranslation } from 'react-i18next';
import {
    getFileSizeLabel,
    putClientDocument,
    useAuth,
    useModal,
    useNotifications,
} from '../../../../features';
import UmoDocumentForm from './umo-document-form';
import _ from 'lodash';
import UmoDocumentSettingsForm from './umo-document-settings-form';

export const UmoDocumentTypes = Object.freeze({
    Image: 'IMAGE',
    Pdf: 'PDF',
    Word: 'WORD',
    Spreadsheet: 'SPREADSHEET',
    Text: 'TEXT',
    Email: 'EMAIL',
    Other: 'OTHER',
});

export const UmoDocumentsTable = ({
    entity,
    postDataCallback,
    getDataCallback,
    getUrlCallback,
    permissions,
    deleteDataCallback,
    accountId: propAccountId,
    visibleHeaderKeys = ['name', 'sizeLabel', 'contentTypeLabel'],
    ...props
}) => {
    const { t } = useTranslation();
    const { accountId } = useAuth();
    const { showWarning } = useNotifications();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const [title] = useState(
        props.title ?? t('21f64da1e5792c8295b964d159a14491', 'Documents')
    );

    const { showDeleteModal, showFormModal, showModal } = useModal();

    const maxFileSize = props.maxFileSize || 5000000;
    const allowedTypes = props.allowedTypes || [
        'application/msexcel',
        'application/msword',
        'application/octet-stream',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.ms-outlook',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/png',
        'image/tiff',
        'message/rfc822',
        'text/plain',
    ];

    const getAccountId = () => {
        if (_.isNil(accountId) && !_.isNil(propAccountId)) {
            return propAccountId;
        }
        return accountId;
    };

    const headers = [
        {
            key: 'name',
            header: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
            sort: 'name',
            search: 'name',
        },
        {
            key: 'sizeLabel',
            header: t('f7bd60b75b29d79b660a2859395c1a24', 'Size'),
            sort: 'size',
        },
        {
            key: 'contentTypeLabel',
            header: t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
            sort: 'contentTypeLabel',
        },
        {
            key: 'showDocumentInAlarm',
            header: t('b74cf8f97d6a1a4035d00e04ef7f06f2', 'Visible in alarm'),
            width: '200px',
        },
    ];

    const getDocumentType = (contentType) => {
        if (contentType.indexOf('image/') === 0) {
            return UmoDocumentTypes.Image;
        } else if (contentType === 'application/pdf') {
            return UmoDocumentTypes.Pdf;
        } else if (
            contentType.indexOf('msword') !== -1 ||
            contentType.indexOf('wordprocessingml') !== -1
        ) {
            return UmoDocumentTypes.Word;
        } else if (
            contentType.indexOf('excel') !== -1 ||
            contentType.indexOf('spreadsheet') !== -1
        ) {
            return UmoDocumentTypes.Spreadsheet;
        } else if (contentType.indexOf('text/') !== -1) {
            return UmoDocumentTypes.Text;
        } else if (
            contentType === 'message/rfc822' ||
            contentType === 'application/vnd.ms-outlook' ||
            contentType === 'application/octet-stream'
        ) {
            return UmoDocumentTypes.Email;
        }

        return UmoDocumentTypes.Other;
    };

    const contentTypeLabel = (contentType) => {
        let documentType = getDocumentType(contentType);
        if (documentType === UmoDocumentTypes.Image) {
            return t('78805a221a988e79ef3f42d7c5bfd418', 'Image');
        } else if (documentType === UmoDocumentTypes.Pdf) {
            return t('437175ba4191210ee004e1d937494d09', 'PDF');
        } else if (documentType === UmoDocumentTypes.Word) {
            return t('d2d983bc8c5cfdec6e6b34fa84ff1028', 'Word document');
        } else if (documentType === UmoDocumentTypes.Spreadsheet) {
            return t('28e6994ddf03d47d067b7af4298247eb', 'Spreadsheet');
        } else if (documentType === UmoDocumentTypes.Text) {
            return t('1cb251ec0d568de6a929b520c4aed8d1', 'Text');
        } else if (documentType === UmoDocumentTypes.Email) {
            return t('d68deec1d2e8936b3dfe684f7c83ace5', 'Email message');
        }

        return contentType;
    };

    const mappingCallback = (responseData) => {
        return {
            id: responseData.id,
            name: responseData.name,
            size: responseData.size,
            sizeLabel: getFileSizeLabel(responseData.size),
            contentType: responseData.contentType,
            contentTypeLabel: contentTypeLabel(responseData.contentType),
            showDocumentInAlarm: (
                <EdsTableCheckmark value={responseData.showDocumentInAlarm} />
            ),
            showDocumentInAlarmValue: responseData.showDocumentInAlarm,
        };
    };

    const getData = async () => {
        return getDataCallback(entity?.id);
    };

    const getDataRow = (row) => {
        let data = tableRef.current?.getRowData(row.id);

        let documentUrl = getUrlCallback(entity?.id, row.id, getAccountId());

        let documentType = getDocumentType(data.contentType);
        let fitContent = documentType === UmoDocumentTypes.Image;

        if (
            documentType !== UmoDocumentTypes.Image &&
            documentType !== UmoDocumentTypes.Pdf
        ) {
            showWarning({
                title: t('723c25877a86786664edc4b643c08a6f', 'Unsupported'),
                content: t(
                    '27508e1f083343f80100e5ebebbbeac8',
                    'Preview for this file type is unsupported'
                ),
            });
            return;
        }
        showModal({
            title: data.name,
            children: (
                <>
                    {documentType === UmoDocumentTypes.Image && (
                        <UmoImageView src={documentUrl} noCaching={true} />
                    )}
                    {documentType === UmoDocumentTypes.Pdf && (
                        <UmoPdfView src={documentUrl} noCaching={true} />
                    )}
                </>
            ),
            hidePrimaryButton: true,
            hideSecondaryButton: true,
            fitContent: fitContent,
            size: 'lg',
        });
    };

    const downloadDataRow = (row) => {
        let data = tableRef.current?.getRowData(row.id);
        let link = document.createElement('a');
        link.download = data?.name;
        link.href = getUrlCallback(entity?.id, row.id, getAccountId());
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const showDataModal = () => {
        showFormModal({
            newTitle: t('5076c6e5d45430e6aa1b564cade6ee8b', 'Add document'),
            formData: {},
            formRef,
            tableRef,
            primaryButtonText: t('76ee3de97a1b8b903319b7c013d8c877', 'Upload'),
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.fetchInitData();
                }
                removeModal();
            },
            children: (
                <UmoDocumentForm
                    postDataCallback={postDataCallback}
                    entity={entity}
                    ref={formRef}
                    hideDefaultSubmit={true}
                    maxFileSize={maxFileSize}
                    allowedTypes={allowedTypes}
                ></UmoDocumentForm>
            ),
        });
    };

    const getVisibleHeaders = () => {
        if (!visibleHeaderKeys) {
            return headers;
        }

        const test = visibleHeaderKeys.map((key) =>
            headers.find((h) => h.key === key)
        );

        return test;
    };

    return (
        <EdsContainerTable
            title={title}
            ref={tableRef}
            headers={getVisibleHeaders()}
            getDataCallback={getData}
            enableSearchParams={false}
            mappingCallback={mappingCallback}
            onRowClick={(row) => {
                getDataRow(row);
            }}
            onDownloadClick={(row) => {
                downloadDataRow(row);
            }}
            onDeleteClick={(row) => {
                const name = tableRef.current?.getColumnValueString(
                    row.id,
                    'name'
                );
                showDeleteModal({
                    name: name,
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deleteDataCallback(entity?.id, row.id);
                    },
                });
            }}
            onHeaderAddClick={() => showDataModal()}
            permissions={permissions}
            searchClientSide={true}
            enableSearch={true}
            hasPaging={false}
            onEditClick={(row) => {
                let data = tableRef.current?.getRowData(row.id);
                showFormModal({
                    title: t(
                        '1bfab461e9c44bcb049c150e34dda5d7',
                        'Edit document settings'
                    ),
                    formRef,
                    tableRef,
                    onSubmitFormResponse: async (response, removeModal) => {
                        if (response) {
                            tableRef.current?.fetchInitData();
                        }
                        removeModal();
                    },
                    children: (
                        <UmoDocumentSettingsForm
                            hideDefaultSubmit={true}
                            ref={formRef}
                            tableRef={tableRef}
                            putCallbackUrl={putClientDocument}
                            initValues={{
                                clientId: entity.id,
                                documentId: data.id,
                                showDocumentInAlarm:
                                    data.showDocumentInAlarmValue,
                            }}
                        />
                    ),
                });
            }}
            {...props}
        />
    );
};
