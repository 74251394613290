export const ModalActions = {
    ShowModal: 'SHOW-MODAL',
    RemoveModal: 'REMOVE-MODAL',
    RemoveAllModal: 'REMOVE-ALL-MODAL',
};

export const modalReducer = (state, action) => {
    switch (action.type) {
        case ModalActions.RemoveModal: {
            return {
                ...state,
                modal: state.modal.filter((modal) => modal.id !== action.id),
            };
        }
        case ModalActions.RemoveAllModal: {
            return {
                ...state,
                modal: [],
            };
        }
        case ModalActions.ShowModal: {
            return {
                ...state,
                modal: [...state.modal, action.payload],
            };
        }
    }
};
