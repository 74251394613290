import './umo-control-device.scss';
import { useTranslation } from 'react-i18next';
import {
    Close,
    Devices,
    VolumeDownAlt,
    VolumeUpAlt,
} from '@carbon/icons-react';
import { UmoControlPanelComponent } from '../umo-control-panel-component/umo-control-panel-component';
import {
    ContentSwitcher,
    OverflowMenu,
    OverflowMenuItem,
    Switch,
    Toggle,
} from '@carbon/react';
import { useEffect, useState } from 'react';
import {
    AlarmControlIconButton,
    AlarmControlIconButtonWrapper,
    UmoAlarmControlIconButtonType,
} from '../umo-alarm-control-icon-button/umo-alarm-control-icon-button';
import {
    postAlarmCloseDevice,
    useAlarms,
    useNotifications,
} from '../../../../features';
import _ from 'lodash';

export function UmoAlarmControlDevice() {
    const { t } = useTranslation();
    const { operableAlarm } = useAlarms();
    const { showError } = useNotifications();

    const [fullDuplexEnabled, setFullDuplexEnabled] = useState(false);
    const toggleFullDuplex = () => {
        setFullDuplexEnabled(!fullDuplexEnabled);
    };
    const [isCloseDeviceDisabled, setIsCloseDeviceDisabled] = useState(
        _.isNil(operableAlarm?.id) || !!operableAlarm?.device_closed
    );

    useEffect(() => {
        setIsCloseDeviceDisabled(
            _.isNil(operableAlarm?.id) || !!operableAlarm?.device_closed
        );
    }, [setIsCloseDeviceDisabled, operableAlarm]);

    const closeDevice = async () => {
        try {
            await postAlarmCloseDevice(operableAlarm?.id);
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <UmoControlPanelComponent
            icon={Devices}
            title={t('39aa5f03f2e211ee6e6088099d56a9c2', 'Device controls')}
            className="alarm-control-device"
        >
            <div className="device-details">
                {/* TODO Add device details when available */}
                {/* <div className="detail-header">
                    <div className="detail-header-title">APG-300S</div>
                    <div className="detail-header-subtitle">
                        O VL Tunstall Caresse Lite
                    </div>
                </div> */}

                <div className="duplex-toggle">
                    <Toggle
                        labelA={t(
                            'be9289739753c131848f2f07efd33621',
                            'Half duplex'
                        )}
                        labelB={t(
                            'b31bf13d37c2e0c381cdee7a079ffaae',
                            'Full duplex'
                        )}
                        toggled={fullDuplexEnabled}
                        size="sm"
                        id="duplex-toggle"
                        disabled={true}
                        onClick={toggleFullDuplex}
                    />

                    {!fullDuplexEnabled && (
                        <div className="half-duplex-toggle">
                            <ContentSwitcher size="sm" onChange={() => {}}>
                                <Switch
                                    name="listening"
                                    disabled={true}
                                    text={t(
                                        'a6e71e02cd9c6565c2ad2fbaf135bf64',
                                        'Listening'
                                    )}
                                />
                                <Switch
                                    name="speaking"
                                    disabled={true}
                                    text={t(
                                        'd1715efc5a67ac1c988152b8136e3dfa',
                                        'Speaking'
                                    )}
                                />
                            </ContentSwitcher>
                        </div>
                    )}
                </div>
            </div>
            <div className="device-controls">
                <AlarmControlIconButtonWrapper>
                    <AlarmControlIconButton
                        icon={VolumeUpAlt}
                        disabled={true}
                        label={t(
                            '94cdaa0470753e5097f5ebb729b2387d',
                            'Volume up'
                        )}
                    />
                    <AlarmControlIconButton
                        icon={VolumeDownAlt}
                        disabled={true}
                        label={t(
                            '4f27abb939d2941b7554aee40f2441b4',
                            'Volume down'
                        )}
                    />
                    <AlarmControlIconButton
                        icon={Close}
                        disabled={isCloseDeviceDisabled}
                        type={UmoAlarmControlIconButtonType.Danger}
                        label={t(
                            'eecccd7bc30352690b2dbc58a24799a8',
                            'Close device'
                        )}
                        onClick={closeDevice}
                    />

                    <OverflowMenu
                        className="alarm-control-icon-button"
                        menuOptionsClass="alarm-control-icon-menu"
                        size="sm"
                        aria-label="more device controls"
                        disabled={true}
                    >
                        <OverflowMenuItem itemText="Door open" />
                        <OverflowMenuItem itemText="Key safe open" />
                        <OverflowMenuItem itemText="BS Inelelink override speech" />
                    </OverflowMenu>
                </AlarmControlIconButtonWrapper>
            </div>
        </UmoControlPanelComponent>
    );
}
