import { Outlet } from 'react-router';
import { AccountSettingsRoutes } from './account-settings';
import { AlarmSettingsRoutes } from './alarm-settings';
import { ResidenceSettingsRoutes } from './residence-settings';
import { DeviceSettingsRoutes } from './device-settings';
import { MedicalSettingsRoutes } from './medical-settings';
import { ClientSettingsRoutes } from './client-settings';
import { PeripheralSettingsRoutes } from './peripheral-settings';
import { TenantSettingsRoutes } from './tenant-settings';
import { CrmMaintenancePermissionSet, Restricted } from '../../../features';

const MaintenanceRoutes = {
    path: 'maintenance',
    element: (
        <Restricted
            to={CrmMaintenancePermissionSet}
            requiresAll={false}
            redirect={true}
        >
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('57cb773ae7a82c8c8aae12fa8f8d7abd', 'Maintenance'),
        routable: false,
    },
    children: [
        // no index here as the below routes might not work because of dynamic scopes
        AccountSettingsRoutes,
        AlarmSettingsRoutes,
        ClientSettingsRoutes,
        DeviceSettingsRoutes,
        PeripheralSettingsRoutes,
        ResidenceSettingsRoutes,
        MedicalSettingsRoutes,
        TenantSettingsRoutes,
    ],
};

export default MaintenanceRoutes;
