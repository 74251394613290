import { Navigate } from 'react-router';
import MaintenanceRoutes from './maintenance';
import DevicePageRoutes from './device-page';
import ClientPageRoutes from './client-page';
import ResidencePageRoutes from './residence-page';
import PeripheralPageRoutes from './peripheral-page';
import ProfessionalCaregiverPageRoutes from './professional-caregiver-page';
import CareTeamPageRoutes from './care-team-page';
import SchemePageRoutes from './scheme-page';
import AlarmPageRoutes from './alarm-page';
import {
    AlarmReadPermissionSet,
    CareProviderReadPermissionSet,
    ClientReadPermissionSet,
    CrmMaintenancePermissionSet,
    DeviceReadPermissionSet,
    getLogger,
    ResidenceReadPermissionSet,
    SchemeReadPermissionSet,
    useAuth,
    usePermissions,
} from '../../features';
import _ from 'lodash';

const logger = getLogger('CrmRoutes');

const CrmIndexPage = () => {
    const { isAllowed } = usePermissions();
    const { logout } = useAuth();

    const mapping = [
        { path: ClientPageRoutes.path, permission: ClientReadPermissionSet },
        { path: DevicePageRoutes.path, permission: DeviceReadPermissionSet },
        {
            path: PeripheralPageRoutes.path,
            permission: DeviceReadPermissionSet,
        },
        {
            path: ResidencePageRoutes.path,
            permission: ResidenceReadPermissionSet,
        },
        {
            path: ProfessionalCaregiverPageRoutes.path,
            permission: CareProviderReadPermissionSet,
        },
        {
            path: CareTeamPageRoutes.path,
            permission: CareProviderReadPermissionSet,
        },
        { path: SchemePageRoutes.path, permission: SchemeReadPermissionSet },
        { path: AlarmPageRoutes.path, permission: AlarmReadPermissionSet },
        {
            path: MaintenanceRoutes.path,
            permission: CrmMaintenancePermissionSet,
        },
    ];

    let route = mapping.find((item) => isAllowed(item.permission));

    if (!_.isUndefined(route)) {
        logger.log('Navigate to first available route', route.path);
        return <Navigate to={route.path} relative={true} replace={true} />;
    }

    logout();
    return <Navigate to={'/auth/login'} />;
};

const CrmRoutes = {
    path: 'crm',
    handle: {
        crumb: (t) => t('95ffdefa64d418cd17ee38c3f467917e', 'CRM'),
    },
    children: [
        {
            index: true,
            element: <CrmIndexPage />,
        },
        ClientPageRoutes,
        DevicePageRoutes,
        MaintenanceRoutes,
        PeripheralPageRoutes,
        ResidencePageRoutes,
        ProfessionalCaregiverPageRoutes,
        CareTeamPageRoutes,
        SchemePageRoutes,
        AlarmPageRoutes,
    ],
};

export default CrmRoutes;
