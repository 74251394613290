import { lazy } from 'react';
import { Outlet } from 'react-router';
import {
    pageLoader as AlarmPageLoader,
    pageLoaderAlarmDevicePosition as AlarmPageLoaderLocations,
} from './details/loader';
import { AlarmReadPermissionSet, Restricted } from '../../../features';

const AlarmPage = lazy(() => import('./alarm-page'));
const AlarmPageDetails = lazy(() => import('./details'));

const DetailsTab = lazy(() => import('./details/details-tab'));
const CloseInformationTab = lazy(() =>
    import('./details/close-information-tab')
);
const ActionsTab = lazy(() => import('./details/actions-tab'));
const CareRegistrationTab = lazy(() =>
    import('./details/care-registration-tab')
);
const LocationTab = lazy(() => import('./details/location-tab'));
const AdditionalInformationTab = lazy(() =>
    import('./details/additional-information-tab')
);
const DebugTab = lazy(() => import('./details/debug-tab'));

const AlarmPageRoutes = {
    path: 'alarm',
    element: (
        <Restricted to={AlarmReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('6486b4e98228b83d4b13d54febe5f170', 'Alarm'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            element: <AlarmPage />,
        },
        {
            id: 'alarm/id',
            path: ':alarmId',
            element: <AlarmPageDetails />,
            loader: AlarmPageLoader,
            handle: {
                crumb: (_t, data) => data?.id,
                documentTitleParent: true,
            },
            children: [
                {
                    index: true,
                    element: <DetailsTab />,
                    handle: {
                        //for tabs to find that this route is the index
                        index: true,
                    },
                },
                {
                    path: 'close-information',
                    element: <CloseInformationTab />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '5c3569f83da40a0719a5fb53495baf1a',
                                'Close information'
                            ),
                    },
                },
                {
                    path: 'actions',
                    element: <ActionsTab />,
                    handle: {
                        crumb: (t) =>
                            t('ebb67a4271abe715344471b0f16321f6', 'Actions'),
                    },
                },
                {
                    path: 'care-registration',
                    element: <CareRegistrationTab />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '4ca03a697e41f65d4c4ac5e611f94bc2',
                                'Care registration'
                            ),
                    },
                },
                {
                    path: 'location',
                    element: <LocationTab />,
                    id: 'alarm/id/location',
                    loader: AlarmPageLoaderLocations,
                    handle: {
                        crumb: (t) =>
                            t(
                                '52532a4954cf0fb0f051cff9d19d9d3c',
                                'GPS Location'
                            ),
                    },
                },
                {
                    path: 'additional-information',
                    element: <AdditionalInformationTab />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '4eec452dee7d15acfb78a023b487bf19',
                                'Additional information'
                            ),
                    },
                },
                {
                    path: 'debug',
                    element: <DebugTab />,
                    handle: {
                        crumb: (t) =>
                            t('ad42f6697b035b7580e4fef93be20b4d', 'Debug'),
                    },
                },
            ],
        },
    ],
};

export default AlarmPageRoutes;
