import { useEffect, useState } from 'react';
import { useForm } from '../../../eds/eds-form';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
    EdsNotification,
    EdsWizardStep,
} from '../../../eds';
import { Row } from '@carbon/react';
import { UmoSearchProfessionalCaregiverTable } from '../umo-search-professional-caregiver-table';
import { UmoSearchCareTeamTable } from '../umo-search-care-team-table';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export const EdsSearchEntityType = Object.freeze({
    ProfessionalCaregiver: 'PROFESSIONAL_CAREGIVER',
    CareTeam: 'CARE_TEAM',
});

function SearchStep({
    id,
    selectedRow,
    filterIds = {},
    filter,
    onNewButtonClick,
    searchEntitySelection = true,
    ...props
}) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(selectedRow);
    const [searchEntityType, setSearchEntityType] = useState(
        EdsSearchEntityType.ProfessionalCaregiver
    );
    const { updateFormValues, isInvalid } = useForm();

    useEffect(() => {
        props.selectedRowCallback(selected);
    }, [props, selected]);

    useEffect(() => {
        if (!_.isUndefined(selected) && _.isString(selected)) {
            updateFormValues({ ['caregiver.caregiverId']: selected });
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const getEntityTypes = () => {
        return [
            {
                id: 1,
                name: t(
                    '599bfff4492f40356f6e8c9eb47f6996',
                    'Professional caregiver'
                ),
                searchEntityType: EdsSearchEntityType.ProfessionalCaregiver,
            },
            {
                id: 2,
                name: t('6e9de986c9d59a1e3f6284d77adbcb3e', 'Care team'),
                searchEntityType: EdsSearchEntityType.CareTeam,
            },
        ];
    };

    const mapSearchEntityTypes = (entityType) => {
        return {
            id: entityType.id,
            text: entityType.name,
            searchEntityType: entityType.searchEntityType,
        };
    };

    const formDefinition = {
        wizardStepId: id,
        searchEntity: {
            value: mapSearchEntityTypes(_.head(getEntityTypes())),
            validation: {
                type: 'dropdown',
                required: true,
            },
            mapping: 'searchEntityType',
        },
        caregiverId: {
            validation: {
                required: true,
            },
        },
    };

    const getSearchEntityName = () => {
        return searchEntityType === EdsSearchEntityType.ProfessionalCaregiver
            ? t('599bfff4492f40356f6e8c9eb47f6996', 'Professional caregiver')
            : t('6e9de986c9d59a1e3f6284d77adbcb3e', 'Care team');
    };

    const getBaseFilter = (searchEntityType) => {
        const ids =
            searchEntityType === EdsSearchEntityType.ProfessionalCaregiver
                ? filterIds?.professionalCaregiverIds
                : filterIds?.careTeamIds ?? [];

        let baseFilter = 'disabled eq false';
        if (!_.isEmpty(ids)) {
            baseFilter += ` and (id in (${ids
                .map((i) => `'${i}'`)
                .join(',')}) eq false)`;
        }

        return baseFilter;
    };

    return (
        <EdsWizardStep
            id={id}
            label={t(
                '61c3faea81769efbe2c213ed28c3a3b5',
                'Search professional caregiver'
            )}
        >
            <EdsFormGroup
                formDefinition={formDefinition}
                inStep={true}
                prefix="caregiver"
            >
                {isInvalid('caregiver.caregiverId') && (
                    <EdsNotification
                        notification={{
                            hideCloseButton: true,
                            kind: 'warning',
                            title: t(
                                '416af0b0716d2a63329f359ceed41c6f',
                                'Please select a {{entity}} to link',
                                {
                                    entity: getSearchEntityName(),
                                }
                            ),
                            inline: true,
                        }}
                    />
                )}

                {searchEntitySelection && (
                    <EdsFormGroup
                        prefix="caregiver"
                        formDefinition={formDefinition}
                        inStep={props.wizardStepId ?? false}
                    >
                        <Row>
                            <EdsFormColumn>
                                <EdsDropdown
                                    name={'searchEntity'}
                                    label={t(
                                        'b570914ddfe902ee4aa3f106a769e2bd',
                                        'Search for'
                                    )}
                                    type={EdsDropdownType.EdsDropdown}
                                    getDataCallback={getEntityTypes}
                                    mappingCallback={mapSearchEntityTypes}
                                    onChangeCallback={(event) => {
                                        setSearchEntityType(
                                            event?.selectedItem
                                                ?.searchEntityType
                                        );
                                        setSelected(undefined);
                                        updateFormValues(
                                            {
                                                ['caregiver.caregiverId']:
                                                    undefined,
                                            },
                                            true
                                        );
                                    }}
                                ></EdsDropdown>
                            </EdsFormColumn>
                        </Row>
                    </EdsFormGroup>
                )}

                <EdsFormGroup
                    prefix="caregiver"
                    formDefinition={formDefinition}
                    inStep={props.wizardStepId ?? false}
                >
                    {searchEntityType ===
                        EdsSearchEntityType.ProfessionalCaregiver && (
                        <UmoSearchProfessionalCaregiverTable
                            onRowClick={(row) => {
                                if (row.id !== selected) {
                                    setSelected(row.id);
                                }
                            }}
                            onSelectionButtonClick={(row) => {
                                if (row.id !== selected) {
                                    setSelected(row.id);
                                }
                            }}
                            onNewButtonClick={onNewButtonClick}
                            selected={[selected]}
                            enableRadioSelection={true}
                            size="md"
                            enableSearchParams={false}
                            searchFilterStore={undefined}
                            fetchServerSideDataOnInit={true}
                            enableSearch={true}
                            baseFilter={getBaseFilter(searchEntityType)}
                            filter={filter}
                        />
                    )}

                    {searchEntityType === EdsSearchEntityType.CareTeam && (
                        <UmoSearchCareTeamTable
                            onRowClick={(row) => {
                                if (row.id !== selected) {
                                    setSelected(row.id);
                                }
                            }}
                            onSelectionButtonClick={(row) => {
                                if (row.id !== selected) {
                                    setSelected(row.id);
                                }
                            }}
                            selected={[selected]}
                            enableRadioSelection={true}
                            size="md"
                            enableSearchParams={false}
                            searchFilterStore={undefined}
                            fetchServerSideDataOnInit={true}
                            enableSearch={true}
                            baseFilter={getBaseFilter(searchEntityType)}
                        />
                    )}
                </EdsFormGroup>
            </EdsFormGroup>
        </EdsWizardStep>
    );
}

export default SearchStep;
