import './eds-modal.scss';
import { Children, cloneElement, useRef } from 'react';
import {
    ComposedModal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '@carbon/react';
import { debounce, useModal } from '../../../features';
import { useTranslation } from 'react-i18next';
import { EdsButton } from '../eds-button/eds-button';
import _ from 'lodash';

export function EdsModal({
    modal,
    open,
    fitContent,
    children,
    label,
    title,
    primaryButtonText,
    secondaryButtonText,
    danger,
    primaryDanger,
    secondaryDanger,
    size,
    onRequestSubmit,
    onSecondarySubmit,
    hidePrimaryButton = false,
    hideSecondaryButton = false,
}) {
    const { t } = useTranslation();
    const { remove } = useModal();
    const buttonTimeoutId = useRef(null);

    const removeModal = () => {
        if (modal) {
            remove(modal.id);
        }
    };

    const getStyle = () => {
        return open ? {} : { visible: 'none' };
    };

    const getClasses = () => {
        let classes = ['eds-modal'];
        if (fitContent) {
            classes.push('eds--fit-content');
        }
        return classes.join(' ');
    };

    const buttonHandler = (func, params) => {
        if (_.isFunction(func)) {
            debounce(buttonTimeoutId, func, {
                params: params,
            });
        }
    };

    const getChildren = () => {
        if (!_.isNil(children) && _.isObject(children)) {
            children = Children.map(children, (el) => {
                return cloneElement(el, { modalId: modal.id });
            });
        }
        return children;
    };

    return (
        <div style={getStyle()}>
            <ComposedModal
                size={size || 'md'}
                className={getClasses()}
                preventCloseOnClickOutside={true}
                open={open}
                onClose={() => {
                    removeModal();
                }}
            >
                <ModalHeader
                    label={label}
                    title={title}
                    iconDescription={t(
                        '716f6b30598ba30945d84485e61c1027',
                        'Close'
                    )}
                />
                <ModalBody>{getChildren()}</ModalBody>

                {(!hideSecondaryButton || !hidePrimaryButton) && (
                    <ModalFooter className="eds-button-set">
                        {!hideSecondaryButton && (
                            <EdsButton
                                kind={
                                    danger || secondaryDanger
                                        ? 'danger--ghost'
                                        : 'secondary'
                                }
                                onClick={(event) => {
                                    if (_.isFunction(onSecondarySubmit)) {
                                        buttonHandler(onSecondarySubmit, [
                                            event,
                                            removeModal,
                                        ]);
                                    } else {
                                        removeModal();
                                    }
                                }}
                            >
                                {secondaryButtonText ||
                                    t(
                                        '10aec35353f9c4096a71c38654c3d402',
                                        'Cancel'
                                    )}
                            </EdsButton>
                        )}
                        {!hidePrimaryButton && (
                            <EdsButton
                                kind={
                                    danger || primaryDanger
                                        ? 'danger'
                                        : 'primary'
                                }
                                onClick={(event) => {
                                    buttonHandler(onRequestSubmit, [
                                        event,
                                        removeModal,
                                    ]);
                                }}
                            >
                                {primaryButtonText ||
                                    t(
                                        '43781db5c40ecc39fd718685594f0956',
                                        'Save'
                                    )}
                            </EdsButton>
                        )}
                    </ModalFooter>
                )}
            </ComposedModal>
        </div>
    );
}
