import { lazy } from 'react';
import { redirect } from 'react-router';
import {
    CrmMaintenanceDevicePermissionSet,
    Restricted,
} from '../../../../features';

const PeripheralSettingsPage = lazy(() => import('./peripheral-settings-page'));
const PeripheralTypePage = lazy(() =>
    import('./peripheral-type/peripheral-type-page')
);

export const PeripheralSettingsRoutes = {
    path: 'peripheral',
    element: (
        <Restricted to={CrmMaintenanceDevicePermissionSet} redirect={true}>
            <PeripheralSettingsPage />
        </Restricted>
    ),
    handle: {
        crumb: (t) =>
            t('e87e25cb3e6257d20d888eec3070625a', 'Peripheral settings'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            loader: () => redirect('type'),
        },
        {
            path: 'type',
            element: <PeripheralTypePage />,
            handle: {
                crumb: (t) => t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
            },
        },
    ],
};
