import {
    createSearchFilterStoreFunctions,
    getRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/alarms';

export const getAlarmSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const getAlarmSearchAllAccounts = async (search) => {
    return getRequest(`${basePath}/searchAllAccounts`, search);
};

export const getAlarm = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const putAlarm = async (alarm, headers) => {
    return putRequest(`${basePath}/${alarm?.id}`, alarm, headers);
};

export const getAlarmActions = async (id) => {
    return getRequest(`${basePath}/${id}/alarmActions`);
};

export const postAlarmAction = async (id, alarmAction) => {
    return postRequest(`${basePath}/${id}/alarmActions`, alarmAction);
};

export const getAlarmDetails = async (id) => {
    return getRequest(`${basePath}/${id}/alarmDetails`);
};

export const postAlarmDetail = async (id, alarmDetail, headers) => {
    return postRequest(
        `${basePath}/${id}/alarmDetails`,
        alarmDetail,
        undefined,
        headers
    );
};

export const getAlarmReasons = async (id) => {
    return getRequest(`${basePath}/${id}/alarmReasons`);
};

export const postAlarmReason = async (id, alarmReason, headers) => {
    return postRequest(
        `${basePath}/${id}/alarmReasons`,
        alarmReason,
        undefined,
        headers
    );
};

export const getAlarmRenderedHelp = async (id) => {
    return getRequest(`${basePath}/${id}/alarmRenderedHelp`);
};

export const postAlarmRenderedHelp = async (id, renderedHelp, headers) => {
    return postRequest(
        `${basePath}/${id}/alarmRenderedHelp`,
        renderedHelp,
        undefined,
        headers
    );
};

export const getCareRegistrations = async (id) => {
    return getRequest(`${basePath}/${id}/careRegistrations`);
};

export const getCareRegistrationsForProfessionalCaregiver = async (
    id,
    professionalCaregiverId,
    params
) => {
    return getRequest(
        `${basePath}/${id}/professionalCaregiver/${professionalCaregiverId}/careRegistrations`,
        params
    );
};

export const postCareRegistrationForProfessionalCaregiver = async (
    id,
    professionalCaregiverId,
    careRegistration
) => {
    return postRequest(
        `${basePath}/${id}/professionalCaregiver/${professionalCaregiverId}/careRegistrations`,
        careRegistration
    );
};

export const getCareRegistrationsForPersonalCaregiver = async (
    id,
    personalCaregiverId,
    params
) => {
    return getRequest(
        `${basePath}/${id}/personalCaregiver/${personalCaregiverId}/careRegistrations`,
        params
    );
};

export const postCareRegistrationForPersonalCaregiver = async (
    id,
    personalCaregiverId,
    careRegistration
) => {
    return postRequest(
        `${basePath}/${id}/personalCaregiver/${personalCaregiverId}/careRegistrations`,
        careRegistration
    );
};

export const putCareRegistration = async (id, careRegistration) => {
    return putRequest(`${basePath}/careRegistrations/${id}`, careRegistration);
};

export const getAlarmDevicePositions = async (id) => {
    return getRequest(`${basePath}/${id}/alarmDevicePositions`);
};

export const getAlarmQueue = async () => {
    return getRequest(`${basePath}/queue`);
};

export const getActiveAlarmQueue = async () => {
    return getRequest(`${basePath}/activeQueue`);
};

export const postAcceptAlarm = async (id) => {
    return postRequest(`${basePath}/accept`, {
        alarm_id: id,
    });
};

export const postHoldAlarm = async (id) => {
    return postRequest(`${basePath}/hold`, {
        alarm_id: id,
    });
};

export const postResumeAlarm = async (id) => {
    return postRequest(`${basePath}/resume`, {
        alarm_id: id,
    });
};

export const postCloseAlarmToHistory = async (id) => {
    return postRequest(`${basePath}/close_to_history`, {
        alarm_id: id,
    });
};

export const postCloseAlarmToFollowUp = async (id) => {
    return postRequest(`${basePath}/close_to_follow_up`, {
        alarm_id: id,
    });
};

export const postCreateManualAlarm = async (alarm) => {
    return postRequest(`${basePath}/create_manual_alarm`, alarm);
};

export const postReopenAlarm = async (alarm) => {
    return postRequest(`${basePath}/reopen_alarm`, alarm);
};

export const postAlarmDialout = async (
    accountId,
    alarmId,
    dialoutDestination
) => {
    return postRequest(`${basePath}/dialout`, {
        account_id: accountId,
        alarm_id: alarmId,
        dialout_destination: dialoutDestination,
    });
};

export const postAlarmEndDialout = async (
    accountId,
    alarmId,
    dialoutHandle
) => {
    return postRequest(`${basePath}/end_dialout`, {
        account_id: accountId,
        alarm_id: alarmId,
        dialout_handle: dialoutHandle,
    });
};

export const postAlarmCloseDevice = async (id) => {
    return postRequest(`${basePath}/close_device`, { alarm_id: id });
};

export const getAlarmState = async (id) => {
    return getRequest(`${basePath}/${id}/state`);
};

export const getOperableAlarm = async () => {
    return getRequest(`${basePath}/operable`);
};

export const getAlarmCareProviders = async (id) => {
    return getRequest(`${basePath}/${id}/careProviders`);
};

export const getAlarmPersonalCaregivers = async (id) => {
    return getRequest(`${basePath}/${id}/personalCaregivers`);
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'alarms'),
};

export const searchFilterStoreForHistory = {
    ...createSearchFilterStoreFunctions(
        `${basePath}/history`,
        'history-alarms'
    ),
};

export const searchFilterStoreForFollowUp = {
    ...createSearchFilterStoreFunctions(
        `${basePath}/follow-up`,
        'follow-up-alarms'
    ),
};

// See https://enovationgroup.atlassian.net/wiki/spaces/NUQ/pages/23365243/Alarm+Actions
export const EdsAlarmActionType = Object.freeze({
    UserDefined: 61,
    CareworkerMobilized: 67,
});
