import { forwardRef, useRef, useState } from 'react';
import { Row } from '@carbon/react';
import {
    EdsForm,
    EdsFormGroup,
    EdsFormColumn,
    EdsTextInput,
    EdsDropdown,
    EdsTableFilterOperator,
    EdsTableFilterType,
    EdsToggle,
    EdsDropdownType,
    EdsDatePicker,
    EdsDatePickerType,
    EdsPhoneNumber,
    EdsNumberInput,
    EdsFormGroupLocation,
    EdsFormGroupGroup,
    EdsTableDeleteButton,
    EdsActionButtonTypes,
    EdsFormGroupAlarmCriteria,
    EdsFormGroupTag,
} from '../..';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    defaultDropdownMappingCallback,
    getDate,
    getLogger,
} from '../../../../features';
import { v4 as uuid } from 'uuid';

const logger = getLogger('EdsTableFilterForm');

export function EdsTableCriteriaRangeForm({ repeatRef, ...props }) {
    const { t } = useTranslation();

    const testRange = (value, testContext) => {
        const range = props.prefix
            ? testContext?.options?.context?.form[props.prefix]
            : testContext?.options?.context?.form;

        return (
            _.isNil(range.from) ||
            _.isNil(range.to) ||
            Number(range.from) < Number(range.to)
        );
    };

    const formDefinition = {
        from: {
            value: props?.selectedValues?.from ?? 100,
            validation: {
                type: 'number',
                min: 100,
                max: 1000,
                required: true,
                tests: [
                    {
                        name: 'valid-form-value',
                        message: t(
                            '2425e942bbc420a94e8476eb5329653f',
                            'From must be lower than to'
                        ),
                        func: testRange,
                    },
                ],
            },
            dependents: ['to'],
        },
        to: {
            value: props?.selectedValues?.to ?? 199,
            validation: {
                type: 'number',
                min: 100,
                max: 1000,
                required: true,
                tests: [
                    {
                        name: 'valid-to-value',
                        message: t(
                            'b8ed11d46ca229dc14d01d44476b2489',
                            'To must be higher than from'
                        ),
                        func: testRange,
                    },
                ],
            },
            dependents: ['from'],
        },
    };

    return (
        <EdsFormGroup inFilter formDefinition={formDefinition} {...props}>
            <Row>
                <EdsFormColumn>
                    <EdsNumberInput
                        name={'from'}
                        label={t('d98a07f84921b24ee30f86fd8cd85c3c', 'From')}
                    />
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsNumberInput
                        name={'to'}
                        label={t('01b6e20344b68835c5ed1ddedf20d531', 'To')}
                    />
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsTableDeleteButton
                        align={'top-end'}
                        kind={'ghost'}
                        size={'sm'}
                        disabled={!repeatRef.current?.removeRepeatEnabled()}
                        type={EdsActionButtonTypes.Delete}
                        label={t(
                            '097f441e200f01b6cec54d6c8b4f9d9b',
                            'Remove range'
                        )}
                        onClick={() => {
                            repeatRef.current?.removeRepeat(props.repeatId);
                        }}
                    />
                </EdsFormColumn>
            </Row>
        </EdsFormGroup>
    );
}

export const EdsTableFilterForm = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const editMode = () => {
        return !_.isNil(props?.filterToEdit) && !_.isEmpty(props?.filterToEdit);
    };

    const fields = () => {
        if (editMode()) {
            return props.availableFilters.filter(
                (item) => item?.id === props.filterToEdit.column
            );
        }

        let usedFilters = props.filters.map((item) => item.column);
        return props.availableFilters.filter(
            (item) => !usedFilters.includes(item?.id)
        );
    };

    const [selectedFilter, setSelectedFilter] = useState(
        _.head(fields()) ?? null
    );

    const getInitOperator = () => {
        if (editMode()) {
            return _.head(
                operators().filter((operator) => {
                    return operator.id === props.filterToEdit.operator;
                })
            );
        }
        return _.head(operators());
    };

    const getRangeFieldValue = (type, fromValue) => {
        const fieldValue = getInputFieldValue(type);
        if (_.isNil(fieldValue)) {
            return null;
        }

        if (fromValue) {
            return fieldValue?.from;
        } else {
            return fieldValue?.to;
        }
    };

    const getYesNoFieldValue = () => {
        const fieldValue = getInputFieldValue(EdsTableFilterType.YesNo);
        if (!_.isNull(fieldValue)) {
            return fieldValue;
        }
        return true;
    };

    const getInputFieldValue = (type) => {
        if (!editMode() || _.isNil(props?.filterToEdit?.value)) {
            return null;
        }

        const fieldValue = props.filterToEdit.value;

        switch (type) {
            case EdsTableFilterType.Text:
            case EdsTableFilterType.Email:
            case EdsTableFilterType.Date:
            case EdsTableFilterType.DateRange:
            case EdsTableFilterType.DateTimeRange:
            case EdsTableFilterType.Range:
            case EdsTableFilterType.YesNo:
            case EdsTableFilterType.Number:
            case EdsTableFilterType.PhoneNumber: {
                return fieldValue;
            }

            case EdsTableFilterType.MultiSelect: {
                return props.filterToEdit?.selectedItems ?? [];
            }

            case EdsTableFilterType.Dropdown: {
                return _.head(props.filterToEdit?.selectedItems) ?? {};
            }

            case EdsTableFilterType.Location:
            case EdsTableFilterType.Group:
            case EdsTableFilterType.Tag: {
                return props.filterToEdit?.selectedItems ?? {};
            }

            case EdsTableFilterType.AlarmCriteria: {
                return props.filterToEdit;
            }

            default: {
                return null;
            }
        }
    };

    const operators = () => {
        return [
            {
                id: EdsTableFilterOperator.Equals,
                name: t('51c3f59625962b899c03595d6cdfb284', 'Equals'),
            },
            ...(selectedFilter?.type !== EdsTableFilterType.Group
                ? [
                      {
                          id: EdsTableFilterOperator.NotEquals,
                          name: t(
                              '62558f7b07c034e992ca179a1b9b7e68',
                              'Not equals'
                          ),
                      },
                  ]
                : []),
            ...(selectedFilter?.type === EdsTableFilterType.Text
                ? [
                      {
                          id: EdsTableFilterOperator.Contains,
                          name: t(
                              '857af22f119fefbfa24769ed2ad6d5e7',
                              'Contains'
                          ),
                      },
                      {
                          id: EdsTableFilterOperator.StartsWith,
                          name: t(
                              '0a7f4995e6820457caa233a0fe82bc64',
                              'Starts with'
                          ),
                      },
                      {
                          id: EdsTableFilterOperator.EndsWith,
                          name: t(
                              'feb972f0513d4da2670631692d44def9',
                              'Ends with'
                          ),
                      },
                  ]
                : []),
            {
                id: EdsTableFilterOperator.Empty,
                name: t('bf943ad8b2ad1f26745e6236f04b74df', 'Is empty'),
            },
        ];
    };

    const [selectedOperator, setSelectedOperator] = useState(
        getInitOperator() ?? null
    );

    const customCriteriaRangeRef = useRef(null);

    const formDefinition = {
        column: {
            value: defaultDropdownMappingCallback(_.head(fields())),
            validation: {
                type: 'dropdown',
                required: true,
            },
        },
        operator: {
            value: defaultDropdownMappingCallback(getInitOperator()),
            validation: {
                type: 'dropdown',
                required: false,
            },
        },
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }
        return form;
    };

    const showOperator = () => {
        if (
            selectedFilter.showOperator ||
            _.isUndefined(selectedFilter.showOperator)
        ) {
            return [
                EdsTableFilterType.Dropdown,
                EdsTableFilterType.Text,
                EdsTableFilterType.Group,
                EdsTableFilterType.Number,
            ].includes(selectedFilter?.type);
        }
        return false;
    };

    const testValueRequired = async (value, testContext) => {
        if (value) {
            if (
                _.isPlainObject(value) &&
                _.has(value, 'id') &&
                _.has(value, 'text') &&
                _.isUndefined(value.id) &&
                _.isEmpty(value.text)
            ) {
                //if { id: undefined, text: "" } eq not filled in
                return false;
            } else {
                return true;
            }
        }

        if (
            testContext?.options?.context?.form?.operator?.id ===
            EdsTableFilterOperator.Empty
        ) {
            return true;
        }
        return false;
    };

    const getInputField = () => {
        if (
            showOperator() &&
            selectedOperator.id === EdsTableFilterOperator.Empty
        ) {
            return null;
        }

        switch (selectedFilter?.type) {
            case EdsTableFilterType.Dropdown: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value: {
                                value: getInputFieldValue(
                                    EdsTableFilterType.Dropdown
                                ),
                                validation: {
                                    type: 'dropdown',
                                    required: false,
                                    tests: [
                                        {
                                            name: 'value-mandatory-for-operator',
                                            message: t(
                                                '3f5f7f01887d47e3f71bf06e5f475e41',
                                                'Field is required'
                                            ),
                                            func: testValueRequired,
                                        },
                                    ],
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsDropdown
                            type={EdsDropdownType.ComboBox}
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                            getDataCallback={selectedFilter.getDataCallback}
                            mappingCallback={
                                selectedFilter.mappingCallback ??
                                defaultDropdownMappingCallback
                            }
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.MultiSelect: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value: {
                                value: getInputFieldValue(
                                    EdsTableFilterType.MultiSelect
                                ),
                                validation: {
                                    type: 'multiselect',
                                    required: true,
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsDropdown
                            type={EdsDropdownType.FilterableMultiSelect}
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                            getDataCallback={selectedFilter.getDataCallback}
                            compareItems={selectedFilter.compareItems}
                            mappingCallback={
                                selectedFilter.mappingCallback ??
                                defaultDropdownMappingCallback
                            }
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Text: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value: {
                                value: getInputFieldValue(
                                    EdsTableFilterType.Text
                                ),
                                validation: {
                                    type: 'string',
                                    required: false,
                                    tests: [
                                        {
                                            name: 'value-mandatory-for-operator',
                                            message: t(
                                                '3f5f7f01887d47e3f71bf06e5f475e41',
                                                'Field is required'
                                            ),
                                            func: testValueRequired,
                                        },
                                    ],
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsTextInput
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Email: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value: {
                                value: getInputFieldValue(
                                    EdsTableFilterType.Email
                                ),
                                validation: {
                                    type: 'email',
                                    required: true,
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsTextInput
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.PhoneNumber: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value: {
                                value: getInputFieldValue(
                                    EdsTableFilterType.PhoneNumber
                                ),
                                validation: {
                                    type: 'text',
                                    required: true,
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsPhoneNumber
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Number: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value: {
                                value: getInputFieldValue(
                                    EdsTableFilterType.Number
                                ),
                                validation: {
                                    type: 'number',
                                    required: false,
                                    tests: [
                                        {
                                            name: 'value-mandatory-for-operator',
                                            message: t(
                                                '3f5f7f01887d47e3f71bf06e5f475e41',
                                                'Field is required'
                                            ),
                                            func: testValueRequired,
                                        },
                                    ],
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsNumberInput
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.YesNo: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value: {
                                value: getYesNoFieldValue(),
                                validation: {
                                    type: 'boolean',
                                    required: true,
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsToggle
                            labelText={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                            name={'value'}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Range: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value_from: {
                                value: getRangeFieldValue(
                                    EdsTableFilterType.Range,
                                    true
                                ),
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                            },
                            value_to: {
                                value: getRangeFieldValue(
                                    EdsTableFilterType.Range,
                                    false
                                ),
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsTextInput
                            name={'value_from'}
                            label={t(
                                'd98a07f84921b24ee30f86fd8cd85c3c',
                                'From'
                            )}
                        />
                        <EdsTextInput
                            name={'value_to'}
                            label={t('01b6e20344b68835c5ed1ddedf20d531', 'To')}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Date: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value: {
                                value: getInputFieldValue(
                                    EdsTableFilterType.Date
                                ),
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsDatePicker
                            name={'value'}
                            label={t(
                                '2063c1608d6e0baf80249c42e2be5804',
                                'Value'
                            )}
                            minDate={getDate({ minusYears: 120 })}
                            maxDate={getDate()}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.DateRange: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value_from: {
                                value: getRangeFieldValue(
                                    EdsTableFilterType.DateRange,
                                    true
                                ),
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                            },
                            value_to: {
                                value: getRangeFieldValue(
                                    EdsTableFilterType.DateRange,
                                    false
                                ),
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsDatePicker
                            fromName={'value_from'}
                            fromLabel={t(
                                'd98a07f84921b24ee30f86fd8cd85c3c',
                                'From'
                            )}
                            toName={'value_to'}
                            toLabel={t(
                                '01b6e20344b68835c5ed1ddedf20d531',
                                'To'
                            )}
                            type={EdsDatePickerType.DateRange}
                            minDate={getDate({ minusYears: 120 })}
                            maxDate={getDate()}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.DateTimeRange: {
                return (
                    <EdsFormGroup
                        formDefinition={{
                            resetOnDefinitionChange: true,
                            value_from: {
                                value: getRangeFieldValue(
                                    EdsTableFilterType.DateTimeRange,
                                    true
                                ),
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                            },
                            value_to: {
                                value: getRangeFieldValue(
                                    EdsTableFilterType.DateTimeRange,
                                    false
                                ),
                                validation: {
                                    type: 'string',
                                    required: true,
                                },
                            },
                        }}
                        prefix="filter_value"
                        key={selectedFilter.key}
                    >
                        <EdsDatePicker
                            fromName={'value_from'}
                            fromLabel={t(
                                'd98a07f84921b24ee30f86fd8cd85c3c',
                                'From'
                            )}
                            toName={'value_to'}
                            toLabel={t(
                                '01b6e20344b68835c5ed1ddedf20d531',
                                'To'
                            )}
                            type={EdsDatePickerType.DateTimeRange}
                            minDate={getDate({ minusYears: 120 })}
                            maxDate={getDate()}
                        />
                    </EdsFormGroup>
                );
            }
            case EdsTableFilterType.Location: {
                return (
                    <EdsFormGroupLocation
                        prefix="filter_value"
                        key={selectedFilter.key}
                        label={''}
                        selectedValues={getInputFieldValue(
                            EdsTableFilterType.Location
                        )}
                    />
                );
            }
            case EdsTableFilterType.Group: {
                return (
                    <EdsFormGroupGroup
                        groupIdMandatory={true}
                        prefix="filter_value"
                        key={selectedFilter.key}
                        label={''}
                        selectedValues={getInputFieldValue(
                            EdsTableFilterType.Group
                        )}
                    />
                );
            }
            case EdsTableFilterType.Tag: {
                return (
                    <EdsFormGroupTag
                        prefix="filter_value"
                        key={selectedFilter.key}
                        label={''}
                        getDataCallback={selectedFilter.getDataCallback}
                        selectedValues={getInputFieldValue(
                            EdsTableFilterType.Tag
                        )}
                    />
                );
            }
            case EdsTableFilterType.AlarmCriteria: {
                return (
                    <EdsFormGroupAlarmCriteria
                        prefix="filter_value"
                        key={selectedFilter.key}
                        selectedFilter={selectedFilter}
                        ref={customCriteriaRangeRef}
                        selectedValues={getInputFieldValue(
                            EdsTableFilterType.AlarmCriteria
                        )}
                    />
                );
            }
            default:
                return null;
        }
    };
    logger.log('selectedFilter', selectedFilter);

    if (fields()?.length === 0) {
        return (
            <div>
                {t('01e1db6de2b78dbba1e79c073c023469', 'No filters available.')}
            </div>
        );
    }

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit ?? true}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsDropdown
                            name="column"
                            label={t(
                                '1afd32818d1c9525f82aff4c09efd254',
                                'Column'
                            )}
                            readOnly={editMode()}
                            mappingCallback={defaultDropdownMappingCallback}
                            getDataCallback={() => fields()}
                            onChangeCallback={(event) => {
                                let newSelectedFilter =
                                    props.availableFilters.find(
                                        (filter) =>
                                            filter.id === event.selectedItem.id
                                    );
                                newSelectedFilter.key = uuid();

                                const selectedFilterType = selectedFilter?.type;
                                setSelectedFilter(newSelectedFilter);

                                // Remove alarm criteria form definitions when changing column from alarm criteria
                                if (
                                    selectedFilterType ===
                                        EdsTableFilterType.AlarmCriteria &&
                                    newSelectedFilter?.type !==
                                        EdsTableFilterType.AlarmCriteria
                                ) {
                                    customCriteriaRangeRef.current?.removeRepeatAll();
                                }
                            }}
                        />
                    </EdsFormColumn>
                </Row>
                {showOperator() && (
                    <Row>
                        <EdsFormColumn fullWidth>
                            <EdsDropdown
                                name="operator"
                                label={t(
                                    '4b583376b2767b923c3e1da60d10de59',
                                    'Operator'
                                )}
                                mappingCallback={defaultDropdownMappingCallback}
                                getDataCallback={() => operators()}
                                onChangeCallback={(event) => {
                                    setSelectedOperator(event.selectedItem);
                                }}
                            />
                        </EdsFormColumn>
                    </Row>
                )}
                <Row>
                    <EdsFormColumn fullWidth>{getInputField()}</EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});

EdsTableFilterForm.displayName = 'EdsTableFilterForm';
