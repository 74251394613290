import { useRouteLoaderData } from 'react-router';
import { EdsContainerGrid, EdsTabs } from '../../../components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function DevicesTab() {
    const { t } = useTranslation();
    const devices = useRouteLoaderData('alarm-detail/devices');

    if (_.isNil(devices) || _.isEmpty(devices)) {
        return (
            <EdsContainerGrid
                noDataLabel={t(
                    '3b2467c9104895de4f2a76011afc97d2',
                    'This client has no devices'
                )}
            />
        );
    }

    const tabs = devices.map((item, index) => {
        return {
            label: item.deviceCode,
            transparent: true,
            index: index === 0,
            slug: `${item.deviceUID}`,
        };
    });

    return (
        <>
            <EdsTabs contained={true} tabs={tabs} router={true} />
        </>
    );
}

export default DevicesTab;
