import {
    Notification,
    UserProfile,
    Devices,
    Group,
    HealthCross,
    Home,
    Schematics,
    LicenseMaintenance,
    List,
} from '@carbon/icons-react';
import './eds-side-nav.scss';
import {
    SideNav,
    SideNavItems,
    SideNavLink,
    SideNavMenu,
    SideNavMenuItem,
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import {
    AlarmReadPermissionSet,
    CareProviderReadPermissionSet,
    ClientReadPermissionSet,
    CrmMaintenanceAccountPermissionSet,
    CrmMaintenanceAlarmPermissionSet,
    CrmMaintenanceClientPermissionSet,
    CrmMaintenanceDevicePermissionSet,
    CrmMaintenanceMedicalPermissionSet,
    CrmMaintenancePermissionSet,
    CrmMaintenanceResidencePermissionSet,
    CrmMaintenanceTenantPermissionSet,
    DeviceReadPermissionSet,
    ResidenceReadPermissionSet,
    Restricted,
    SchemeReadPermissionSet,
} from '../../../features';
import { useMatches } from 'react-router';
import _ from 'lodash';
import { UmoNavLink } from '../../umo';

export function EdsSideNav({ version, onOverlayClick, ...props }) {
    const { t } = useTranslation();

    //on page load check if the menu needs te be expanded.
    const matches = useMatches();
    let expandedMaintenanceMenu = false;
    if (_.isArray(matches) && !_.isEmpty(matches)) {
        expandedMaintenanceMenu = matches
            .pop()
            .pathname?.includes('/crm/maintenance/');
    }

    return (
        <SideNav
            aria-label={t(
                '8e7f8fd4607cca88f5ca54e51ea51327',
                'Side navigation'
            )}
            isFixedNav={false}
            isPersistent={true}
            defaultExpanded={false}
            className="eds-side-nav"
            onOverlayClick={onOverlayClick}
            {...props}
        >
            <SideNavItems>
                <Restricted to={ClientReadPermissionSet}>
                    <SideNavLink
                        renderIcon={UserProfile}
                        as={UmoNavLink}
                        to="/crm/client"
                    >
                        {t('62608e08adc29a8d6dbc9754e659f125', 'Client')}
                    </SideNavLink>
                </Restricted>

                <Restricted to={DeviceReadPermissionSet}>
                    <SideNavLink
                        renderIcon={Devices}
                        as={UmoNavLink}
                        to="/crm/device"
                    >
                        {t('913f9c49dcb544e2087cee284f4a00b7', 'Device')}
                    </SideNavLink>
                    <SideNavLink
                        renderIcon={List}
                        as={UmoNavLink}
                        to="/crm/peripheral"
                    >
                        {t('dfef988e5e51db5a86acc045d907c1c7', 'Peripheral')}
                    </SideNavLink>
                </Restricted>

                <Restricted to={ResidenceReadPermissionSet}>
                    <SideNavLink
                        renderIcon={Home}
                        as={UmoNavLink}
                        to="/crm/residence"
                    >
                        {t('cad4811efcdb1000aae7a6841d995713', 'Residence')}
                    </SideNavLink>
                </Restricted>

                <Restricted to={CareProviderReadPermissionSet}>
                    <SideNavLink
                        renderIcon={HealthCross}
                        as={UmoNavLink}
                        to="/crm/professional-caregiver"
                    >
                        {t(
                            '599bfff4492f40356f6e8c9eb47f6996',
                            'Professional caregiver'
                        )}
                    </SideNavLink>

                    <SideNavLink
                        renderIcon={Group}
                        as={UmoNavLink}
                        to="/crm/care-team"
                    >
                        {t('bedb36b3c58925c6093b8f5f98f86468', 'Care teams')}
                    </SideNavLink>
                </Restricted>

                <Restricted to={SchemeReadPermissionSet}>
                    <SideNavLink
                        renderIcon={Schematics}
                        as={UmoNavLink}
                        to="/crm/scheme"
                    >
                        {t('d586b8449187103d4c75041033e60fb5', 'Schemes')}
                    </SideNavLink>
                </Restricted>

                <Restricted to={AlarmReadPermissionSet}>
                    <SideNavLink
                        renderIcon={Notification}
                        as={UmoNavLink}
                        to="/crm/alarm"
                    >
                        {t('0500cd47ceefb6776f9d279889775775', 'Alarms')}
                    </SideNavLink>
                </Restricted>

                <Restricted
                    to={CrmMaintenancePermissionSet}
                    requiresAll={false}
                >
                    <SideNavMenu
                        defaultExpanded={expandedMaintenanceMenu}
                        isActive={expandedMaintenanceMenu}
                        title={t(
                            '57cb773ae7a82c8c8aae12fa8f8d7abd',
                            'Maintenance'
                        )}
                        renderIcon={LicenseMaintenance}
                    >
                        <Restricted to={CrmMaintenanceAccountPermissionSet}>
                            <SideNavMenuItem
                                as={UmoNavLink}
                                to="/crm/maintenance/account"
                            >
                                {t(
                                    'e268443e43d93dab7ebef303bbe9642f',
                                    'Account'
                                )}
                            </SideNavMenuItem>
                        </Restricted>
                        <Restricted to={CrmMaintenanceAlarmPermissionSet}>
                            <SideNavMenuItem
                                as={UmoNavLink}
                                to="/crm/maintenance/alarm"
                            >
                                {t('6486b4e98228b83d4b13d54febe5f170', 'Alarm')}
                            </SideNavMenuItem>
                        </Restricted>
                        <Restricted to={CrmMaintenanceClientPermissionSet}>
                            <SideNavMenuItem
                                as={UmoNavLink}
                                to="/crm/maintenance/client"
                            >
                                {t(
                                    '62608e08adc29a8d6dbc9754e659f125',
                                    'Client'
                                )}
                            </SideNavMenuItem>
                        </Restricted>
                        <Restricted to={CrmMaintenanceDevicePermissionSet}>
                            <SideNavMenuItem
                                as={UmoNavLink}
                                to="/crm/maintenance/device"
                            >
                                {t(
                                    '913f9c49dcb544e2087cee284f4a00b7',
                                    'Device'
                                )}
                            </SideNavMenuItem>
                            <SideNavMenuItem
                                as={UmoNavLink}
                                to="/crm/maintenance/peripheral"
                            >
                                {t(
                                    'dfef988e5e51db5a86acc045d907c1c7',
                                    'Peripheral'
                                )}
                            </SideNavMenuItem>
                        </Restricted>
                        <Restricted to={CrmMaintenanceResidencePermissionSet}>
                            <SideNavMenuItem
                                as={UmoNavLink}
                                to="/crm/maintenance/residence"
                            >
                                {t(
                                    'cad4811efcdb1000aae7a6841d995713',
                                    'Residence'
                                )}
                            </SideNavMenuItem>
                        </Restricted>
                        <Restricted to={CrmMaintenanceMedicalPermissionSet}>
                            <SideNavMenuItem
                                as={UmoNavLink}
                                to="/crm/maintenance/medical"
                            >
                                {t(
                                    '7cbdd4e997c3b8e759f8d579bb30f6f1',
                                    'Medical'
                                )}
                            </SideNavMenuItem>
                        </Restricted>
                        <Restricted to={CrmMaintenanceTenantPermissionSet}>
                            <SideNavMenuItem
                                as={UmoNavLink}
                                to="/crm/maintenance/tenant"
                            >
                                {t(
                                    'adfb689897b2b5255adcaee72945c791',
                                    'Tenant'
                                )}
                            </SideNavMenuItem>
                        </Restricted>
                    </SideNavMenu>
                </Restricted>
            </SideNavItems>
            {version && version !== '' && (
                <div className="eds-side-nav-version">v{version}</div>
            )}
        </SideNav>
    );
}
