import { useRouteLoaderData } from 'react-router';
import {
    EdsContainerGrid,
    UmoAlarmPersonalCaregivers,
} from '../../../components';
import { getAlarmPersonalCaregivers } from '../../../features';

function PersonalCaregiversPage() {
    const { alarm } = useRouteLoaderData('alarm-detail/id');

    return (
        <EdsContainerGrid>
            <UmoAlarmPersonalCaregivers
                getDataCallback={() => getAlarmPersonalCaregivers(alarm.id)}
            />
        </EdsContainerGrid>
    );
}

export default PersonalCaregiversPage;
