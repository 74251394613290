import { useCallback, useReducer } from 'react';
import EdsTableFilterContext from './eds-table-filter-context';
import {
    EdsTableFilterReducer,
    EdsTableFilterReducerActions,
} from './eds-table-filter-reducer';
import { v4 as uuid } from 'uuid';
import {
    EdsTableFilterAlarmCriteriaType,
    EdsTableFilterType,
} from './eds-table-filter-types';
import { EdsTableFilterOperator } from './eds-table-filter-operator';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    defaultDropdownMappingCallback,
    getCity,
    getCountry,
    getCountryTranslations,
    getDistrict,
    getGroup,
    getLogger,
    getProject,
    getRegion,
    getSubgroup,
    getSubproject,
    getTag,
    toJSDate,
} from '../../../../features';
import { DateTime } from 'luxon';

const logger = getLogger('EdsTableFilterProvider');

const DATE_URL_FORMAT = 'yyyy-MM-dd';

export const urlEncodeFilters = (filters) => {
    let f = {};
    for (let index in filters) {
        let filter = filters[index];

        let item = {};
        if (
            !_.isEmpty(filter.operator) &&
            filter.operator !== EdsTableFilterOperator.Equals
        ) {
            item.o = filter.operator;
        }
        if (!_.isNil(filter.value?.from) && !_.isNil(filter.value?.to)) {
            item.f = urlEncodeValue(filter.value.from, filter.type);
            item.t = urlEncodeValue(filter.value.to, filter.type);
        } else if (
            !_.isEmpty(filter.value) ||
            _.isInteger(filter.value) ||
            _.isBoolean(filter.value)
        ) {
            item.v = urlEncodeValue(filter.value, filter.type);
        }
        f[filter.column] = item;
    }
    return JSON.stringify(f);
};

const urlEncodeValue = (value, type) => {
    if (
        type === EdsTableFilterType.Date ||
        type === EdsTableFilterType.DateRange
    ) {
        const date = toJSDate(value, true);
        if (date instanceof Date) {
            return DateTime.fromJSDate(date).toFormat(DATE_URL_FORMAT);
        }
    } else if (type === EdsTableFilterType.DateTimeRange) {
        const date = toJSDate(value, true);
        if (date instanceof Date) {
            return date.toJSON();
        }
    }
    return value;
};

export const urlDecodeFilters = (f, availableFilters) => {
    if (_.isEmpty(f)) {
        return [];
    }

    let encodedFilters;
    if (_.isPlainObject(f)) {
        encodedFilters = f;
    } else {
        try {
            encodedFilters = JSON.parse(f);
        } catch (err) {
            logger.warn('[urlDecodeFilters] Unable to decode filters:', err);
            return [];
        }
    }

    let decodedFilters = [];
    for (let key in encodedFilters) {
        let originalFilter = availableFilters.find((item) => item.id === key);
        if (_.isNil(originalFilter)) {
            logger.warn(
                '[urlDecodeFilters] Unable to find filter for key:',
                key
            );
            continue;
        }

        let encoded = encodedFilters[key];
        let filter = {
            column: key,
            type: originalFilter.type,
            key: originalFilter.name,
        };

        if (
            !_.isNil(encoded.o) &&
            Object.values(EdsTableFilterOperator).indexOf(encoded.o) !== -1
        ) {
            filter.operator = encoded.o;
        } else {
            filter.operator = EdsTableFilterOperator.Equals;
        }

        if (!_.isNil(encoded.f) && !_.isNil(encoded.t)) {
            let from = urlDecodeValue(encoded.f, originalFilter.type);
            let to = urlDecodeValue(encoded.t, originalFilter.type);
            filter.value = { from, to };
        } else if (!_.isNil(encoded.v)) {
            filter.value = urlDecodeValue(encoded.v, originalFilter.type);
        }

        decodedFilters.push(filter);
    }

    return decodedFilters;
};

const urlDecodeValue = (value, type) => {
    if (
        (type === EdsTableFilterType.Date ||
            type === EdsTableFilterType.DateRange) &&
        _.isString(value)
    ) {
        return DateTime.fromFormat(value, DATE_URL_FORMAT).toJSON();
    } else if (type === EdsTableFilterType.DateTimeRange) {
        return DateTime.fromISO(value).toJSON();
    }
    return value;
};

export const EdsTableFilterProvider = ({ children, ...props }) => {
    const { t } = useTranslation();
    const availableFilters = props.availableFilters || [];

    const [state, dispatch] = useReducer(
        EdsTableFilterReducer(props.searchFilterStore),
        {
            filters: [],
            searchTerm: null,
            sorting: null,
            savedFilter: null,
        }
    );

    const removeFilter = useCallback((id) => {
        logger.log('[RemoveFilter]', id);

        dispatch({ type: EdsTableFilterReducerActions.RemoveFilter, id });
    }, []);

    const setSearchTerm = useCallback((searchTerm) => {
        dispatch({
            type: EdsTableFilterReducerActions.SetSearchTerm,
            payload: searchTerm,
        });
    }, []);

    const setSorting = useCallback((sorting) => {
        dispatch({
            type: EdsTableFilterReducerActions.SetSorting,
            payload: sorting,
        });
    }, []);

    const editFilter = useCallback(
        (filter, onClick) => {
            logger.log('[EditFilter]', filter);

            const editFilterAsync = async () => {
                await formatFilter(filter);
                dispatch({
                    type: EdsTableFilterReducerActions.EditFilter,
                    payload: {
                        onClose: () => removeFilter(filter.id),
                        onClick: () => onClick({ ...filter, id: filter.id }),
                        ...filter,
                    },
                });
            };
            editFilterAsync();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [removeFilter]
    );

    const addFilter = useCallback(
        (filter, onClick) => {
            logger.log('[AddFilter]', filter);

            const addFilterAsync = async () => {
                const id = uuid();
                await formatFilter(filter);
                dispatch({
                    type: EdsTableFilterReducerActions.AddFilter,
                    payload: {
                        id,
                        onClose: () => removeFilter(id),
                        onClick: () => onClick({ ...filter, id: id }),
                        ...filter,
                    },
                });
            };
            addFilterAsync();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [removeFilter]
    );

    const addFilters = useCallback(
        (filters, onClick) => {
            logger.log('[AddFilters]', filters);

            const addFiltersAsync = async () => {
                let payload = [];
                for (let index in filters) {
                    let filter = filters[index];
                    await formatFilter(filter);
                    const id = uuid();
                    payload.push({
                        id,
                        onClose: () => removeFilter(id),
                        onClick: () => onClick({ ...filter, id: id }),
                        ...filter,
                    });
                }
                dispatch({
                    type: EdsTableFilterReducerActions.AddFilters,
                    payload,
                });
            };
            addFiltersAsync();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [removeFilter]
    );

    const setSavedFilter = useCallback(
        (onClick) => {
            const addSavedFilterAsync = async () => {
                const savedFilterSet = await props.searchFilterStore.get();
                let savedFilter = null;
                if (!_.isNil(savedFilterSet)) {
                    const filterSet = {
                        searchTerm: savedFilterSet.search_term,
                        sorting: savedFilterSet.sorting,
                        filters: savedFilterSet.filters,
                    };

                    for (let index in savedFilterSet.filters) {
                        let filter = savedFilterSet.filters[index];
                        await formatFilter(filter);
                    }

                    savedFilter = {
                        filterSet,
                        content: formatSavedFilterSetContent(filterSet),
                        onClick: () => {
                            logger.log('Use FilterSet');
                            applySavedFilter(filterSet, onClick);
                        },
                        onClose: () => clearFilter(),
                    };
                }

                dispatch({
                    type: EdsTableFilterReducerActions.SetSavedFilter,
                    payload: savedFilter,
                });
            };
            addSavedFilterAsync();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [removeFilter]
    );

    const applySavedFilter = useCallback(
        (filterSet, onClick) => {
            logger.log('Apply filterset', filterSet.searchTerm);

            let filters = filterSet.filters.map((filter) => {
                let id = uuid();
                return {
                    id,
                    onClose: () => removeFilter(id),
                    onClick: () => onClick({ ...filter, id: id }),
                    ...filter,
                };
            });
            dispatch({
                type: EdsTableFilterReducerActions.ApplySavedFilter,
                payload: {
                    filters,
                    searchTerm: filterSet.searchTerm,
                    sorting: filterSet.sorting,
                },
            });
        },
        [removeFilter]
    );

    const formatOperator = (operator) => {
        switch (operator) {
            default:
            case EdsTableFilterOperator.Equals:
                return t('51c3f59625962b899c03595d6cdfb284', 'Equals');
            case EdsTableFilterOperator.NotEquals:
                return t('62558f7b07c034e992ca179a1b9b7e68', 'Not equals');
            case EdsTableFilterOperator.Contains:
                return t('857af22f119fefbfa24769ed2ad6d5e7', 'Contains');
            case EdsTableFilterOperator.StartsWith:
                return t('0a7f4995e6820457caa233a0fe82bc64', 'Starts with');
            case EdsTableFilterOperator.EndsWith:
                return t('feb972f0513d4da2670631692d44def9', 'Ends with');
            case EdsTableFilterOperator.Empty:
                return t('bf943ad8b2ad1f26745e6236f04b74df', 'Is empty');
        }
    };

    const getMappedServerData = async (originalFilter) => {
        let serverData = await originalFilter.getDataCallback();
        return serverData.map((data) => {
            return _.isFunction(originalFilter.mappingCallback)
                ? originalFilter.mappingCallback(data)
                : defaultDropdownMappingCallback(data);
        });
    };

    const formatFilter = async (filter) => {
        if (!_.isEmpty(filter.key) && !_.isEmpty(filter.content)) {
            // Already formatted
            return;
        }

        let content = filter.value ?? '';

        let originalFilter = availableFilters.find(
            (f) => f.id === filter.column
        );

        if (_.isNil(originalFilter)) {
            logger.warn(
                '[formatFilter] Unable to find original filter:',
                filter,
                availableFilters
            );
            return;
        }

        filter.key = originalFilter.name;

        switch (filter.type) {
            case EdsTableFilterType.MultiSelect: {
                filter.selectedItems = [];
                if (_.isFunction(originalFilter.getDataCallback)) {
                    const mappedData = await getMappedServerData(
                        originalFilter
                    );
                    let texts = [];
                    for (let index in filter.value) {
                        let id = filter.value[index];
                        let data = mappedData.find((data) => data.id === id);
                        if (!_.isNil(data?.text)) {
                            texts.push(data.text);
                            filter.selectedItems.push(data);
                        }
                    }
                    content = texts.join(', ');
                }
                break;
            }
            case EdsTableFilterType.Dropdown: {
                filter.selectedItems = [];
                const mappedData = await getMappedServerData(originalFilter);
                let selectedValue = mappedData.find(
                    (data) => data.id === filter.value
                );

                filter.selectedItems.push(selectedValue);

                let formattedOperator = formatOperator(filter.operator);
                if (filter.operator === EdsTableFilterOperator.Empty) {
                    content = formattedOperator;
                } else {
                    content = `${formattedOperator}: ${selectedValue?.text}`;
                }
                break;
            }
            case EdsTableFilterType.YesNo: {
                content = filter.value
                    ? t('a6105c0a611b41b08f1209506350279e', 'Yes')
                    : t('7fa3b767c460b54a2be4d49030b349c7', 'No');
                break;
            }
            case EdsTableFilterType.DateRange: {
                const dateFrom = toJSDate(filter.value?.from, true);
                const dateTo = toJSDate(filter.value?.to, true);
                if (dateFrom instanceof Date && dateTo instanceof Date) {
                    const format = t(
                        '418c23834fc746227d6ba6bbf181e8b5',
                        'MM/dd/yyyy'
                    );
                    let formattedDateFrom =
                        DateTime.fromJSDate(dateFrom).toFormat(format);

                    let formattedDateTo =
                        DateTime.fromJSDate(dateTo).toFormat(format);

                    content = t(
                        '517465b3e27939a0a35cd0228afa2b48',
                        '{{from}} to {{to}}',
                        {
                            from: formattedDateFrom,
                            to: formattedDateTo,
                        }
                    );
                }
                break;
            }
            case EdsTableFilterType.DateTimeRange: {
                const dateFrom = toJSDate(filter.value?.from, true);
                const dateTo = toJSDate(filter.value?.to, true);
                if (dateFrom instanceof Date && dateTo instanceof Date) {
                    const format = t(
                        '6eb1403d4ccd7c4731ffa90b6c01beef',
                        'MM/dd/yyyy h:mm a'
                    );
                    let formattedDateFrom =
                        DateTime.fromJSDate(dateFrom).toFormat(format);

                    let formattedDateTo =
                        DateTime.fromJSDate(dateTo).toFormat(format);

                    content = t(
                        '517465b3e27939a0a35cd0228afa2b48',
                        '{{from}} to {{to}}',
                        {
                            from: formattedDateFrom,
                            to: formattedDateTo,
                        }
                    );
                }
                break;
            }
            case EdsTableFilterType.Date: {
                let formattedOperator = formatOperator(filter.operator);
                if (filter.operator === EdsTableFilterOperator.Empty) {
                    content = formattedOperator;
                } else {
                    const date = toJSDate(filter.value, true);
                    if (date instanceof Date) {
                        let formattedDate = DateTime.fromJSDate(date).toFormat(
                            t('418c23834fc746227d6ba6bbf181e8b5', 'MM/dd/yyyy')
                        );
                        content = `${formattedOperator}: ${formattedDate}`;
                    }
                }
                break;
            }
            case EdsTableFilterType.Text:
            case EdsTableFilterType.Email:
            case EdsTableFilterType.PhoneNumber:
            case EdsTableFilterType.Number: {
                let formattedOperator = formatOperator(filter.operator);
                if (filter.operator === EdsTableFilterOperator.Empty) {
                    content = formattedOperator;
                } else {
                    content = `${formattedOperator}: ${filter.value}`;
                }
                break;
            }
            case EdsTableFilterType.Range: {
                content = t(
                    '517465b3e27939a0a35cd0228afa2b48',
                    '{{from}} to {{to}}',
                    {
                        from: filter.value?.from,
                        to: filter.value?.to,
                    }
                );
                break;
            }
            case EdsTableFilterType.Location: {
                filter.selectedItems = {};

                if (filter.value?.district) {
                    let district = await getDistrict(filter.value.district);

                    filter.selectedItems.district = {
                        id: filter.value.district,
                        text: district?.name,
                    };
                }

                if (filter.value?.city) {
                    let city = await getCity(filter.value.city);
                    filter.selectedItems.city = {
                        id: filter.value.city,
                        text: city?.name,
                    };
                }

                if (filter.value?.region) {
                    let region = await getRegion(filter.value.region);
                    filter.selectedItems.region = {
                        id: filter.value.region,
                        text: region?.name,
                    };
                }

                if (filter.value?.country) {
                    let country = await getCountry(filter.value.country);
                    const countryTranslations = getCountryTranslations(t);
                    const countryName = country?.countryCode
                        ? countryTranslations[country?.countryCode]
                        : country.name;
                    filter.selectedItems.country = {
                        id: filter.value.country,
                        text: countryName,
                    };
                }

                if (filter.value?.district) {
                    content = `${t(
                        '6b77ef4b602800a89d88e6e3f93a322c',
                        'District'
                    )}: ${filter.selectedItems?.district?.text}`;
                } else if (filter.value?.city) {
                    content = `${t(
                        '4ed5d2eaed1a1fadcc41ad1d58ed603e',
                        'City'
                    )}: ${filter.selectedItems?.city?.text}`;
                } else if (filter.value?.region) {
                    content = `${t(
                        '960db2ed82202a9706b97775a4269378',
                        'Region'
                    )}: ${filter.selectedItems?.region?.text}`;
                } else if (filter.value?.country) {
                    content = `${t(
                        'e909c2d7067ea37437cf97fe11d91bd0',
                        'Country'
                    )}: ${filter.selectedItems?.country?.text}`;
                }

                break;
            }
            case EdsTableFilterType.Group: {
                filter.selectedItems = {};

                let formattedOperator = formatOperator(filter.operator);
                if (filter.operator === EdsTableFilterOperator.Empty) {
                    content = formattedOperator;
                } else {
                    if (filter.value?.subproject) {
                        let subproject = await getSubproject(
                            filter.value.subproject
                        );
                        filter.selectedItems.subproject = {
                            id: filter.value.subproject,
                            text: subproject?.name,
                        };
                    }

                    if (filter.value?.project) {
                        let project = await getProject(filter.value.project);
                        filter.selectedItems.project = {
                            id: filter.value.project,
                            text: project?.name,
                        };
                    }

                    if (filter.value?.subgroup) {
                        let subgroup = await getSubgroup(filter.value.subgroup);
                        filter.selectedItems.subgroup = {
                            id: filter.value.subgroup,
                            text: subgroup?.name,
                        };
                    }

                    if (filter.value?.group) {
                        let group = await getGroup(filter.value.group);
                        filter.selectedItems.group = {
                            id: filter.value.group,
                            text: group?.name,
                        };
                    }

                    if (filter.value?.subproject) {
                        content = `${t(
                            'bb07fd5abbe6511c40c7e036116d57de',
                            'Subproject'
                        )}: ${filter.selectedItems?.subproject?.text}`;
                    } else if (filter.value?.project) {
                        content = `${t(
                            '46f86faa6bbf9ac94a7e459509a20ed0',
                            'Project'
                        )}: ${filter.selectedItems?.project?.text}`;
                    } else if (filter.value?.subgroup) {
                        content = `${t(
                            'ff90800b1b198e93f60b292ace3ffb00',
                            'Subgroup'
                        )}: ${filter.selectedItems?.subgroup?.text}`;
                    } else if (filter.value?.group) {
                        content = `${t(
                            'db0f6f37ebeb6ea09489124345af2a45',
                            'Group'
                        )}: ${filter.selectedItems?.group?.text}`;
                    }
                }

                break;
            }
            case EdsTableFilterType.Tag: {
                let tag = await getTag(filter.value);
                content = tag?.name;
                filter.selectedItems = {};
                filter.selectedItems.tagCategory = {
                    id: tag?.tagCategory?.id,
                    text: tag?.tagCategory?.name,
                };
                filter.selectedItems.tag = {
                    id: tag?.id,
                    text: tag?.name,
                };
                break;
            }
            case EdsTableFilterType.AlarmCriteria: {
                filter.selectedItems = [];
                let types = [];
                if (filter.value.alarm) {
                    types.push(t('6486b4e98228b83d4b13d54febe5f170', 'Alarm'));
                    filter.selectedItems.push({
                        id: EdsTableFilterAlarmCriteriaType.Alarm,
                        text: t('6486b4e98228b83d4b13d54febe5f170', 'Alarm'),
                    });
                }
                if (filter.value.technicalAlarm) {
                    types.push(
                        t('49155a18a3aabdd0182d51cf89aa6885', 'Technical alarm')
                    );

                    filter.selectedItems.push({
                        id: EdsTableFilterAlarmCriteriaType.TechnicalAlarm,
                        text: t(
                            '49155a18a3aabdd0182d51cf89aa6885',
                            'Technical alarm'
                        ),
                    });
                }
                if (!_.isEmpty(filter.value.custom)) {
                    types.push(
                        t(
                            'ad8e9ec398490b7b96cb8a9640288416',
                            'Custom range(s)'
                        ) +
                            ' - ' +
                            filter.value.custom
                                .map((range) => `(${range.from}-${range.to})`)
                                .join(', ')
                    );

                    filter.selectedItems.push({
                        id: EdsTableFilterAlarmCriteriaType.CustomRanges,
                        text: t(
                            'ad8e9ec398490b7b96cb8a9640288416',
                            'Custom range(s)'
                        ),
                    });
                }
                if (!_.isEmpty(filter.value.specific)) {
                    types.push(
                        t(
                            '74fb39e1813c7bd59d050bdec07a67e3',
                            'Specific criteria'
                        ) +
                            ' - ' +
                            filter.value.specific.join(', ')
                    );

                    filter.selectedItems.push({
                        id: EdsTableFilterAlarmCriteriaType.SpecificCriteria,
                        text: t(
                            '74fb39e1813c7bd59d050bdec07a67e3',
                            'Specific criteria'
                        ),
                    });
                }
                content = types.join(', ');
                break;
            }
            default:
                break;
        }
        filter.content = content;
    };

    const formatSearchTerm = (searchTerm) => {
        return {
            key: t('94e12a3d717d4f1374012ede695cd81f', 'Search term'),
            content: searchTerm,
        };
    };

    const formatSorting = (sorting) => {
        let headerName = sorting.field;
        if (_.isArray(props.headers)) {
            for (let index in props.headers) {
                let header = props.headers[index];
                if (header.key === headerName || header.sort === headerName) {
                    headerName = header.header;
                    break;
                }
            }
        }
        return {
            key: t('8b4939bcf8f2d06f72b94cc1bd204569', 'Sorting'),
            content: `${headerName} (${
                sorting.direction === 'ASC'
                    ? t('9c9ab624360885fcf93b7643c93b6748', 'ascending')
                    : t('b19e9805fd7727c52ca04dfa3d24a2e5', 'descending')
            })`,
        };
    };

    const formatSavedFilterSetContent = (filterSet) => {
        let listItems = [];

        if (!_.isEmpty(filterSet.searchTerm)) {
            listItems.push(formatSearchTerm(filterSet.searchTerm));
        }

        if (!_.isEmpty(filterSet.sorting)) {
            listItems.push(formatSorting(filterSet.sorting));
        }

        let filters = filterSet.filters;
        for (let i in filters) {
            let filter = filters[i];
            listItems.push({ key: filter.key, content: filter.content });
        }

        return (
            <ul>
                {listItems.map((val) => (
                    <li key={val.key}>
                        {val.key} - {val.content}
                    </li>
                ))}
            </ul>
        );
    };

    const clearFilter = useCallback(() => {
        logger.log('[clearFilter]');
        dispatch({
            type: EdsTableFilterReducerActions.ClearSavedFilter,
        });
    }, []);

    const providerValue = {
        ...state,
        addFilter,
        addFilters,
        editFilter,
        setSavedFilter,
        removeFilter,
        clearFilter,
        setSearchTerm,
        setSorting,
    };

    return (
        <EdsTableFilterContext.Provider value={providerValue}>
            {children}
        </EdsTableFilterContext.Provider>
    );
};

EdsTableFilterProvider.displayName = 'EdsTableFilterProvider';
