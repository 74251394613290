import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/peripherals';

export const getPeripheralSearch = async (params) => {
    return getRequest(`${basePath}/search`, params);
};

export const savePeripheral = async (data) => {
    return postRequest(`${basePath}`, data);
};

export const getPeripheral = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const putPeripheral = async (data) => {
    return putRequest(`${basePath}/${data.id}`, data);
};

export const deletePeripheral = async (id) => {
    return deleteRequest(`${basePath}/${id}`);
};

export const getPeripheralStateHistory = async (id) => {
    return getRequest(`${basePath}/${id}/history/state`);
};

export const getPeripheralLocationHistory = async (id) => {
    return getRequest(`${basePath}/${id}/history/location`);
};

export const patchPeripheral = async (id, data) => {
    return patchRequest(`${basePath}/${id}`, data);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'peripherals'),
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'peripherals'),
};
