import { Navigate } from 'react-router';
import { usePermissions } from './context';
import _ from 'lodash';

export const Restricted = ({
    to,
    toAnyAccount,
    requiresAll = true,
    redirect = false,
    children,
}) => {
    const { isAllowed, isAllowedAnyAccount } = usePermissions();

    if (
        !_.isEmpty(toAnyAccount) &&
        isAllowedAnyAccount(toAnyAccount, requiresAll)
    ) {
        return <>{children}</>;
    }

    if (isAllowed(to, requiresAll)) {
        return <>{children}</>;
    }

    if (redirect) {
        return <Navigate to="/" />;
    }

    return null;
};
