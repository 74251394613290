import { useRef } from 'react';
import { EdsContainerTable, EdsTableDate, EdsTableFilterType } from '../../..';
import { useTranslation } from 'react-i18next';
import {
    deleteTagLink,
    getLogger,
    getTagLink,
    useModal,
} from '../../../../features';
import UmoTagsForm from './umo-tags-form';
import _ from 'lodash';

let logger = getLogger('UmoTagsTable');

export const UmoTagsTable = ({ getDataCallback, ...props }) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const { showDeleteModal, showFormModal } = useModal();

    const tagsHeaders = [
        {
            key: 'sortIndex',
            header: '#',
            width: '100px',
        },
        {
            key: 'tag',
            header: t('e4d23e841d8e8804190027bce3180fa5', 'Tag'),
        },
        {
            key: 'from',
            header: t('167b2d7828511b4bdbb6d642029bdaff', 'Valid from'),
            width: '200px',
        },
        {
            key: 'to',
            header: t('6e1f5c87ac9e0d969abb679a0603b54d', 'Valid to'),
            width: '200px',
        },
        {
            key: 'text',
            header: t('1cb251ec0d568de6a929b520c4aed8d1', 'Text'),
            width: '50%',
        },
    ];

    const tagsMappingCallback = (responseData) => {
        return {
            id: responseData.id,
            sortIndex: responseData.sortIndex,
            tag: responseData.tag?.name,
            from: <EdsTableDate value={responseData.validFrom} />,
            to: <EdsTableDate value={responseData.validTo} />,
            text: responseData.freeText,
        };
    };

    const showDataModal = (tag = {}) => {
        showFormModal({
            newTitle: t('0d208001d259c3f5b29ee98f6744fff8', 'New tag'),
            editTitle: t('7bad552469f5c5b0e68079a352c062d0', 'Edit tag'),
            formData: tag,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.doSearch(true);
                }
                removeModal();
            },
            children: (
                <UmoTagsForm
                    postDataCallback={props.postDataCallback}
                    ref={formRef}
                    initValues={tag}
                    hideDefaultSubmit={true}
                    tagParams={props.tagParams ?? {}}
                    tagCategoryParams={props.tagCategoryParams ?? {}}
                ></UmoTagsForm>
            ),
        });
    };

    const getDataRow = async (row) => {
        try {
            let tagLink = await getTagLink(row.id);
            showDataModal({
                id: {
                    value: tagLink.id,
                },
                sortIndex: {
                    value: tagLink.sortIndex,
                },
                tagCategoryId: {
                    id: tagLink.tag.tagCategoryId,
                    text: tagLink.tag.tagCategory.name,
                },
                tagId: {
                    id: tagLink.tagId,
                    text: tagLink.tag.name,
                },
                validFrom: {
                    value: tagLink.validFrom,
                },
                validTo: {
                    value: tagLink.validTo,
                },
                freeText: {
                    value: tagLink.freeText,
                },
                freeTextAllowed: {
                    value: tagLink.tag.freeTextAllowed,
                },
            });
        } catch (ex) {
            logger.warn('getTagLink UDS Exception:', ex);
        }
    };

    return (
        <EdsContainerTable
            title={t('d57ac45256849d9b13e2422d91580fb9', 'Tags')}
            ref={tableRef}
            headers={tagsHeaders}
            getDataCallback={async (params = {}) => {
                return getDataCallback({
                    isValid: params.showInvalid ? null : true,
                });
            }}
            mappingCallback={tagsMappingCallback}
            onEditClick={(row) => {
                getDataRow(row);
            }}
            onDeleteClick={(row) => {
                const tag = tableRef.current?.getColumnValueString(
                    row.id,
                    'tag'
                );
                showDeleteModal({
                    name: tag,
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deleteTagLink(row.id);
                    },
                });
            }}
            onHeaderAddClick={() => showDataModal()}
            enableSearch={false}
            searchClientSide={true}
            hasPaging={false}
            availableFilters={[
                {
                    id: 'showInvalid',
                    name: t(
                        'f4b50fb47dcc58beef451ad3caead371',
                        'Also show invalid items'
                    ),
                    type: EdsTableFilterType.YesNo,
                    showOperator: false,
                    customQueryParamsFormatter: (params, values) => {
                        if (!_.isUndefined(values) && _.isBoolean(values)) {
                            return { ...params, showInvalid: values };
                        }
                        return params;
                    },
                },
            ]}
            permissions={props.permissions}
            {...props}
        />
    );
};
