import { lazy } from 'react';
import { Outlet } from 'react-router';
import { pageLoader as DevicePageLoader } from './details/loader';
import {
    DeviceCreatePermissionSet,
    DeviceReadPermissionSet,
    Restricted,
} from '../../../features';

const DevicePage = lazy(() => import('./device-page'));
const DevicePageNew = lazy(() => import('./new'));
const DevicePageNewRange = lazy(() => import('./new-range'));
const DevicePageDetails = lazy(() => import('./details'));

const DeviceTab = lazy(() => import('./details/device-tab'));
const NotesTab = lazy(() => import('./details/notes-tab'));
const HistoryTab = lazy(() => import('./details/history-tab'));
const LogsTab = lazy(() => import('./details/logs-tab'));
const PeripheralsTab = lazy(() => import('./details/peripherals-tab'));
const ErrorsTab = lazy(() => import('./details/errors-tab'));
const SettingsTab = lazy(() => import('./details/settings-tab'));

const DevicePageRoutes = {
    path: 'device',
    element: (
        <Restricted to={DeviceReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('913f9c49dcb544e2087cee284f4a00b7', 'Device'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            element: <DevicePage />,
        },
        {
            path: 'new',
            element: (
                <Restricted to={DeviceCreatePermissionSet} redirect={true}>
                    <DevicePageNew />
                </Restricted>
            ),
            handle: {
                crumb: (t) => t('22af645d1859cb5ca6da0c484f1f37ea', 'New'),
            },
        },
        {
            path: 'new-range',
            element: (
                <Restricted to={DeviceCreatePermissionSet} redirect={true}>
                    <DevicePageNewRange />
                </Restricted>
            ),
            handle: {
                crumb: (t) =>
                    t('90a69223693ef110433d261a13c05592', 'New range'),
            },
        },
        {
            id: 'device/id',
            path: ':deviceId',
            element: <DevicePageDetails />,
            loader: DevicePageLoader,
            handle: {
                crumb: (_t, data) => data.deviceCode,
                documentTitleParent: true,
            },
            children: [
                {
                    index: true,
                    element: <DeviceTab />,
                    handle: {
                        //for tabs to find that this route is the index
                        index: true,
                    },
                },
                {
                    path: 'notes',
                    element: <NotesTab />,
                    handle: {
                        crumb: (t) =>
                            t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes'),
                    },
                },
                {
                    path: 'history',
                    element: <HistoryTab />,
                    handle: {
                        crumb: (t) =>
                            t('3cd15f8f2940aff879df34df4e5c2cd1', 'History'),
                    },
                },
                {
                    path: 'logs',
                    element: <LogsTab />,
                    handle: {
                        crumb: (t) =>
                            t('2165e4fa5bddb65a31f6a0c495c2fa37', 'Logs'),
                    },
                },
                {
                    path: 'peripherals',
                    element: <PeripheralsTab />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '185ee72ba5108b2808b540d0a8864f1f',
                                'Peripherals'
                            ),
                    },
                },
                {
                    path: 'errors',
                    element: <ErrorsTab />,
                    handle: {
                        crumb: (t) =>
                            t('07213a0161f52846ab198be103b5ab43', 'Errors'),
                    },
                },
                {
                    path: 'settings',
                    element: <SettingsTab />,
                    handle: {
                        crumb: (t) =>
                            t('2e5d8aa3dfa8ef34ca5131d20f9dad51', 'Settings'),
                    },
                },
            ],
        },
    ],
};

export default DevicePageRoutes;
