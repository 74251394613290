import { useRouteLoaderData } from 'react-router';
import { EdsContainerGrid, UmoAlarmsTable } from '../../../components';
import { useTranslation } from 'react-i18next';

function HistoryTab() {
    const { alarm } = useRouteLoaderData('alarm-detail/id');
    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <UmoAlarmsTable
                title={t('c71231a6be114b5166980b8e7484402e', 'Alarm history')}
                baseFilter={`deviceId eq ${alarm.deviceId}`}
            />
        </EdsContainerGrid>
    );
}

export default HistoryTab;
