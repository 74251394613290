import { useTranslation } from 'react-i18next';
import { EdsTabs, UmoResponderPanel } from '../../../components';
import { Column, Grid } from '@carbon/react';
import './caregivers-tab.scss';

function CaregiversTab() {
    const { t } = useTranslation();

    const tabs = [
        {
            label: t(
                'b5fc51fe09248958fb552f61b871a8e5',
                'Professional caregivers'
            ),
            transparent: true,
            slug: 'professional',
        },
        {
            label: t('d16da7fd52500df57ed23e3c34b383d0', 'Personal caregivers'),
            transparent: true,
            slug: 'personal',
        },
    ];

    return (
        <div className="eds-caregivers-tab">
            <Grid>
                <Column sm={2} md={5} lg={11}>
                    <EdsTabs contained={true} tabs={tabs} router={true} />
                </Column>
                <Column sm={2} md={3} lg={5}>
                    <UmoResponderPanel />
                </Column>
            </Grid>
        </div>
    );
}

export default CaregiversTab;
