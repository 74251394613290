import { EdsActionButtonTypes, EdsContainerTable } from '../../../';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useNavigate } from '../../../../features';

export const UmoWeblinksTable = ({
    getDataCallback,
    hideWhenNoData = true,
    ...props
}) => {
    const { t } = useTranslation();
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const [hasData, setHasData] = useState();

    const headers = [
        {
            key: 'name',
            header: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
            width: '200px',
        },
        {
            key: 'source',
            header: t('36cd38f49b9afa08222c0dc9ebfe35eb', 'Source'),
        },
    ];

    const mappingCallback = (responseData) => {
        return {
            id: responseData.id,
            name: responseData?.name,
            source: responseData?.source,
            ...((_.isNil(responseData?.source) ||
                _.isEmpty(responseData?.source)) && {
                hideActionButtons: [EdsActionButtonTypes.OpenLink],
            }),
        };
    };

    const getData = async () => {
        let data = await getDataCallback();
        setHasData(!_.isEmpty(data));
        return data;
    };

    if (
        hideWhenNoData &&
        !_.isUndefined(hasData) &&
        _.isBoolean(hasData) &&
        !hasData
    ) {
        return null;
    }

    return (
        <EdsContainerTable
            title={t('807765384d9d5527da8848df14a4f02f', 'Links')}
            ref={tableRef}
            headers={headers}
            getDataCallback={getData}
            enableSearchParams={false}
            searchClientSide={true}
            enableSearch={false}
            fetchServerSideDataOnInit={true}
            mappingCallback={mappingCallback}
            permissions={props.permissions}
            onOpenLinkClick={async (row) => {
                const data = tableRef.current?.getRowData(row?.id);
                if (data?.source) {
                    navigate(data?.source);
                }
            }}
            {...props}
        />
    );
};
