import {
    EdsContainerGrid,
    EdsInlineNotificationsWrapper,
    UmoAlarmsTableForAlarmOverview,
    UmoWeblinksTable,
} from '../../../components';
import { useRouteLoaderData } from 'react-router';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useNavigate } from '../../../features';

function OverviewTab() {
    const { t } = useTranslation();
    const { highPriorityNotes, devicePositions, alarm } =
        useRouteLoaderData('alarm-detail/id');
    const { group } = useRouteLoaderData('alarm-detail/clients/id');
    const navigate = useNavigate();

    const formatNotifications = () => {
        let notifications = [];

        if (!_.isNil(group) && !_.isEmpty(group.welcomeMessage)) {
            notifications.push({
                id: `group-${group.id}`,
                kind: 'info',
                title: t('504541c02761264f0b5e89dda344acc0', 'Welcome message'),
                hideCloseButton: true,
                content: group.welcomeMessage,
            });
        }

        if (!_.isUndefined(highPriorityNotes)) {
            highPriorityNotes.map((note) => {
                notifications.push({
                    id: `note-${note.id}`,
                    kind: 'warning',
                    title: note.subject,
                    hideCloseButton: true,
                    content: note.content,
                });
            });
        }

        if (!_.isUndefined(devicePositions) && !_.isEmpty(devicePositions)) {
            notifications.push({
                id: 'alarm-device-position',
                kind: 'warning',
                title: t(
                    'fe13dd2408c943e39944e282c0923470',
                    'Alarm device position'
                ),
                hideCloseButton: true,
                content: t(
                    'ee27db43ba186b031b4151c111b4562d',
                    'There was an alarm device position received, open the map tab for details'
                ),
                actionButtonLabel: t(
                    '51f77b37230f48bbf7cfee3fba34fbb1',
                    'Open Map'
                ),
                onActionButtonClick: () => {
                    navigate(`./map`);
                },
            });
        }

        return notifications;
    };

    const getLinks = async () => {
        let links = [];

        if (!_.isNil(alarm?.alarmUrl) && !_.isEmpty(alarm?.alarmUrl)) {
            links.push({
                id: uuid(),
                name: alarm?.alarmUrlName ?? '-',
                source: alarm?.alarmUrl,
            });
        }

        return links;
    };

    return (
        <EdsContainerGrid>
            <EdsInlineNotificationsWrapper
                notifications={formatNotifications()}
            />

            <UmoAlarmsTableForAlarmOverview
                pageSize={5}
                enablePagination={false}
                title={t('c71231a6be114b5166980b8e7484402e', 'Alarm history')}
                baseFilter={`deviceId eq ${alarm.deviceId}`}
                seeMoreButtonLabel={t(
                    'd1c97d695d693b36adc603f41ec93a6d',
                    'See more alarms'
                )}
                seeMoreButtonLink={'./history'}
            />

            <UmoWeblinksTable getDataCallback={getLinks} />
        </EdsContainerGrid>
    );
}

export default OverviewTab;
