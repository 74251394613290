import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    patchRequest,
    multipartPostRequest,
} from '../api';

export const getAlarmSettings = async () => {
    return getRequest('/maintenance/alarm/settings');
};

export const putAlarmSettings = async (alarmSettings) => {
    return putRequest('/maintenance/alarm/settings', alarmSettings);
};

export const getAlarmReasonCategories = async (params) => {
    return getRequest('/maintenance/alarm/reason-categories', params);
};

export const getAlarmReasonCategory = async (id) => {
    return getRequest('/maintenance/alarm/reason-categories/' + id);
};

export const postAlarmReasonCategory = async (alarmReasonCategory) => {
    return postRequest(
        '/maintenance/alarm/reason-categories',
        alarmReasonCategory
    );
};

export const putAlarmReasonCategory = async (alarmReasonCategory) => {
    return putRequest(
        '/maintenance/alarm/reason-categories/' + alarmReasonCategory.id,
        alarmReasonCategory
    );
};

export const deleteAlarmReasonCategory = async (id) => {
    return deleteRequest('/maintenance/alarm/reason-categories/' + id);
};

export const getAlarmReasonsByCategory = async (params) => {
    return getRequest('/maintenance/alarm/reasons/', params);
};

export const getMaintenanceAlarmReason = async (id) => {
    return getRequest('/maintenance/alarm/reasons/' + id);
};

export const postMaintenanceAlarmReason = async (alarmReason) => {
    return postRequest('/maintenance/alarm/reasons', alarmReason);
};

export const putMaintenanceAlarmReason = async (alarmReason) => {
    return putRequest(
        '/maintenance/alarm/reasons/' + alarmReason.id,
        alarmReason
    );
};

export const deleteMaintenanceAlarmReason = async (id) => {
    return deleteRequest('/maintenance/alarm/reasons/' + id);
};

export const getMaintenanceAlarmDetails = async () => {
    return getRequest('/maintenance/alarm/details');
};

export const getMaintenanceAlarmDetail = async (id) => {
    return getRequest('/maintenance/alarm/details/' + id);
};

export const postMaintenanceAlarmDetail = async (alarmDetail) => {
    return postRequest('/maintenance/alarm/details', alarmDetail);
};

export const putMaintenanceAlarmDetail = async (alarmDetail) => {
    return putRequest(
        '/maintenance/alarm/details/' + alarmDetail.id,
        alarmDetail
    );
};

export const deleteMaintenanceAlarmDetail = async (id) => {
    return deleteRequest('/maintenance/alarm/details/' + id);
};

export const getRenderedHelpCategories = async (params) => {
    return getRequest('/maintenance/alarm/rendered-help-categories', params);
};

export const getRenderedHelpCategory = async (id) => {
    return getRequest('/maintenance/alarm/rendered-help-categories/' + id);
};

export const postRenderedHelpCategory = async (renderedHelpCategory) => {
    return postRequest(
        '/maintenance/alarm/rendered-help-categories',
        renderedHelpCategory
    );
};

export const putRenderedHelpCategory = async (renderedHelpCategory) => {
    return putRequest(
        '/maintenance/alarm/rendered-help-categories/' +
            renderedHelpCategory.id,
        renderedHelpCategory
    );
};

export const deleteRenderedHelpCategory = async (id) => {
    return deleteRequest('/maintenance/alarm/rendered-help-categories/' + id);
};

export const getRenderedHelp = async (id) => {
    return getRequest('/maintenance/alarm/rendered-help/' + id);
};

export const postRenderedHelp = async (renderedHelp) => {
    return postRequest('/maintenance/alarm/rendered-help', renderedHelp);
};

export const putRenderedHelp = async (renderedHelp) => {
    return putRequest(
        '/maintenance/alarm/rendered-help/' + renderedHelp.id,
        renderedHelp
    );
};

export const deleteRenderedHelp = async (id) => {
    return deleteRequest('/maintenance/alarm/rendered-help/' + id);
};

export const getRenderedHelpByCategory = async (params) => {
    return getRequest('/maintenance/alarm/rendered-help/', params);
};

export const getAlarmTypes = async (params) => {
    return getRequest('/maintenance/alarm/types', params);
};

export const getAlarmType = async (id) => {
    return getRequest('/maintenance/alarm/types/' + id);
};

export const postAlarmType = async (alarmType) => {
    return postRequest('/maintenance/alarm/types', alarmType);
};

export const putAlarmType = async (alarmType) => {
    return putRequest('/maintenance/alarm/types/' + alarmType.id, alarmType);
};

export const deleteAlarmType = async (id) => {
    return deleteRequest('/maintenance/alarm/types/' + id);
};

export const getAlarmProcedures = async (params) => {
    return getRequest('/maintenance/alarm/procedures', params);
};

export const getAlarmProceduresByCriteria = async (id, params) => {
    return getRequest('/maintenance/alarm/procedures/criteria/' + id, params);
};

export const getAlarmProcedure = async (id) => {
    return getRequest('/maintenance/alarm/procedures/' + id);
};

export const postAlarmProcedure = async (alarmProcedure) => {
    return postRequest('/maintenance/alarm/procedures', alarmProcedure);
};

export const putAlarmProcedure = async (alarmProcedure) => {
    return putRequest(
        '/maintenance/alarm/procedures/' + alarmProcedure.id,
        alarmProcedure
    );
};

export const deleteAlarmProcedure = async (id) => {
    return deleteRequest('/maintenance/alarm/procedures/' + id);
};

export const getAlarmProcedureDocuments = async (id) => {
    return getRequest(`/maintenance/alarm/procedures/${id}/documents`);
};

export const postAlarmProcedureDocument = async (
    id,
    file,
    cbProgress,
    abortController
) => {
    let form = new FormData();
    form.append('file', file);
    let customHeaders = {};
    return multipartPostRequest(
        `/maintenance/alarm/procedures/${id}/document`,
        form,
        customHeaders,
        cbProgress,
        abortController
    );
};

export const getAlarmProcedureDocumentUrl = (id, documentId, accountId) => {
    return `/api/maintenance/alarm/procedures/${id}/document/${documentId}?account_id=${accountId}`;
};

export const deleteAlarmProcedureDocument = async (id, documentId) => {
    return deleteRequest(
        `/maintenance/alarm/procedures/${id}/document/${documentId}`
    );
};

export const getAlarmCriteria = async () => {
    return getRequest('/maintenance/alarm/criteria');
};

export const getAlarmCriterion = async (id) => {
    return getRequest('/maintenance/alarm/criteria/' + id);
};

export const getAlarmCriterionByNumber = async (number) => {
    return getRequest('/maintenance/alarm/criteria/numbers/' + number);
};

export const putAlarmCriterion = async (alarmCriterion) => {
    return putRequest(
        '/maintenance/alarm/criteria/' + alarmCriterion.id,
        alarmCriterion
    );
};

export const patchAlarmCriterion = async (
    alarmCriterionNumber,
    alarmCriterion
) => {
    return patchRequest(
        '/maintenance/alarm/criteria/' + alarmCriterionNumber,
        alarmCriterion
    );
};

export const patchAlarmCustomerCriterion = async (
    alarmCriterionNumber,
    alarmCriterion
) => {
    return patchRequest(
        '/maintenance/alarm/criteria/customer/' + alarmCriterionNumber,
        alarmCriterion
    );
};

export const getAlarmSkills = async (params) => {
    return getRequest(`/maintenance/alarm/skills`, params);
};

export const getAlarmSkill = async (id) => {
    return getRequest('/maintenance/alarm/skills/' + id);
};

export const postAlarmSkill = async (data) => {
    return postRequest('/maintenance/alarm/skills', data);
};

export const putAlarmSkill = async (data) => {
    return putRequest('/maintenance/alarm/skills/' + data.id, data);
};

export const deleteAlarmSkill = async (id) => {
    return deleteRequest('/maintenance/alarm/skills/' + id);
};
