import _ from 'lodash';
import './eds-colored-label.scss';
import { CircleFill } from '@carbon/icons-react';
import { stringToHslColor } from '../../../features';
import { useEffect, useState } from 'react';

export function EdsColoredLabel({
    label,
    colorLabel = false,
    colorIcon = true,
    iconSize = 16,
    saturation = 70,
    lightness = 50,
}) {
    const [hslColor, setHslColor] = useState();

    useEffect(() => {
        setHslColor(stringToHslColor(label ?? '', saturation, lightness));
    }, [label, colorLabel, colorIcon, saturation, lightness]);

    const getLabelStyle = () => {
        if (!colorLabel) {
            return;
        }

        return {
            color: hslColor,
        };
    };

    return (
        <div className="eds-colored-label">
            {colorIcon && !_.isEmpty(label) && (
                <CircleFill size={iconSize} style={{ color: hslColor }} />
            )}
            <span style={getLabelStyle()}>{label}</span>
        </div>
    );
}
