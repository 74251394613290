import './umo-document-form.scss';
import { CheckboxGroup, Row } from '@carbon/react';
import { EdsCheckbox, EdsForm, EdsFormColumn, EdsFormGroup } from '../../..';
import { forwardRef } from 'react';
import { setEmptyFieldToNull, useNotifications } from '../../../../features';
import { useTranslation } from 'react-i18next';

const UmoDocumentSettingsForm = forwardRef(
    ({ putCallbackUrl, ...props }, ref) => {
        const { showSuccess, showError } = useNotifications();
        const { t } = useTranslation();
        const formDefinition = {
            showDocumentInAlarm: {
                validation: {
                    required: false,
                },
            },
        };
        const saveData = async (form) => {
            const { clientId, documentId, ...postData } =
                setEmptyFieldToNull(form);
            return await putCallbackUrl(clientId, documentId, postData);
        };

        const onSubmit = async (_event, form, isValid) => {
            if (!isValid) {
                throw false;
            }

            try {
                const response = await saveData(form);
                showSuccess({
                    title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                    content: t(
                        '2f8f34c1a33c2364b442ad5fee1c7e2b',
                        'Document settings are saved'
                    ),
                });

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        return (
            <div className="umo-document-form">
                <EdsForm
                    hideDefaultSubmit={props.hideDefaultSubmit}
                    initValues={props.initValues}
                    formDefinition={formDefinition}
                    onSubmit={onSubmit}
                    ref={ref}
                >
                    <EdsFormGroup>
                        <Row>
                            <EdsFormColumn>
                                <CheckboxGroup legendText="">
                                    <EdsCheckbox
                                        name="showDocumentInAlarm"
                                        labelText={t(
                                            'b74cf8f97d6a1a4035d00e04ef7f06f2',
                                            'Visible in alarm'
                                        )}
                                    />
                                </CheckboxGroup>
                            </EdsFormColumn>
                        </Row>
                    </EdsFormGroup>
                </EdsForm>
            </div>
        );
    }
);

UmoDocumentSettingsForm.displayName = 'UmoDocumentSettingsForm';

export default UmoDocumentSettingsForm;
