import { useEffect, useRef, useState } from 'react';
import {
    debounce,
    EdsCaregiverTypes,
    getCareRegistrationsForPersonalCaregiver,
    getCareRegistrationsForProfessionalCaregiver,
    postCareRegistrationForPersonalCaregiver,
    postCareRegistrationForProfessionalCaregiver,
    putCareRegistration,
    useAlarms,
    useAuth,
    useNotifications,
} from '../../../../features';
import {
    EdsButton,
    EdsContainer,
    EdsDataGrid,
    EdsMaps,
    EdsTableDatetime,
} from '../../../eds';
import './umo-responder-panel.scss';
import { useRouteLoaderData } from 'react-router';
import { getMapMarkers } from '../../../../features/utils/maps';
import { useTranslation } from 'react-i18next';
import { Add, Road } from '@carbon/icons-react';
import ActionRemarkInlineForm from './action-remark-inline-form';
import { DateTime } from 'luxon';
import _ from 'lodash';
import {
    EdsCareRegistrationState,
    getEdsCareRegistrationStateByName,
} from './eds-careprovider-types';

export function UmoResponderPanel() {
    const { t } = useTranslation();
    const { alarm } = useRouteLoaderData('alarm-detail/id');
    const { attributes } = useAuth();
    const { selectedCaregiver, updateSelectedCaregiver } = useAlarms();
    const { showSuccess, showError } = useNotifications();
    const [disabled, setDisabled] = useState(false);
    const [careRegistration, setCareRegistration] = useState(null);
    const [showRemarkForm, setShowRemarkForm] = useState(false);
    const { googleMapsSettings, devicePositions } = useRouteLoaderData(
        'alarm-detail/caregivers'
    );

    useEffect(() => {
        const getCareRegistration = async (caregiverType) => {
            let careRegistrations = [];

            switch (caregiverType) {
                case EdsCaregiverTypes.ProfessionalCaregiver:
                    careRegistrations =
                        await getCareRegistrationsForProfessionalCaregiver(
                            alarm?.id,
                            selectedCaregiver?.id,
                            { includeHistoricalCareRegistrations: false }
                        );
                    break;
                case EdsCaregiverTypes.PersonalCaregiver:
                    careRegistrations =
                        await getCareRegistrationsForPersonalCaregiver(
                            alarm?.id,
                            selectedCaregiver?.id,
                            { includeHistoricalCareRegistrations: false }
                        );
                    break;
            }

            if (!_.isEmpty(careRegistrations)) {
                const registration = careRegistrations.find(
                    (item) =>
                        ![
                            EdsCareRegistrationState.Finished.name,
                            EdsCareRegistrationState.Cancelled.name,
                        ].includes(item?.careRegistrationState)
                );
                setCareRegistration(registration);
            } else {
                setCareRegistration(null);
            }
        };

        getCareRegistration(selectedCaregiver?.type);
    }, [alarm, selectedCaregiver]);

    useEffect(() => {
        if (_.isNil(careRegistration)) {
            return;
        }

        if (
            careRegistration?.careRegistrationState !==
            selectedCaregiver?.careRegistrationState
        ) {
            updateSelectedCaregiver({
                ...selectedCaregiver,
                careRegistrationState: careRegistration?.careRegistrationState,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [careRegistration, selectedCaregiver]);

    const getPanelLabel = () => {
        switch (selectedCaregiver?.type) {
            case EdsCaregiverTypes.Team:
                return t('25d6bc2f17f172df3b75432caab0d917', 'Link team');
            case EdsCaregiverTypes.PersonalCaregiver:
                return t(
                    '9d3c3183263588ea08ce34319a530303',
                    'Link personal caregiver'
                );
            case EdsCaregiverTypes.ProfessionalCaregiver:
                return t(
                    '1ca9561c9db8b74135fb87d8cf5dece3',
                    'Link professional caregiver'
                );
            default:
                return t('2a304a1348456ccd2234cd71a81bd338', 'Link');
        }
    };
    const [label, setLabel] = useState(getPanelLabel());

    useEffect(() => {
        setDisabled(_.isNil(selectedCaregiver?.id));
        setLabel(getPanelLabel());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCaregiver]);

    const handleActionRemarkOnClick = () => {
        setShowRemarkForm(true);
    };

    const handleSetToJourneyOnClick = async () => {
        let careRegistrationResponse;
        switch (selectedCaregiver?.type) {
            case EdsCaregiverTypes.ProfessionalCaregiver:
                careRegistrationResponse =
                    await postCareRegistrationForProfessionalCaregiver(
                        alarm?.id,
                        selectedCaregiver?.id,
                        {
                            calledTime: DateTime.now().toJSON(),
                            actionRemark: undefined,
                            actionOperatorName: attributes?.user?.name,
                            phoneNumber: undefined,
                        }
                    );
                break;
            case EdsCaregiverTypes.PersonalCaregiver:
                careRegistrationResponse =
                    await postCareRegistrationForPersonalCaregiver(
                        alarm?.id,
                        selectedCaregiver?.id,
                        {
                            calledTime: DateTime.now().toJSON(),
                            actionRemark: undefined,
                            actionOperatorName: attributes?.user?.name,
                            phoneNumber: undefined,
                        }
                    );
                break;
        }
        if (!_.isNil(careRegistrationResponse)) {
            setCareRegistration(careRegistrationResponse);

            if (
                careRegistrationResponse?.careRegistrationState ===
                EdsCareRegistrationState.Called.name
            ) {
                await setCareRegistrationState(
                    EdsCareRegistrationState.Mobilized,
                    careRegistrationResponse
                );
            }
        }
    };

    const getStateTransitions = (currentStateName, newState) => {
        const transitionNames = [newState?.name];

        while (
            !_.isEmpty(newState?.validFromStateNames) &&
            !newState.validFromStateNames.includes(currentStateName)
        ) {
            newState = getEdsCareRegistrationStateByName(
                _.first(newState.validFromStateNames)
            );
            transitionNames.push(newState?.name);
        }

        const transitions = transitionNames
            .toReversed()
            .map((transition, index, arr) => {
                return {
                    oldState: index === 0 ? currentStateName : arr[index - 1],
                    newState: transition,
                };
            });

        return transitions;
    };

    const setCareRegistrationState = async (
        newState,
        registration = careRegistration
    ) => {
        if (_.isNil(registration)) {
            return;
        }

        try {
            const transitions = getStateTransitions(
                registration?.careRegistrationState,
                newState
            );

            const now = DateTime.now().toJSON();
            let response;

            for (const transition of transitions) {
                response = await putCareRegistration(registration?.id, {
                    ...transition,
                    newTime: now,
                    actionOperatorName: attributes?.user?.name,
                });
            }

            setCareRegistration(response);

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: 'State',
                        name: t(newState.label),
                    }
                ),
            });

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <div className="eds-responder-panel">
            <EdsContainer title={label} disabled={disabled}>
                {showRemarkForm && (
                    <ActionRemarkInlineForm
                        alarmId={alarm?.id}
                        caregiver={selectedCaregiver}
                        onCloseCallback={() => setShowRemarkForm(false)}
                    />
                )}
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    fullWidth: true,
                                },
                                value: {
                                    fullWidth: true,
                                },
                            },
                        },
                        rows: {
                            left: [
                                ...(!showRemarkForm
                                    ? [
                                          {
                                              key: t(
                                                  'ab8ef9e254f5b0bb5e6d1ee96e7420f9',
                                                  'Action remark'
                                              ),
                                              value: (
                                                  <EdsButton
                                                      className="eds-grid-button"
                                                      renderIcon={Add}
                                                      kind="tertiary"
                                                      disabled={disabled}
                                                      onClick={
                                                          handleActionRemarkOnClick
                                                      }
                                                  >
                                                      {t(
                                                          '8b6953166d26a5837450b7ecaff05d66',
                                                          'Add remark'
                                                      )}
                                                  </EdsButton>
                                              ),
                                          },
                                      ]
                                    : []),
                                ...(_.isNil(careRegistration)
                                    ? [
                                          {
                                              key: t(
                                                  'd1c3ea656422b0c754d10cf88b7eb850',
                                                  'Enable cargiver'
                                              ),
                                              value: (
                                                  <EdsButton
                                                      className="eds-grid-button"
                                                      renderIcon={Road}
                                                      kind="tertiary"
                                                      disabled={disabled}
                                                      onClick={
                                                          handleSetToJourneyOnClick
                                                      }
                                                  >
                                                      {t(
                                                          'b47bdae130a147c9aea1cd35a7a92f6f',
                                                          'Set to journey'
                                                      )}
                                                  </EdsButton>
                                              ),
                                          },
                                      ]
                                    : [
                                          {
                                              key: t(
                                                  '29c1d9e86581bad02e8b5baed42bbf57',
                                                  'Enabled caregiver'
                                              ),
                                              value: (
                                                  <OptionalTimeButton
                                                      careRegistration={
                                                          careRegistration
                                                      }
                                                      newState={
                                                          EdsCareRegistrationState.Cancelled
                                                      }
                                                      danger={true}
                                                      onClickCallback={
                                                          setCareRegistrationState
                                                      }
                                                  />
                                              ),
                                          },
                                          {
                                              key: t(
                                                  '98dc0157e90a9b28306967d14bdd2f4f',
                                                  'Journey'
                                              ),
                                              value: (
                                                  <OptionalTimeButton
                                                      careRegistration={
                                                          careRegistration
                                                      }
                                                      newState={
                                                          EdsCareRegistrationState.Mobilized
                                                      }
                                                      onClickCallback={
                                                          setCareRegistrationState
                                                      }
                                                  />
                                              ),
                                          },
                                          {
                                              key: t(
                                                  '10dd75dd95439ec85804a67636d50aca',
                                                  'At client'
                                              ),

                                              value: (
                                                  <OptionalTimeButton
                                                      careRegistration={
                                                          careRegistration
                                                      }
                                                      newState={
                                                          EdsCareRegistrationState.Arrived
                                                      }
                                                      onClickCallback={
                                                          setCareRegistrationState
                                                      }
                                                  />
                                              ),
                                          },
                                          {
                                              key: t(
                                                  '6b2ded51d81a4403d8a4bd25fa1e57ee',
                                                  'Done'
                                              ),
                                              value: (
                                                  <OptionalTimeButton
                                                      careRegistration={
                                                          careRegistration
                                                      }
                                                      newState={
                                                          EdsCareRegistrationState.Finished
                                                      }
                                                      onClickCallback={
                                                          setCareRegistrationState
                                                      }
                                                  />
                                              ),
                                          },
                                      ]),
                            ],
                        },
                    }}
                />
            </EdsContainer>

            <EdsMaps
                apiKey={googleMapsSettings?.apiKey}
                markers={getMapMarkers(devicePositions)}
            />
        </div>
    );
}

function OptionalTimeButton({
    careRegistration,
    newState,
    danger = false,
    onClickCallback,
}) {
    const { t } = useTranslation();
    const buttonClickTimeoutId = useRef(null);
    const timeValue = careRegistration?.[newState?.field];

    const getButtonStyle = (danger) => {
        let classes = ['eds-grid-button'];
        if (danger) {
            classes.push('eds-danger');
        }
        return classes.join(' ');
    };

    const handleOnClick = () => {
        if (_.isFunction(onClickCallback)) {
            debounce(buttonClickTimeoutId, () => {
                onClickCallback(newState);
            });
        }
    };

    if (!_.isNil(timeValue)) {
        return <EdsTableDatetime value={timeValue} />;
    } else {
        return (
            <EdsButton
                className={getButtonStyle(danger)}
                kind={danger ? 'ghost' : 'tertiary'}
                onClick={handleOnClick}
            >
                {t(newState?.buttonText)}
            </EdsButton>
        );
    }
}
