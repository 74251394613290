import { useRevalidator, useRouteLoaderData } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    EdsContainerGrid,
    EdsLoading,
    UmoLinkedClientsForSchemes,
} from '../../../../components';
import { getClients } from '../../../../features';
import { useRef } from 'react';

function ClientsTab() {
    const { t } = useTranslation();
    const revalidator = useRevalidator();
    const scheme = useRouteLoaderData('scheme/id');

    const clientsTableRef = useRef(null);

    if (revalidator.state === 'loading') {
        return <EdsLoading />;
    }

    return (
        <EdsContainerGrid>
            <UmoLinkedClientsForSchemes
                ref={clientsTableRef}
                hideWhenNoData={false}
                title={t('fac04ca68a48af91f0290001604a2463', 'Clients')}
                getDataCallback={() =>
                    getClients({
                        schemeId: scheme.id,
                        $filter: 'clientStatus/consumerStateId ne 3',
                    })
                }
            />
        </EdsContainerGrid>
    );
}
export default ClientsTab;
