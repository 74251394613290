import { WarningAltFilled } from '@carbon/icons-react';
import { EdsTooltip } from '../../eds-tooltip/eds-tooltip';
import './eds-table-warning.scss';

const warning = (
    <WarningAltFilled className="eds-table-warning eds-warning" size="20" />
);

export function EdsTableWarning(props) {
    if (!props.value) {
        return null;
    }

    if (props.tooltip) {
        return (
            <EdsTooltip align={props.align} label={props.tooltip}>
                {warning}
            </EdsTooltip>
        );
    }

    return warning;
}
