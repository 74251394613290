import { useEffect, useRef, useState } from 'react';
import {
    EdsTableDeleteButton,
    EdsTableReplaceButton,
    EdsTableImageButton,
    EdsTableEditButton,
    EdsTableLogButton,
    EdsTableUnlinkButton,
    EdsTableDownloadButton,
    EdsTableActionbarButton,
    EdsTableOpenLinkButton,
    EdsTableCallButton,
    EdsTableNotesButton,
    EdsTableNavigateToButton,
    EdsTableLinkButton,
} from '../../';
import { debounce } from '../../../../features';
import './eds-table-actionbar.scss';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { OverflowMenuVertical } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

export const EdsActionButtonTypes = Object.freeze({
    Edit: 'EDIT',
    Download: 'DOWNLOAD',
    Image: 'IMAGE',
    Replace: 'REPLACE',
    Delete: 'DELETE',
    Link: 'LINK',
    Unlink: 'UNLINK',
    Log: 'LOG',
    Custom: 'CUSTOM',
    OpenLink: 'OPEN_LINK',
    Call: 'CALL',
    Notes: 'NOTES',
    NavigateTo: 'NAVIGATE_TO',
});

export function EdsTableActionbar({
    row,
    actionButtons,
    hideActionButtons = [],
    size,
    overflowMenuLabel,
    showActionButtonsAsOverflowMenu = false,
}) {
    const { t } = useTranslation();
    const buttonClickTimeoutId = useRef(null);
    const [regularButtons, setRegularButtons] = useState([]);
    const [overflowButtons, setOverflowButtons] = useState([]);

    useEffect(() => {
        setOverflowButtons(getOverflowButtons());
        setRegularButtons(getRegularButtons());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionButtons]);

    const getOverflowButtons = () => {
        return showActionButtonsAsOverflowMenu
            ? actionButtons
            : actionButtons.filter((item) => item.showInOverflowMenu);
    };

    const getRegularButtons = () => {
        return !showActionButtonsAsOverflowMenu
            ? actionButtons.filter((item) => !item.showInOverflowMenu)
            : [];
    };

    const onHandleButtonClick = (btnOnClick, row) => {
        if (_.isFunction(btnOnClick)) {
            debounce(
                buttonClickTimeoutId,
                () => {
                    btnOnClick(row);
                },
                { delay: 500 }
            );
        }
    };

    const onHandleShowLabel = (btnOnShowLabel, row) => {
        if (_.isFunction(btnOnShowLabel)) {
            return btnOnShowLabel(row);
        }
    };
    const getRenderOverflowButtons = () => {
        return overflowButtons.map((actionButton, index) => {
            const button = renderActionButton(actionButton, index);
            // returned item for the OverflowMenu must be a OverflowMenuItem in order to work properly
            return (
                <OverflowMenuItem
                    className={button.props?.className}
                    index={button.props?.index}
                    key={button.props?.index}
                    onClick={button.props?.onClick}
                    itemText={button.props?.label}
                    isDelete={
                        actionButton?.type === EdsActionButtonTypes.Delete ||
                        actionButton?.type === EdsActionButtonTypes.Unlink
                    }
                    hasDivider={actionButton?.hasDivider}
                ></OverflowMenuItem>
            );
        });
    };

    const renderActionButton = (actionButton, index) => {
        const props = {
            index: index,
            onClick: () => {
                onHandleButtonClick(actionButton.onClick, row);
            },
            size: size,
            label: _.isFunction(actionButton.label)
                ? onHandleShowLabel(actionButton.label, row)
                : actionButton.label,
            align: actionButton.align,
            ...(_.isFunction(actionButton.props) && {
                ...actionButton.props(row),
            }),
            ...(!_.isFunction(actionButton.props) && {
                ...actionButton?.props,
            }),
            showInOverflowMenu:
                actionButton?.showInOverflowMenu ||
                showActionButtonsAsOverflowMenu,
        };

        if (hideActionButtons.includes(actionButton.type)) {
            return;
        }

        switch (actionButton.type) {
            case EdsActionButtonTypes.NavigateTo:
                return (
                    <EdsTableNavigateToButton
                        key={actionButton.type}
                        {...props}
                    />
                );
            case EdsActionButtonTypes.Edit:
                return (
                    <EdsTableEditButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Download:
                return (
                    <EdsTableDownloadButton
                        key={actionButton.type}
                        {...props}
                    />
                );
            case EdsActionButtonTypes.Image:
                return (
                    <EdsTableImageButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Replace:
                return (
                    <EdsTableReplaceButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Delete:
                return (
                    <EdsTableDeleteButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Link:
                return (
                    <EdsTableLinkButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Unlink:
                return (
                    <EdsTableUnlinkButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Log:
                return <EdsTableLogButton key={actionButton.type} {...props} />;

            case EdsActionButtonTypes.OpenLink:
                return (
                    <EdsTableOpenLinkButton
                        key={actionButton.type}
                        {...props}
                    />
                );
            case EdsActionButtonTypes.Call:
                return (
                    <EdsTableCallButton
                        key={actionButton.type}
                        {...props}
                        row={row}
                        onClick={actionButton.onClick}
                    />
                );
            case EdsActionButtonTypes.Notes:
                return (
                    <EdsTableNotesButton key={actionButton.type} {...props} />
                );
            case EdsActionButtonTypes.Custom:
                return (
                    <EdsTableActionbarButton
                        key={uuid()}
                        icon={actionButton.icon}
                        {...props}
                    />
                );
            default:
                return;
        }
    };

    if (actionButtons.length === 0) {
        return;
    }

    return (
        <div className="eds-table-actionbar">
            {regularButtons.map((actionButton) => {
                return renderActionButton(actionButton);
            })}

            {!_.isEmpty(overflowButtons) && (
                <OverflowMenu
                    flipped={true}
                    menuOptionsClass="eds-table-overflow-menu"
                    renderIcon={OverflowMenuVertical}
                    iconDescription={
                        overflowMenuLabel ?? _.isEmpty(regularButtons)
                            ? t('93da65a9fd0004d9477aeac024e08e15', 'Options')
                            : t(
                                  'cecc1e203ff32cf3e9359f1b8f3bf449',
                                  'More options'
                              )
                    }
                    align={'top-right'}
                >
                    {getRenderOverflowButtons()}
                </OverflowMenu>
            )}
        </div>
    );
}
