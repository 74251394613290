import {
    EdsPage,
    EdsContainerTable,
    EdsAlarmStatus,
    EdsTableCriterion,
    EdsTableDatetimeSeconds,
    EdsContainerGrid,
    EdsSortDirection,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import {
    postAcceptAlarm,
    useAlarms,
    getActiveAlarmQueue,
    useEffectOnMount,
    postResumeAlarm,
    useModal,
    postCreateManualAlarm,
    useAuth,
    useNotifications,
} from '../../../features';
import './queue-page.scss';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { CreateAlarmWizard } from './new/create-alarm-wizard';
import _ from 'lodash';

function QueuePage() {
    const alarmsQueueRef = useRef(null);
    const activeAlarmsQueueRef = useRef(null);
    const { t } = useTranslation();
    const { getAlarms, alarmQueue, activeAlarmQueue } = useAlarms();
    const { showModal, showConfirmationModal } = useModal();
    const { showSuccess, showError } = useNotifications();
    const navigate = useNavigate();
    const { attributes } = useAuth();
    const agentId = attributes?.user?.sub;

    useEffectOnMount(() => {
        getAlarms();
    });

    useEffect(() => {
        alarmsQueueRef.current.fetchInitData();
        activeAlarmsQueueRef.current.fetchInitData();
    }, [alarmQueue, activeAlarmQueue]);

    const queueHeaders = [
        {
            key: 'criterion',
            header: t('4d4b370ab9b3dbca1ccdb1a5cca6a728', 'Criterion'),
            sort: 'criterion',
        },
        {
            key: 'timeStamp',
            header: t('01c76cca45a8dc7a3cd3097e734ee65b', 'Received on'),
            sort: 'timeStampValue',
            isDefaultSort: EdsSortDirection.Ascending,
        },
        {
            key: 'account_name',
            header: t('e268443e43d93dab7ebef303bbe9642f', 'Account'),
            sort: 'account_name',
        },
        {
            key: 'name',
            header: t('9fd352e406ac9fd2164c0ed50b35932b', 'Client name'),
            sort: 'name',
        },
        {
            key: 'device',
            header: t('913f9c49dcb544e2087cee284f4a00b7', 'Device'),
            sort: 'device',
        },
        {
            key: 'comibinedStatus',
            header: t('9acb44549b41563697bb490144ec6258', 'Status'),
            sort: 'status',
        },
    ];

    const queueMappingCallback = (responseData) => {
        return {
            id: responseData.id,
            timeStamp: (
                <EdsTableDatetimeSeconds
                    value={responseData?.data?.timestamp}
                />
            ),
            timeStampValue: responseData?.data?.timestamp,
            criterion: (
                <EdsTableCriterion
                    label={responseData?.data.customer_metadata?.criterion_name}
                    criterion={responseData?.data?.criterion_number}
                    icon={responseData?.data.customer_metadata?.criterion_icon}
                    color={
                        responseData?.data.customer_metadata?.criterion_color
                    }
                />
            ),
            name: responseData?.data.customer_metadata?.client_name,
            account_name: responseData?.data.customer_metadata?.account_name,
            priority: responseData?.data.customer_metadata?.priority,
            device: responseData?.data.device_code,
            comibinedStatus: (
                <EdsAlarmStatus
                    assignee={responseData?.assigned_agent?.name}
                    status={responseData?.status}
                    startDate={responseData.data.timestamp}
                />
            ),
            assignedAgent: responseData?.assigned_agent,
            status: responseData?.status,
        };
    };

    const showCreateAlarmModal = () => {
        showModal({
            title: t('8a219e1c734cd8127ee07e30dff6f7ad', 'Create manual alarm'),
            size: 'lg',
            hidePrimaryButton: true,
            hideSecondaryButton: true,
            secondaryButtonText: t('716f6b30598ba30945d84485e61c1027', 'Close'),
            children: (
                <CreateAlarmWizard
                    onSubmit={async (form) => {
                        let clientId = parseInt(form.client?.clientId) ?? 0;
                        let accountId = parseInt(form.client?.accountId);
                        const account = attributes?.accounts?.find(
                            (item) => item.id === accountId
                        );
                        let accountIdentifier = account?.umoAccountIdentifier;
                        let residenceId =
                            parseInt(
                                form.client?.residenceId ?? form.residenceId
                            ) ?? 0;
                        let deviceId = parseInt(form.device?.deviceId) ?? 0;
                        let deviceCode = form.deviceCode;

                        try {
                            postCreateManualAlarm({
                                alarm: {
                                    account_id: accountIdentifier,
                                    correlation_id: null,
                                    device_code: deviceCode,
                                    device_id: deviceId,
                                    client_id: clientId,
                                    residence_id: residenceId,
                                },
                            });
                            showSuccess({
                                title: t(
                                    '260ca9dd8a4577fc00b7bd5810298076',
                                    'Success'
                                ),
                                content: t(
                                    '5618438fff83b790689c07fbb68d16e7',
                                    'Created new manual alarm'
                                ),
                            });
                        } catch (error) {
                            showError({
                                title: t(
                                    'cb5e100e5a9a3e7f6d1fd97512215282',
                                    'Error'
                                ),
                                content: t(
                                    'df0a9d46baf7315909e4389a04786e3d',
                                    'Oops something went wrong'
                                ),
                            });

                            throw error;
                        }
                    }}
                />
            ),
        });
    };

    const onRowClick = async (data) => {
        const assignedToAgentId = data?.assignedAgent?.id;

        if (!_.isNil(assignedToAgentId) && assignedToAgentId !== agentId) {
            //if the alarm is assigned to a different user
            showConfirmationModal({
                title: t(
                    'f5e0840af79f3da4f8bc09feeca1ec08',
                    'Cannot accept this alarm'
                ),
                question: t(
                    'f357812f53bc4e10c851a61db20d15e9',
                    'This alarm is already being handled by {{name}}',
                    {
                        name: data?.assignedAgent?.name,
                    }
                ),
                secondaryButtonText: t(
                    '10aec35353f9c4096a71c38654c3d402',
                    'Cancel'
                ),
                primaryButtonText: t(
                    'b793e0ba216c22a244e15876e1791bc8',
                    'View alarm'
                ),
                yesHandler: async () => {
                    navigate(`/alarm/details/${data.id}`);
                },
            });

            return;
        }

        if (data.status !== 'active') {
            if (data.status === 'unassigned') {
                await postAcceptAlarm(data.id);
            } else {
                await postResumeAlarm(data.id);
            }
        }
        navigate(`/alarm/details/${data.id}`);
    };

    return (
        <EdsPage>
            <EdsContainerGrid>
                <EdsContainerTable
                    ref={activeAlarmsQueueRef}
                    title={t('790a0c44177e99fac27016ec9ed953b3', 'My alarms')}
                    headers={queueHeaders}
                    noDataLabel={t(
                        '7d22ec1dbb68ac9fdf43376d056be189',
                        'There are no alarms assigned to you'
                    )}
                    getDataCallback={getActiveAlarmQueue}
                    mappingCallback={queueMappingCallback}
                    onRowClick={(data) => {
                        let rowData = activeAlarmsQueueRef.current?.getRowData(
                            data.id
                        );
                        onRowClick(rowData);
                    }}
                    showAddLabel={true}
                    addLabel={t(
                        '8a219e1c734cd8127ee07e30dff6f7ad',
                        'Create manual alarm'
                    )}
                    searchClientSide={true}
                    hasPaging={false}
                    onHeaderAddClick={showCreateAlarmModal}
                />

                <EdsContainerTable
                    ref={alarmsQueueRef}
                    title={t('3517d17f97589f6ee011e4d64ba4ec90', 'Alarm queue')}
                    headers={queueHeaders}
                    noDataLabel={t(
                        '4d9f7f0819668cb732fa8f7fd5361d0e',
                        'The queue is empty'
                    )}
                    pageSize={100}
                    getDataCallback={() => {
                        return alarmQueue;
                    }}
                    mappingCallback={queueMappingCallback}
                    onRowClick={(data) => {
                        let rowData = alarmsQueueRef.current?.getRowData(
                            data.id
                        );
                        onRowClick(rowData);
                    }}
                />
            </EdsContainerGrid>
        </EdsPage>
    );
}

export default QueuePage;
