import { useTranslation } from 'react-i18next';
import {
    EdsCheckbox,
    EdsFormColumn,
    EdsFormGroup,
    EdsNumberInput,
} from '../../../eds';
import { CheckboxGroup, Row } from '@carbon/react';
import _ from 'lodash';

function AdditionalInformationFormGroup({ showHasKey = true, ...props }) {
    const { t } = useTranslation();

    const formDefinition = {
        order: {
            validation: {
                type: 'number',
                min: 0,
                required: false,
            },
        },
        ...(showHasKey && {
            hasKey: false,
        }),
        ...(!_.isUndefined(props.wizardStepId) && {
            wizardStepId: props.wizardStepId,
        }),
    };

    return (
        <EdsFormGroup
            prefix="linkInfo"
            formDefinition={formDefinition}
            inStep={true}
        >
            <Row>
                <EdsFormColumn>
                    <EdsNumberInput
                        name="order"
                        label={t('70a17ffa722a3985b86d30b034ad06d7', 'Order')}
                    />
                </EdsFormColumn>
            </Row>
            {showHasKey && (
                <Row>
                    <EdsFormColumn>
                        <CheckboxGroup legendText="">
                            <EdsCheckbox
                                name="hasKey"
                                labelText={t(
                                    'ce380372c6231ea71ee4d0a798b99959',
                                    'Has key'
                                )}
                            />
                        </CheckboxGroup>
                    </EdsFormColumn>
                </Row>
            )}
        </EdsFormGroup>
    );
}

export default AdditionalInformationFormGroup;
