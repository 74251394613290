import {
    CheckmarkFilled,
    ErrorFilled,
    Misuse,
    PendingFilled,
    WarningAltFilled,
} from '@carbon/icons-react';
import './eds-table-state.scss';
import _ from 'lodash';
import { consumerStates } from '../../../../features';
import { EdsTableStates } from './eds-table-states';

export function EdsTableState({
    state,
    label,
    validState,
    invalidState,
    warning = false,
}) {
    return (
        <div className="eds-table-state">
            {!_.isUndefined(validState) && state === validState && (
                <CheckmarkFilled className="eds-success" size="20" />
            )}

            {warning
                ? !_.isUndefined(invalidState) &&
                  state === invalidState && (
                      <WarningAltFilled className="eds-warning" size="20" />
                  )
                : !_.isUndefined(invalidState) &&
                  state === invalidState && (
                      <ErrorFilled className="eds-danger" size="20" />
                  )}

            <div>{label}</div>
        </div>
    );
}

export function EdsTableMultiState({
    state,
    validState = EdsTableStates.Valid,
    invalidState = EdsTableStates.Invalid,
    indeterminateState = EdsTableStates.Indeterminate,
    label,
}) {
    return (
        <div className="eds-table-state">
            {state === validState && (
                <CheckmarkFilled className="eds-success" size="20" />
            )}
            {state === invalidState && (
                <Misuse className="eds-danger" size="20" />
            )}
            {state === indeterminateState && (
                <PendingFilled className="eds-info" size="20" />
            )}
            <div>{label}</div>
        </div>
    );
}

export function EdsTableConsumerState({ state, label }) {
    return (
        <EdsTableMultiState
            state={state}
            validState={consumerStates.Started}
            invalidState={consumerStates.Ended}
            indeterminateState={consumerStates.ContractRequest}
            label={label}
        />
    );
}
