import { lazy } from 'react';
import { redirect } from 'react-router';
import {
    CrmMaintenanceTenantPermissionSet,
    Restricted,
} from '../../../../features';

const TenantSettingsPage = lazy(() => import('./tenant-settings-page'));
const DefaultTenantSettingsPage = lazy(() =>
    import('./default-tenant-settings/default-tenant-settings-page')
);
const GoogleMapsSettingsPage = lazy(() =>
    import('./google-maps-settings/google-maps-settings-page')
);

export const TenantSettingsRoutes = {
    path: 'tenant',
    element: (
        <Restricted to={CrmMaintenanceTenantPermissionSet} redirect={true}>
            <TenantSettingsPage />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('ce101033745bc11010f85505ed2df276', 'Tenant settings'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            loader: () => redirect('default-tenant-settings'),
        },
        {
            path: 'default-tenant-settings',
            element: <DefaultTenantSettingsPage />,
            handle: {
                crumb: (t) =>
                    t('ce101033745bc11010f85505ed2df276', 'Tenant settings'),
                documentTitleParent: true,
            },
        },
        {
            path: 'google-maps-settings',
            element: <GoogleMapsSettingsPage />,
            handle: {
                crumb: (t) =>
                    t(
                        'acf18331b7b17381044c8b107cc1ddc4',
                        'Google Maps settings'
                    ),
                documentTitleParent: true,
            },
        },
    ],
};
