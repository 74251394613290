import {
    AccountIdApiSetter,
    AuthProvider,
    ChannelsProvider,
    ModalProvider,
    NotificationsProvider,
    PermissionsProvider,
    AlarmsProvider,
    SoftphoneProvider,
    IdleTimer,
} from './features';

export const ProviderTree = ({ children }) => {
    return (
        <NotificationsProvider>
            <AuthProvider>
                <AccountIdApiSetter>
                    <PermissionsProvider>
                        <ModalProvider>
                            <ChannelsProvider>
                                <IdleTimer>
                                    <AlarmsProvider>
                                        <SoftphoneProvider>
                                            {children}
                                        </SoftphoneProvider>
                                    </AlarmsProvider>
                                </IdleTimer>
                            </ChannelsProvider>
                        </ModalProvider>
                    </PermissionsProvider>
                </AccountIdApiSetter>
            </AuthProvider>
        </NotificationsProvider>
    );
};
