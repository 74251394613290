import { useTranslation } from 'react-i18next';
import {
    EdsContainerTable,
    EdsOptionalLink,
    EdsSortDirection,
    EdsTableCriterion,
    EdsTableDatetimeSeconds,
} from '../../../eds';
import { forwardRef, useState } from 'react';
import _ from 'lodash';
import {
    getAlarmSearch,
    getAlarmSearchAllAccounts,
    getAlarmStatusLabel,
    useAuth,
    useModal,
    useNavigate,
} from '../../../../features';

export const UmoAlarmsTable = forwardRef(
    (
        {
            visibleHeaderKeys = [
                'timeStamp',
                'deviceCode',
                'criteria',
                'type',
                'cli',
                'status',
            ],
            enableContainer,
            searchAllAccounts = false,
            allowSort = false,
            defaultSort,
            baseFilter,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const { attributes } = useAuth();
        // const tableRef = useRef(null);

        const [title] = useState(
            props.title ?? t('0500cd47ceefb6776f9d279889775775', 'Alarms')
        );

        const headers = [
            {
                key: 'timeStamp',
                header: t('01c76cca45a8dc7a3cd3097e734ee65b', 'Received on'),
                sort: allowSort ? 'timeStamp' : undefined,
                width: '250px',
            },
            {
                key: 'deviceCode',
                header: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
                sort: allowSort ? 'deviceCode' : undefined,
                search: 'deviceCode',
            },
            {
                key: 'deviceCodeNoLink',
                header: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
                sort: allowSort ? 'deviceCode' : undefined,
            },
            {
                key: 'criteria',
                header: t('15c46c6e8cc3bc71a1f7ac25fd043a38', 'Criteria'),
                sort: allowSort ? 'criterion,criterionName,id' : undefined,
            },
            {
                key: 'locationDescription',
                header: t('d5189de027922f81005951e6efe0efd5', 'Location'),
                sort: allowSort ? 'locationDescription' : undefined,
            },
            {
                key: 'client',
                header: t('62608e08adc29a8d6dbc9754e659f125', 'Client'),
            },
            {
                key: 'residence',
                header: t('cad4811efcdb1000aae7a6841d995713', 'Residence'),
            },
            {
                key: 'type',
                header: t('906eb66e47713fb446120ba0c8622552', 'Alarm type'),
                sort: allowSort ? 'alarmTypeName' : undefined,
            },
            {
                key: 'cli',
                header: t('0898b22730d57afcd394d8e4889ece4a', 'CLI'),
                sort: allowSort ? 'cli' : undefined,
            },
            {
                key: 'status',
                header: t('9acb44549b41563697bb490144ec6258', 'Status'),
                sort: allowSort ? 'status' : undefined,
            },

            {
                key: 'remark',
                header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
                sort: allowSort ? 'operatorRemarks' : undefined,
            },
            {
                key: 'alarmTypeName',
                header: t('906eb66e47713fb446120ba0c8622552', 'Alarm type'),
                sort: allowSort ? 'alarmTypeName' : undefined,
            },
            {
                key: 'operator',
                header: t('34bb9e14a633756c205e777a34f004c0', 'Handled by'),
            },
            {
                key: 'account',
                header: t('e268443e43d93dab7ebef303bbe9642f', 'Account'),
            },
        ];

        const getVisibleHeaders = () => {
            const allHeaders = _.isNil(defaultSort)
                ? headers
                : headers.map((header) => {
                      if (header?.key === defaultSort?.key) {
                          header.isDefaultSort =
                              defaultSort?.order ?? EdsSortDirection.Ascending;
                      }
                      return header;
                  });

            if (!visibleHeaderKeys) {
                return allHeaders;
            }

            return visibleHeaderKeys.map((key) => {
                return allHeaders.find((h) => h.key === key);
            });
        };

        const isVisible = (headerKey) => {
            if (!visibleHeaderKeys) {
                return true;
            }
            return visibleHeaderKeys.includes(headerKey);
        };

        const mapCriteria = (responseData) => {
            if (
                _.isNil(responseData?.alarmIcon?.iconName) ||
                _.isNil(responseData?.alarmIcon?.iconColor)
            ) {
                if (responseData?.alarmCriterion) {
                    return `${responseData?.criterion} - ${responseData?.alarmCriterion?.name} (${responseData?.alarmLocation?.description})`;
                } else {
                    return `${responseData?.criterion} - ${responseData?.criterionName} (${responseData?.locationDescription})`;
                }
            }

            return (
                <EdsTableCriterion
                    label={responseData?.criterionName}
                    criterion={responseData?.criterion}
                    icon={responseData?.alarmIcon?.iconName}
                    color={responseData?.alarmIcon?.iconColor}
                />
            );
        };

        const addBaseFilter = (filter) => {
            const filterParts = [];

            if (!_.isUndefined(filter)) {
                filterParts.push(filter);
            }

            if (baseFilter) {
                filterParts.push(baseFilter);
            }

            if (_.isEmpty(filterParts)) {
                return undefined;
            }

            return filterParts.join(' and ');
        };

        const getDataCallbackMethod = async ({ $filter, ...params }) => {
            const searchParams = {
                ...params,
                $filter: addBaseFilter($filter),
            };

            if (searchAllAccounts) {
                return getAlarmSearchAllAccounts(searchParams);
            } else {
                return getAlarmSearch(searchParams);
            }
        };

        const getAccount = (accountIdentifier) => {
            return attributes?.accounts?.find(
                (item) => item?.umoAccountIdentifier === accountIdentifier
            );
        };

        return (
            <EdsContainerTable
                enableContainer={enableContainer}
                title={title}
                ref={ref}
                headers={getVisibleHeaders()}
                searchClientSide={false}
                fetchServerSideDataOnInit={true}
                getDataCallback={getDataCallbackMethod}
                mappingCallback={(responseData) => {
                    let account;
                    if (
                        isVisible('account') &&
                        !_.isNil(responseData?.accountIdentifier)
                    ) {
                        account = getAccount(responseData?.accountIdentifier);
                    }

                    return {
                        id: responseData.id,
                        ...(isVisible('timeStamp') && {
                            timeStamp: (
                                <EdsTableDatetimeSeconds
                                    value={responseData.timeStamp}
                                />
                            ),
                        }),
                        ...(isVisible('deviceCode') && {
                            deviceCode: !_.isNil(responseData.deviceUID) ? (
                                <EdsOptionalLink
                                    id={responseData?.deviceUID}
                                    to={`/crm/device/${responseData?.deviceUID}`}
                                    label={responseData.deviceCode}
                                    accountId={account?.id}
                                />
                            ) : (
                                <EdsOptionalLink
                                    id={responseData?.deviceId ?? responseData}
                                    to={`/crm/device/${responseData?.deviceId}`}
                                    label={responseData.deviceCode}
                                    accountId={account?.id}
                                />
                            ),
                        }),
                        ...(isVisible('deviceCodeNoLink') && {
                            deviceCodeNoLink: responseData.deviceCode,
                        }),
                        ...(isVisible('criteria') && {
                            criteria: mapCriteria(responseData),
                        }),
                        ...(isVisible('client') && {
                            client: !_.isNull(responseData.clientUID) ? (
                                <EdsOptionalLink
                                    id={responseData?.clientUID}
                                    to={`/crm/client/${responseData?.clientUID}`}
                                    label={t(
                                        '62608e08adc29a8d6dbc9754e659f125',
                                        'Client'
                                    )}
                                    accountId={account?.id}
                                />
                            ) : (
                                <EdsOptionalLink
                                    id={responseData?.clientId}
                                    to={`/crm/client/${responseData?.clientId}`}
                                    label={t(
                                        '62608e08adc29a8d6dbc9754e659f125',
                                        'Client'
                                    )}
                                    accountId={account?.id}
                                />
                            ),
                        }),
                        ...(isVisible('residence') && {
                            residence: !_.isNull(responseData.residenceId) ? (
                                <EdsOptionalLink
                                    id={responseData?.residenceId}
                                    to={`/crm/residence/${responseData?.residenceId}`}
                                    label={t(
                                        'cad4811efcdb1000aae7a6841d995713',
                                        'Residence'
                                    )}
                                    accountId={account?.id}
                                />
                            ) : (
                                <EdsOptionalLink
                                    id={responseData?.residenceUID}
                                    to={`/crm/residence/${responseData?.residenceUID}`}
                                    label={t(
                                        'cad4811efcdb1000aae7a6841d995713',
                                        'Residence'
                                    )}
                                    accountId={account?.id}
                                />
                            ),
                        }),
                        ...(isVisible('type') && {
                            type:
                                responseData?.alarmType?.name ??
                                responseData?.alarmTypeName,
                        }),
                        ...(isVisible('cli') && {
                            cli: responseData?.cli,
                        }),
                        ...(isVisible('status') && {
                            status: getAlarmStatusLabel(
                                t,
                                responseData.status.toLowerCase()
                            ),
                        }),
                        ...(isVisible('locationDescription') && {
                            locationDescription:
                                responseData.locationDescription,
                        }),
                        ...(isVisible('remark') && {
                            remark: responseData?.operatorRemarks,
                        }),
                        ...(isVisible('alarmTypeName') && {
                            alarmTypeName: responseData.alarmTypeName,
                        }),
                        ...(isVisible('operator') && {
                            operator: responseData.operatorName,
                        }),
                        ...(isVisible('account') && {
                            account: account.name,
                        }),
                    };
                }}
                // todo add onclick to show modalbox with alarm details in UMO-304
                //for now go directly to the alarm detail view
                onRowClick={(row) => {
                    navigate(`/crm/alarm/${row.id}`);
                }}
                {...props}
            />
        );
    }
);
UmoAlarmsTable.displayName = 'UmoAlarmsTable';

export const UmoAlarmsTableForAlarmOverview = ({ ...props }) => {
    return (
        <UmoAlarmsTable
            {...props}
            visibleHeaderKeys={[
                'timeStamp',
                'deviceCode',
                'criteria',
                'type',
                'remark',
                'cli',
                'status',
            ]}
        />
    );
};

export const UmoAlarmsTableForClientAndScheme = ({ ...props }) => {
    return (
        <UmoAlarmsTable
            {...props}
            visibleHeaderKeys={[
                'timeStamp',
                'deviceCode',
                'criteria',
                'client',
                'residence',
                'type',
                'cli',
                'status',
            ]}
        />
    );
};

export const UmoAlarmsTableForParentAlarm = ({ ...props }) => {
    return (
        <UmoAlarmsTable
            {...props}
            visibleHeaderKeys={[
                'timeStamp',
                'deviceCode',
                'criteria',
                'client',
                'residence',
                'type',
                'cli',
                'remark',
                'status',
            ]}
        />
    );
};

export const UmoAlarmsTableForDeviceErrors = ({ modalId, ...props }) => {
    const navigate = useNavigate();
    const { remove } = useModal();

    return (
        <UmoAlarmsTable
            {...props}
            visibleHeaderKeys={[
                'timeStamp',
                'deviceCodeNoLink',
                'criteria',
                'type',
                'cli',
                'status',
            ]}
            onRowClick={(row) => {
                navigate(`/crm/alarm/${row.id}`);
                if (!_.isUndefined(modalId)) {
                    remove(modalId);
                }
            }}
        />
    );
};
