import { lazy } from 'react';
import { Outlet, redirect } from 'react-router';
import { pageLoader as AlarmReasonCategorySettingsPageLoader } from './alarm-reason-category/loader';
import { pageLoader as RenderedHelpCategorySettingsPageLoader } from './rendered-help-category/loader';
import { pageLoader as AlarmProcedureDetailsPageLoader } from './alarm-procedure/loader';

import {
    CrmMaintenanceAlarmPermissionSet,
    Restricted,
} from '../../../../features';

const AlarmSettingsPage = lazy(() => import('./alarm-settings-page'));
const AlarmReasonCategoryPage = lazy(() =>
    import('./alarm-reason-category/alarm-reason-category-page')
);
const AlarmDetailPage = lazy(() => import('./alarm-detail/alarm-detail-page'));
const RenderedHelpCategoryPage = lazy(() =>
    import('./rendered-help-category/rendered-help-category-page')
);
const AlarmTypePage = lazy(() => import('./alarm-type/alarm-type-page'));
const AlarmProcedurePage = lazy(() =>
    import('./alarm-procedure/alarm-procedure-page')
);
const AlarmProcedureDetailsPage = lazy(() =>
    import('./alarm-procedure/alarm-procedure-details-page')
);
const AlarmProcedureDocuments = lazy(() =>
    import('./alarm-procedure/documents-tab')
);
const DefaultAlarmSettingsPage = lazy(() =>
    import('./default-alarm-settings/default-alarm-settings-page')
);
const AlarmReasonCategorySettingsPage = lazy(() =>
    import('./alarm-reason-category/alarm-reason-category-settings-page')
);
const AlarmReasonPage = lazy(() => import('./alarm-reason/alarm-reason-page'));
const RenderedHelpCategorySettingsPage = lazy(() =>
    import('./rendered-help-category/rendered-help-category-settings-page')
);
const RenderedHelpPage = lazy(() =>
    import('./rendered-help/rendered-help-page')
);
const AlarmCriteriaPage = lazy(() =>
    import('./alarm-criteria/alarm-criteria-page')
);
const AlarmSkillPage = lazy(() => import('./alarm-skill/alarm-skill-page'));

export const AlarmSettingsRoutes = {
    path: 'alarm',
    element: (
        <Restricted to={CrmMaintenanceAlarmPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('7a02df7897f5d50514d0f6916e8cc623', 'Alarm settings'),
        documentTitleParent: true,
    },
    children: [
        {
            path: '',
            element: <AlarmSettingsPage />,
            children: [
                {
                    index: true,
                    loader: () => redirect('reason-category'),
                },
                {
                    path: 'reason-category',
                    element: <AlarmReasonCategoryPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '55c2532b4de4b2da65083dca322267a8',
                                'Reason category'
                            ),
                    },
                },
                {
                    path: 'details',
                    element: <AlarmDetailPage />,
                    handle: {
                        crumb: (t) =>
                            t('951da6b7179a4f697cc89d36acf74e52', 'Detail'),
                    },
                },
                {
                    path: 'rendered-help-category',
                    element: <RenderedHelpCategoryPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '19c835c0f02d474eb12306e9cd43cc28',
                                'Rendered help category'
                            ),
                    },
                },
                {
                    path: 'type',
                    element: <AlarmTypePage />,
                    handle: {
                        crumb: (t) =>
                            t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
                    },
                },
                {
                    path: 'procedure',
                    element: <AlarmProcedurePage />,
                    handle: {
                        crumb: (t) =>
                            t('662609908ab8e0f372d83dea3511370b', 'Procedure'),
                    },
                },
                {
                    path: 'alarm-settings',
                    element: <DefaultAlarmSettingsPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '7a02df7897f5d50514d0f6916e8cc623',
                                'Alarm settings'
                            ),
                    },
                },
                {
                    path: 'alarm-criteria',
                    element: <AlarmCriteriaPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '0013d044b4cc94f3638cbd5e3753022f',
                                'Alarm criteria'
                            ),
                    },
                },
                {
                    path: 'alarm-skills',
                    element: <AlarmSkillPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '8aae156d8dfa817556acac0938f5568c',
                                'Alarm skill'
                            ),
                    },
                },
            ],
        },
        {
            // dummy reason-category path so breadcrumb will go to Reason category tab in AlarmSettingsPage
            path: 'reason-category',
            handle: {
                crumb: (t) =>
                    t('55c2532b4de4b2da65083dca322267a8', 'Reason category'),
            },
            children: [
                {
                    path: ':alarmReasonCategoryId',
                    element: <AlarmReasonCategorySettingsPage />,
                    loader: AlarmReasonCategorySettingsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.name,
                    },
                    children: [
                        {
                            index: true,
                            loader: () => redirect('reasons'),
                        },
                        {
                            path: 'reasons',
                            element: <AlarmReasonPage />,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        '40bea8d637cdf2c1b07fcf0630482b73',
                                        'Reason'
                                    ),
                            },
                        },
                    ],
                },
            ],
        },
        {
            // dummy rendered-help-category path so breadcrumb will go to Rendered helptab in RenderedHelpCategorySettingsPage
            path: 'rendered-help-category',
            handle: {
                crumb: (t) =>
                    t(
                        '19c835c0f02d474eb12306e9cd43cc28',
                        'Rendered help category'
                    ),
            },
            children: [
                {
                    path: ':renderedHelpCategoryId',
                    element: <RenderedHelpCategorySettingsPage />,
                    loader: RenderedHelpCategorySettingsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.name,
                    },
                    children: [
                        {
                            index: true,
                            loader: () => redirect('rendered-help'),
                        },
                        {
                            path: 'rendered-help',
                            element: <RenderedHelpPage />,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        '8f6e3b3231c5b56e32bd446303a5b6fe',
                                        'Rendered help'
                                    ),
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: 'procedure',
            handle: {
                crumb: (t) =>
                    t('662609908ab8e0f372d83dea3511370b', 'Procedure'),
            },
            children: [
                {
                    path: ':procedureId',
                    element: <AlarmProcedureDetailsPage />,
                    loader: AlarmProcedureDetailsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.description,
                    },
                    children: [
                        {
                            index: true,
                            loader: () => redirect('documents'),
                        },
                        {
                            path: 'documents',
                            element: <AlarmProcedureDocuments />,
                            loader: AlarmProcedureDetailsPageLoader,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        '21f64da1e5792c8295b964d159a14491',
                                        'Documents'
                                    ),
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
