import { useEffect, useRef, useState } from 'react';
import {
    EdsOriginType,
    getNavigateToTarget,
    useModal,
    useNavigate,
} from '../../../../features';
import _ from 'lodash';
import { EdsWizard, EdsWizardOrientation } from '../../../eds';
import SearchStep from './search-step';
import AdditionalInformationStep from './additional-information-step';

function UmoLinkProfessionalCaregiver({
    modalId,
    onSubmit,
    filterIds,
    filter,
    selectedId,
    originTypeName,
    originId,
    ...props
}) {
    const formRef = useRef(null);
    const wizardRef = useRef(null);
    const { remove } = useModal();
    const [selected, setSelected] = useState(
        !_.isNil(selectedId) ? `${selectedId}` : {}
    );
    const navigate = useNavigate();

    useEffect(() => {
        if (!_.isNil(selectedId)) {
            setSelected(`${selectedId}`);
        }
    }, [selectedId]);

    return (
        <EdsWizard
            orientation={EdsWizardOrientation.Horizontal}
            onCancelClick={() => {
                remove(modalId);
            }}
            onSubmit={async (_event, form, isValid) => {
                if (!isValid) {
                    return false;
                }

                if (_.isFunction(onSubmit)) {
                    const response = await onSubmit(
                        { ...form, selected: selected },
                        isValid
                    );
                    remove(modalId);
                    return response;
                } else {
                    remove(modalId);
                    return true;
                }
            }}
            ref={{
                formRef: formRef,
                wizardRef: wizardRef,
            }}
        >
            <SearchStep
                id="select-professional-caregiver"
                selectedRow={selected}
                selectedRowCallback={setSelected}
                filterIds={filterIds}
                filter={filter}
                onNewButtonClick={
                    _.isNil(originTypeName) || _.isNil(originId)
                        ? undefined
                        : () => {
                              navigate(
                                  getNavigateToTarget(
                                      EdsOriginType.ProfessionalCaregiverWizard
                                          .path,
                                      {
                                          typeName: originTypeName,
                                          id: originId,
                                      }
                                  )
                              );

                              remove(modalId);
                          }
                }
                {...props}
            />
            <AdditionalInformationStep
                id="additional-information"
                showHasKey={props?.searchEntitySelection}
            />
        </EdsWizard>
    );
}
export default UmoLinkProfessionalCaregiver;
