import { Outlet } from 'react-router';
import { AlarmReadPermissionSet, Restricted } from '../../../features';
import HistoryPage from './history-page';

const HistoryPageRoutes = {
    path: 'history',
    element: (
        <Restricted toAnyAccount={AlarmReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    children: [
        {
            index: true,
            element: <HistoryPage />,
        },
    ],
};

export default HistoryPageRoutes;
