import { lazy } from 'react';
import { Outlet } from 'react-router';
import { pageLoader as SchemePageLoader } from './details/loader';
import {
    Restricted,
    SchemeCreatePermissionSet,
    SchemeReadPermissionSet,
} from '../../../features';
import ClientsTab from './details/clients-tab';

const SchemePage = lazy(() => import('./scheme-page'));
const SchemePageNew = lazy(() => import('./new'));
const SchemePageDetails = lazy(() => import('./details'));

const SchemeTab = lazy(() => import('./details/scheme-tab'));
const SchemeManagerTab = lazy(() => import('./details/scheme-manager-tab'));
const ResidencesTab = lazy(() => import('./details/residences-tab'));
const DevicesTab = lazy(() => import('./details/devices-tab'));
const CaregiversTab = lazy(() => import('./details/caregivers-tab'));
const HistoryTab = lazy(() => import('./details/history-tab'));
const NotesTab = lazy(() => import('./details/notes-tab'));
const LogsTab = lazy(() => import('./details/logs-tab'));

const SchemePageRoutes = {
    path: 'scheme',
    element: (
        <Restricted to={SchemeReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('41323917ef8089432959a3c33269debf', 'Scheme'),
        documentTitleParent: true,
    },
    children: [
        {
            index: true,
            element: <SchemePage />,
        },
        {
            path: 'new',
            element: (
                <Restricted to={SchemeCreatePermissionSet} redirect={true}>
                    <SchemePageNew />
                </Restricted>
            ),
            handle: {
                crumb: (t) => t('22af645d1859cb5ca6da0c484f1f37ea', 'New'),
            },
        },
        {
            id: 'scheme/id',
            path: ':schemeId',
            element: <SchemePageDetails />,
            loader: SchemePageLoader,
            handle: {
                crumb: (_t, data) => data?.name,
                documentTitleParent: true,
            },
            children: [
                {
                    index: true,
                    element: <SchemeTab />,
                    handle: {
                        //for tabs to find that this route is the index
                        index: true,
                    },
                },
                {
                    path: 'scheme-manager',
                    element: <SchemeManagerTab />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'ab516e68a4b05e85579f0682c61acc92',
                                'Scheme manager'
                            ),
                    },
                },
                {
                    path: 'residences',
                    element: <ResidencesTab />,
                    handle: {
                        crumb: (t) =>
                            t('bcc78f7cf4519aa3fc75d13c0e174b86', 'Residences'),
                    },
                },
                {
                    path: 'devices',
                    element: <DevicesTab />,
                    handle: {
                        crumb: (t) =>
                            t('e0212e54ec3a2a120ca0d321b3a60c78', 'Devices'),
                    },
                },
                {
                    path: 'caregivers',
                    element: <CaregiversTab />,
                    handle: {
                        crumb: (t) =>
                            t('bc77e7fcbeea66aa5fbcd998260f66f5', 'Caregivers'),
                    },
                },
                {
                    path: 'clients',
                    element: <ClientsTab />,
                    handle: {
                        crumb: (t) =>
                            t('fac04ca68a48af91f0290001604a2463', 'Clients'),
                    },
                },
                {
                    path: 'history',
                    element: <HistoryTab />,
                    handle: {
                        crumb: (t) =>
                            t('3cd15f8f2940aff879df34df4e5c2cd1', 'History'),
                    },
                },
                {
                    path: 'notes',
                    element: <NotesTab />,
                    handle: {
                        crumb: (t) =>
                            t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes'),
                    },
                },
                {
                    path: 'logs',
                    element: <LogsTab />,
                    handle: {
                        crumb: (t) =>
                            t('2165e4fa5bddb65a31f6a0c495c2fa37', 'Logs'),
                    },
                },
            ],
        },
    ],
};

export default SchemePageRoutes;
