export * from './countries';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpApi from 'i18next-http-backend';
import { Settings } from 'luxon';
import { getLogger, isDevMode } from '../utils';

const logger = getLogger('i18n');

const httpBackendOptions = {
    /*eslint no-undef: "off"*/
    loadPath: `${window.i18n_cdn}/locales/{{lng}}/{{ns}}.json`,
    queryStringParams: {
        v: window.i18n_version,
    },
    crossDomain: true,
};

const config = {
    ns: 'main',
    defaultNS: 'main',
    // lng: 'cimode', //to show keys instead of translations
    fallbackLng: ['en-US', 'en-devel'],
    load: 'currentOnly',
    debug: isDevMode,
    //when changing these values also update the locales in EdsDatePicker and
    //the translations in EdsLanguageSwitcher
    supportedLngs: [
        'nl-NL',
        'nl-BE',
        'en-US',
        'en-GB',
        'de',
        'nb-NO', // Norwegian Bokmål
        'nn', // Norwegian Nynorsk
        'sv', // Swedish
        'da', // Danish
        'fi', // Finnish
        'fr', // French
        'es', // Spanish
        'en-devel',
    ],
    backend: {
        backends: [
            HttpApi,
            resourcesToBackend((language, namespace) => {
                logger.log('Load resource', language, namespace);

                let data = import.meta.glob('./locales/**/*.json');
                let getTranslations =
                    data[`./locales/${language}/${namespace}.json`];
                if (getTranslations) {
                    return getTranslations();
                }

                return {};
            }),
        ],
        backendOptions: [httpBackendOptions, {}],
    },
    interpolation: { escapeValue: false },
};

if (isDevMode) {
    if (!config.lng) config.lng = 'en-devel';
}

i18n.use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(config);

Settings.defaultLocale = i18n.language;

export default i18n;
