import _ from 'lodash';
import { scanI18n } from '../../../../features';

export const EdsCareRegistrationState = Object.freeze({
    Called: {
        name: 'Called',
        label: scanI18n('4f33252742282f871b0617168156fd55', 'Called'),
    },
    Mobilized: {
        name: 'Mobilized',
        field: 'mobilizedTime',
        label: scanI18n('8959af38423737b7c3723de939c7a77a', 'Mobilized'),
        buttonText: scanI18n(
            'b47bdae130a147c9aea1cd35a7a92f6f',
            'Set to journey'
        ),
        validFromStateNames: ['Called'],
    },
    Arrived: {
        name: 'Arrived',
        field: 'arrivedTime',
        label: scanI18n('241441e184ca822d7768688e9883cc26', 'Arrived'),
        buttonText: scanI18n(
            '2b6e562e7cc7bbf75bbbe88fef31cb74',
            'Timestamp at client'
        ),
        validFromStateNames: ['Mobilized'],
    },
    Finished: {
        name: 'Finished',
        field: 'finishedTime',
        label: scanI18n('a5d7ceb2c59b8cee46c2953fec9abc19', 'Finished'),
        buttonText: scanI18n(
            'a59657a79441264c57c62043bfdd5836',
            'Timestamp done'
        ),
        validFromStateNames: ['Arrived'],
    },
    Refused: {
        name: 'Refused',
        label: scanI18n('723634aa8cde73188d4661bb3fe81ce4', 'Refused'),
        validFromStateNames: ['Called'],
    },
    Cancelled: {
        name: 'Cancelled',
        label: scanI18n('38881e0a24039dc2621e1d6f86cb71f7', 'Cancelled'),
        buttonText: scanI18n('10aec35353f9c4096a71c38654c3d402', 'Cancel'),
        validFromStateNames: ['Called', 'Mobilized', 'Arrived'],
    },
});

export const getEdsCareRegistrationStateByName = (name) => {
    return _.keys(EdsCareRegistrationState).reduce((obj, key) => {
        if (EdsCareRegistrationState[key]?.name === name) {
            obj = EdsCareRegistrationState[key];
        }
        return obj;
    }, undefined);
};
