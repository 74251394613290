import _ from 'lodash';
import { getLogger } from '../../../../features';

const logger = getLogger('EdsTableFilterReducer');

export const EdsTableFilterReducerActions = {
    AddFilter: 'ADD_FILTER',
    AddFilters: 'ADD_FILTERS',
    EditFilter: 'EDIT_FILTER',
    RemoveFilter: 'REMOVE_FILTER',
    ClearSavedFilter: 'CLEAR_SAVED_FILTER',
    SetSavedFilter: 'SET_SAVED_FILTER',
    ApplySavedFilter: 'APPLY_SAVED_FILTER',
    SetSearchTerm: 'SET_SEARCH_TERM',
    SetSorting: 'SET_SORTING',
};

export const EdsTableFilterReducer = (
    searchFilterStore = {
        save: () => null,
        clear: () => null,
        name: () => null,
    }
) => {
    const saveFilterSet = (filters, search_term, sorting) => {
        logger.log(
            `[${searchFilterStore?.name()}] saveFilterSet: search_term`,
            search_term,
            ', sorting',
            sorting,
            ', filters',
            filters
        );
        if (_.isEmpty(filters) && _.isEmpty(search_term)) {
            return;
        }

        let saveFilters = _.isArray(filters)
            ? filters.map((filter) => {
                  return {
                      column: filter.column,
                      type: filter.type,
                      operator: filter.operator,
                      value: filter.value,
                  };
              })
            : [];

        searchFilterStore.save({
            filter_set: { filters: saveFilters, search_term, sorting },
        });
    };

    return (state, action) => {
        switch (action.type) {
            case EdsTableFilterReducerActions.AddFilter: {
                let filters = [...state.filters, action.payload];
                logger.log(
                    `[${searchFilterStore.name()}] Filters to save (on add filter)`,
                    filters
                );
                saveFilterSet(filters, state.searchTerm, state.sorting);

                return {
                    ...state,
                    filters: filters,
                };
            }
            case EdsTableFilterReducerActions.AddFilters: {
                let filters = [...state.filters, ...action.payload];
                logger.log(
                    `[${searchFilterStore.name()}] Filters to save (on add filters)`,
                    filters
                );
                saveFilterSet(filters, state.searchTerm, state.sorting);

                return {
                    ...state,
                    filters: filters,
                };
            }
            case EdsTableFilterReducerActions.EditFilter: {
                logger.log(
                    `[${searchFilterStore.name()}] Filters edit action`,
                    action
                );

                let filters = [
                    ...state.filters.filter(
                        (filter) => filter.id !== action.payload.id
                    ),
                    { ...action.payload },
                ];

                logger.log(
                    `[${searchFilterStore.name()}] Filters edit filters`,
                    filters
                );

                saveFilterSet(filters, state.searchTerm, state.sorting);

                return {
                    ...state,
                    filters: filters,
                };
            }
            case EdsTableFilterReducerActions.RemoveFilter: {
                let filters = state.filters.filter(
                    (filter) => filter.id !== action.id
                );
                logger.log(
                    `[${searchFilterStore.name()}] Filters to save (on remove)`,
                    filters
                );
                saveFilterSet(filters, state.searchTerm, state.sorting);

                return {
                    ...state,
                    filters: filters,
                };
            }
            case EdsTableFilterReducerActions.ClearSavedFilter: {
                logger.log(`[${searchFilterStore?.name()}] Clear saved filter`);

                searchFilterStore.clear();
                return {
                    ...state,
                    savedFilter: null,
                };
            }
            case EdsTableFilterReducerActions.SetSavedFilter: {
                logger.log(
                    `[${searchFilterStore?.name()}] Set/save saved filter`
                );
                return {
                    ...state,
                    savedFilter: action.payload,
                };
            }
            case EdsTableFilterReducerActions.ApplySavedFilter: {
                logger.log(`[${searchFilterStore?.name()}] Apply saved filter`);
                return {
                    ...state,
                    savedFilter: null,
                    filters: action.payload.filters,
                    searchTerm: action.payload.searchTerm,
                    sorting: action.payload.sorting,
                };
            }
            case EdsTableFilterReducerActions.SetSearchTerm: {
                logger.log(`[${searchFilterStore?.name()}] Set search term`);
                saveFilterSet(state.filters, action.payload, state.sorting);

                return {
                    ...state,
                    searchTerm: action.payload,
                };
            }
            case EdsTableFilterReducerActions.SetSorting: {
                logger.log(`[${searchFilterStore?.name()}] Set sorting`);
                saveFilterSet(state.filters, state.searchTerm, action.payload);

                return {
                    ...state,
                    sorting: action.payload,
                };
            }
            default: {
                return state;
            }
        }
    };
};
