import { useEffect, useRef, useState } from 'react';
import { EdsLoading, EdsWizard, EdsWizardOrientation } from '../../../eds';

import {
    defaultDropdownMappingCallback,
    EdsOriginType,
    getAccountSettings,
    getNavigateToTarget,
    useEffectOnMount,
    useModal,
    useNavigate,
} from '../../../../features';

import _ from 'lodash';
import AdditionalInformationStep from '../umo-linked-device-table/additional-information-step';
import SearchStep from './search-step';

export const UmoLinkResidence = ({
    modalId,
    getDataCallback,
    linkBaseFilter,
    filter,
    selectedId,
    originTypeName,
    originId,
    linkToDevice = false,
    onSubmit,
}) => {
    const formRef = useRef(null);
    const wizardRef = useRef(null);
    const { remove } = useModal();
    const [selected, setSelected] = useState(
        !_.isNil(selectedId) ? `${selectedId}` : {}
    );
    const [initSelected, setInitSelected] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffectOnMount(() => {
        if (_.isFunction(getDataCallback)) {
            fetchInitData();
        }
    });

    useEffect(() => {
        if (!_.isNil(selectedId)) {
            setSelected(`${selectedId}`);
        }
    }, [selectedId]);

    const fetchInitData = async () => {
        const data = await getDataCallback();
        if (data) {
            if (_.isNil(selectedId)) {
                setSelected(`${data.id}`);
            }
            setInitSelected(`${data.id}`);
        }
        setLoading(false);
    };

    if (loading) {
        return <EdsLoading />;
    }

    return (
        <EdsWizard
            orientation={EdsWizardOrientation.Horizontal}
            onCancelClick={() => {
                remove(modalId);
            }}
            onSubmit={async (_event, form, isValid) => {
                if (!isValid) {
                    return false;
                }

                if (_.isFunction(onSubmit)) {
                    const response = await onSubmit(
                        { ...form, selected: selected, initSelected },
                        isValid
                    );
                    remove(modalId);
                    return response;
                } else {
                    remove(modalId);
                    return true;
                }
            }}
            ref={{
                formRef: formRef,
                wizardRef: wizardRef,
            }}
        >
            <SearchStep
                id="select-residence"
                selectedRow={selected}
                selectedRowCallback={setSelected}
                baseFilter={linkBaseFilter}
                filter={filter}
                onNewButtonClick={
                    _.isNil(originTypeName) || _.isNil(originId)
                        ? undefined
                        : () => {
                              navigate(
                                  getNavigateToTarget(
                                      EdsOriginType.ResidenceWizard.path,
                                      {
                                          typeName: originTypeName,
                                          id: originId,
                                      }
                                  )
                              );

                              remove(modalId);
                          }
                }
            />
            {linkToDevice && (
                <AdditionalInformationStep
                    id="additional-information"
                    defaultDeviceState={async () => {
                        const accountSettings = await getAccountSettings();
                        return accountSettings?.linkDeviceDefaultState
                            ? defaultDropdownMappingCallback(
                                  accountSettings.linkDeviceDefaultState
                              )
                            : {};
                    }}
                />
            )}
        </EdsWizard>
    );
};
