import {
    Download,
    Edit,
    FlowLogsVpc,
    TrashCan,
    Unlink,
    Image,
    CharacterPatterns,
    Launch,
    PhoneOutgoing,
    Notebook,
    Compass,
    Link,
} from '@carbon/icons-react';
import { IconButton, OverflowMenu, OverflowMenuItem } from '@carbon/react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import './eds-table-actionbar-button.scss';
import { useEffect, useRef, useState } from 'react';
import { debounce } from '../../../../features';
import { EdsBadge } from '../../eds-badge/eds-badge';

export function EdsTableActionbarButton({
    index = 0,
    kind,
    label,
    size,
    align,
    icon,
    badgeKind,
    showInOverflowMenu = false,
    ...props
}) {
    const [iconLabel] = useState(_.isFunction(label) ? label() : label);
    const [iconAlign, setIconAlign] = useState(align);

    useEffect(() => {
        if (iconLabel.length > 15) {
            setIconAlign('top-right');
        }
    }, [iconLabel]);

    const handleOnClick = (event) => {
        if (_.isFunction(props.onClick)) {
            props.onClick(event);
        }
        event.stopPropagation();
    };

    const getStyle = () => {
        let classes = ['eds-table-actionbar-button'];

        if (showInOverflowMenu) {
            classes.push('eds-table-overflow-menu-item');
        }
        if (props?.className) {
            classes.push(props?.className);
        }

        return classes.join(' ');
    };

    if (showInOverflowMenu) {
        return (
            <OverflowMenuItem
                className={getStyle()}
                key={index}
                itemText={iconLabel}
                onClick={handleOnClick}
            />
        );
    }

    return (
        <EdsBadge kind={badgeKind} count={props?.count}>
            <IconButton
                className={getStyle()}
                label={iconLabel}
                kind={kind ?? 'ghost'}
                size={size ?? 'lg'}
                align={iconAlign}
                onClick={handleOnClick}
                {...props}
            >
                {icon}
            </IconButton>
        </EdsBadge>
    );
}

export function EdsTableReplaceButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            className={'eds-table-replace-button'}
            label={label ?? t('9dde360102c103867bd2f45872f1129c', 'Replace')}
            icon={<CharacterPatterns />}
            {...props}
        />
    );
}

export function EdsTableDeleteButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            className={'eds-table-delete-button'}
            label={label ?? t('9530d977022e1fce705fc0f93af0a9b7', 'Delete row')}
            icon={<TrashCan />}
            {...props}
        />
    );
}

export function EdsTableLinkButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            className={'eds-table-link-button'}
            label={label ?? t('2a304a1348456ccd2234cd71a81bd338', 'Link')}
            icon={<Link />}
            {...props}
        />
    );
}

export function EdsTableUnlinkButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            className={'eds-table-unlink-button'}
            label={label ?? t('695630cfc5eb92580fb3e76a0c790e63', 'Unlink')}
            icon={<Unlink />}
            {...props}
        />
    );
}

export function EdsTableImageButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('78805a221a988e79ef3f42d7c5bfd418', 'Image')}
            icon={<Image />}
            {...props}
        />
    );
}

export function EdsTableEditButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('176ed580b73b60b8c497dd28cdf2afc0', 'Edit row')}
            icon={<Edit />}
            {...props}
        />
    );
}

export function EdsTableDownloadButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('fd456406745d816a45cae554c788e754', 'Download')}
            icon={<Download />}
            {...props}
        />
    );
}

export function EdsTableLogButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('7d71019726dccc04ceb52b94f892b7f6', 'Audit log')}
            icon={<FlowLogsVpc />}
            {...props}
        />
    );
}

export function EdsTableOpenLinkButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('fc3dc95a15eb4fdbf9eafccd96d3248e', 'Open link')}
            icon={<Launch />}
            {...props}
        />
    );
}

export function EdsTableNotesButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes')}
            icon={<Notebook />}
            {...props}
        />
    );
}

export function EdsTableCallButton({
    label,
    phoneNumbers = [],
    row,
    ...props
}) {
    const { t } = useTranslation();
    const buttonClickTimeoutId = useRef(null);

    const handleCallOnClick = (event, phoneIndex) => {
        if (_.isFunction(props?.onClick)) {
            debounce(
                buttonClickTimeoutId,
                () => {
                    props.onClick(
                        row,
                        phoneIndex,
                        _.get(phoneNumbers, phoneIndex)
                    );
                },
                { delay: 500 }
            );
        }
    };

    if (_.size(phoneNumbers) > 1) {
        return (
            <OverflowMenu
                flipped={true}
                menuOptionsClass="eds-table-call-menu-options"
                renderIcon={PhoneOutgoing}
                iconDescription={
                    label ?? t('53b9e9679a8ea25880376080b76f98ad', 'Call')
                }
            >
                {phoneNumbers.map((item, index) => (
                    <OverflowMenuItem
                        className="eds-table-call-menu-item"
                        key={index}
                        itemText={
                            t('c9dc3c0aa75d77dfafacf53547647a65', 'Call #') +
                            `${index + 1} ${item}`
                        }
                        onClick={(event) => handleCallOnClick(event, index)}
                    />
                ))}
            </OverflowMenu>
        );
    } else {
        return (
            <EdsTableActionbarButton
                label={label ?? t('53b9e9679a8ea25880376080b76f98ad', 'Call')}
                icon={<PhoneOutgoing />}
                {...props}
                onClick={(event) => handleCallOnClick(event, 0)}
            />
        );
    }
}

export function EdsTableNavigateToButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={
                label ?? t('03e935f9dbc60ddd51310ade24f53a5a', 'Navigate to')
            }
            icon={<Compass />}
            {...props}
        />
    );
}
