import { useRouteLoaderData } from 'react-router';
import {
    EdsContainerGrid,
    UmoAlarmProfessionalCaregivers,
} from '../../../components';
import { getAlarmCareProviders } from '../../../features';

function ProfessionalCaregiversPage() {
    const { alarm } = useRouteLoaderData('alarm-detail/id');

    return (
        <EdsContainerGrid>
            <UmoAlarmProfessionalCaregivers
                getDataCallback={() => getAlarmCareProviders(alarm.id)}
            />
        </EdsContainerGrid>
    );
}

export default ProfessionalCaregiversPage;
