import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../../components/eds/eds-form';
import _ from 'lodash';
import {
    EdsFormGroup,
    EdsNotification,
    EdsSortDirection,
    EdsTable,
    EdsTableCheckmark,
    EdsTableWarning,
    EdsWizardStep,
} from '../../../../components';
import {
    accountIdHeaderName,
    getClientDevices,
    getErrorStateLabels,
} from '../../../../features';

const DeviceSearchStep = forwardRef(({ id, ...props }, ref) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(null);
    const { getFormValue, updateFormValues, isInvalid } = useForm();

    useEffect(() => {
        if (!_.isUndefined(selected)) {
            updateFormValues({
                ['device.deviceId']: selected,
            });
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const formDefinition = {
        wizardStepId: id,
        deviceId: {
            validation: {
                required: true,
            },
        },
    };

    const getDataCallback = async () => {
        const clientId = getFormValue('clientId', 'client');
        const accountId = getFormValue('accountId', 'client');
        if (_.isNil(clientId) || _.isNil(accountId)) {
            return [];
        }

        const defaultDeviceId = getFormValue('defaultDeviceId', 'client');
        if (!_.isNil(defaultDeviceId)) {
            const defaultDeviceIdString = `${defaultDeviceId}`;
            setSelected(defaultDeviceIdString);
            ref.current?.setSelectedRows([defaultDeviceIdString]);
        }

        const headers = {
            common: {
                [accountIdHeaderName]: accountId,
            },
        };

        const devices = await getClientDevices(clientId, headers);

        return devices.map((device) => {
            device.isDefault = device.id === defaultDeviceId;
            return device;
        });
    };

    const mappingCallback = (data) => {
        return {
            id: data.id,
            isDefault: <EdsTableCheckmark value={data.isDefault} />,
            isDefaultValue: data.isDefault,
            errorState: getErrorState(data),
            deviceCode: data.deviceCode,
            deviceType: data.deviceType?.name,
            deviceState: data.deviceState?.name,
            location: data.deviceLocation?.name,
            group: data.group?.name,
            phoneNumber: data.phoneNumber,
            serialNumber: data.serialNumber,
            residenceId: data.residenceId,
        };
    };

    const getErrorState = (device) => {
        const labels = getErrorStateLabels(t, device);

        return (
            <EdsTableWarning
                value={!_.isEmpty(labels)}
                tooltip={labels}
                align="top-left"
            />
        );
    };

    const headers = [
        {
            key: 'isDefault',
            header: t('6c4c3c1e466c26dca47ded5d993858ea', 'Default device'),
            sort: 'isDefaultValue',
            isDefaultSort: EdsSortDirection.Descending,
        },
        {
            key: 'errorState',
            header: t('44076a4e9d7c782fd29b4fc568e0ecdf', 'Error state'),
        },
        {
            key: 'deviceCode',
            header: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
            sort: 'deviceCode',
            search: 'deviceCode',
        },
        {
            key: 'deviceType',
            header: t('25c58563ac9a9e4d07c44dcb5ccaef38', 'Device type'),
            sort: 'deviceType',
        },
        {
            key: 'deviceState',
            header: t('100de37890376212fdea338fb9703bb4', 'Device state'),
            sort: 'deviceState',
        },
        {
            key: 'location',
            header: t('d5189de027922f81005951e6efe0efd5', 'Location'),
            sort: 'location',
        },
        {
            key: 'group',
            header: t('db0f6f37ebeb6ea09489124345af2a45', 'Group'),
            sort: 'group',
        },
        {
            key: 'phoneNumber',
            header: t('9b88e58612797d6b989681a16621ad63', 'Phone number'),
            sort: 'phoneNumber',
            search: 'phoneNumber',
        },
        {
            key: 'serialNumber',
            header: t('c9f4029ce9dcf8ff7f8f1b70edead843', 'Serial number'),
            sort: 'serialNumber',
            search: 'serialNumber',
        },
    ];

    return (
        <EdsWizardStep
            id={id}
            label={t('9123f5318e4fa2a5008e7096b990619f', 'Search device')}
        >
            <EdsFormGroup
                formDefinition={formDefinition}
                inStep={true}
                prefix="device"
            >
                {isInvalid('device.deviceId') && (
                    <EdsNotification
                        notification={{
                            hideCloseButton: true,
                            kind: 'warning',
                            title: t(
                                '8f6f527f8cbdcb9495a305b059599796',
                                'Please select a device to create an alarm for'
                            ),
                            inline: true,
                        }}
                    />
                )}
                <EdsTable
                    ref={ref}
                    searchTooltipKeywords={[
                        t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
                        t('9b88e58612797d6b989681a16621ad63', 'Phone number'),
                        t('c9f4029ce9dcf8ff7f8f1b70edead843', 'Serial number'),
                    ]}
                    headers={headers}
                    searchClientSide={true}
                    getDataCallback={getDataCallback}
                    mappingCallback={mappingCallback}
                    onRowClick={(row) => {
                        if (row.id !== selected) {
                            setSelected(row.id);
                        }
                    }}
                    onSelectionButtonClick={(row) => {
                        if (row.id !== selected) {
                            setSelected(row.id);
                        }
                    }}
                    selected={[selected]}
                    enableRadioSelection={true}
                    enableSearchParams={false}
                    searchFilterStore={undefined}
                    enableSearch={true}
                    size="lg"
                    {...props}
                />
            </EdsFormGroup>
        </EdsWizardStep>
    );
});
DeviceSearchStep.displayName = 'DeviceSearchStep';

export default DeviceSearchStep;
