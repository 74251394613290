import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsContainerGrid,
    EdsDataGrid,
    EdsForm,
    EdsTableDate,
    EdsTableState,
} from '../../../eds';
import { useRevalidator } from 'react-router';
import { Link } from '@carbon/react';
import { ArrowRight } from '@carbon/icons-react';
import { UmoDeviceManagerDetailGrid } from '../umo-device-manager-detail-grid';
import {
    getDeviceManagerPhoneNumbers,
    getDeviceTypeImageUrl,
    putDevice,
    setEmptyFieldToNull,
    useAuth,
    useModal,
    useNotifications,
} from '../../../../features';
import _ from 'lodash';
import { UmoImageView } from '../../umo-image-view/umo-image-view';
import { useRef } from 'react';
import { DeviceDetailsStepForm } from '../../../../routes/crm/device-page/new/device-details-step-form';

export const UmoDeviceDetailGrid = ({
    entity,
    accountId: propAccountId,
    ...props
}) => {
    const { t } = useTranslation();
    const { showSuccess, showError } = useNotifications();
    const { showModal, showFormModal } = useModal();
    const { accountId } = useAuth();
    const formRef = useRef(null);
    const revalidator = useRevalidator();

    const getAccountId = () => {
        if (_.isNil(accountId) && !_.isNil(propAccountId)) {
            return propAccountId;
        }
        return accountId;
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }

        try {
            const { device } = form;
            const deviceData = setEmptyFieldToNull(device);
            const response = await putDevice(deviceData);

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: t('913f9c49dcb544e2087cee284f4a00b7', 'Device'),
                        name: device.deviceCode,
                    }
                ),
            });

            revalidator.revalidate();

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    const showDataModal = (device = {}) => {
        showFormModal({
            title: t('f29d1d5c2e511408df3fb8b0742ae3e5', 'Edit device'),
            formData: device,
            formRef,
            children: (
                <EdsForm
                    hideDefaultSubmit={true}
                    initValues={device}
                    formDefinition={{}}
                    onSubmit={onSubmit}
                    ref={formRef}
                >
                    <DeviceDetailsStepForm initValues={device} />
                </EdsForm>
            ),
        });
    };

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={t('16a514529589534937dae7ba26789e37', 'Device data')}
                onHeaderEditClick={() => {
                    showDataModal({
                        device: {
                            id: {
                                value: entity.id,
                            },
                            deviceCode: {
                                value: entity?.deviceCode,
                            },
                            deviceTypeId: {
                                id: entity?.deviceType?.id,
                                text: entity?.deviceType?.name,
                                isSerialNumberRequired:
                                    entity?.deviceType?.isSerialNumberRequired,
                                isSerialNumberUnique:
                                    entity?.deviceType?.isSerialNumberUnique,
                                isDevicePhoneNumberRequired:
                                    entity?.deviceType
                                        ?.isDevicePhoneNumberRequired,
                            },
                            serialNumber: {
                                value: entity?.serialNumber,
                            },
                            description: {
                                value: entity?.description,
                            },
                            phoneNumber: {
                                value: entity?.phoneNumber,
                            },
                            deviceStateId: {
                                id: entity?.deviceState?.id,
                                text: entity?.deviceState?.name,
                            },
                            deviceLocationId: {
                                id: entity?.deviceLocation?.id,
                                text: entity?.deviceLocation?.name,
                            },
                            purchaseDate: {
                                value: entity?.purchaseDate,
                            },
                            purchasePrice: {
                                value: entity?.purchasePrice,
                            },
                            warrantyDate: {
                                value: entity?.warrantyDate,
                            },
                            batteryDate: {
                                value: entity?.batteryDate,
                            },
                            groupId: {
                                id: entity?.group?.id,
                                text: entity?.group?.name,
                            },
                            deviceManagerId: {
                                id: entity?.deviceManager?.id,
                                text: entity?.deviceManager?.name,
                            },
                            isOwnedByOrganization: {
                                value: entity?.isOwnedByOrganization,
                            },
                            remark: {
                                value: entity?.remark,
                            },
                        },
                    });
                }}
                {...props}
            >
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    width: '400px',
                                },
                            },
                            right: {
                                key: {
                                    width: '400px',
                                },
                            },
                        },
                        rows: {
                            left: [
                                {
                                    key: t(
                                        'c13367945d5d4c91047b3b50234aa7ab',
                                        'Code'
                                    ),
                                    value: entity.deviceCode,
                                },
                                {
                                    key: t(
                                        '599dcce2998a6b40b1e38e8c6006cb0a',
                                        'Type'
                                    ),
                                    value: entity.deviceType?.name,
                                },
                                {
                                    key: t(
                                        'c9f4029ce9dcf8ff7f8f1b70edead843',
                                        'Serial number'
                                    ),
                                    value: entity.serialNumber,
                                },
                                {
                                    key: t(
                                        '9ed39e2ea931586b6a985a6942ef573e',
                                        'State'
                                    ),
                                    value: entity.deviceState?.name,
                                },
                                {
                                    key: t(
                                        '5071d3a7f981bce456aaa16184cee8dd',
                                        'Purchase date'
                                    ),
                                    value: (
                                        <EdsTableDate
                                            value={entity.purchaseDate}
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        '9959097423b1c355c8431d8cda72ccf8',
                                        'Warranty valid until'
                                    ),
                                    value: (
                                        <EdsTableDate
                                            value={entity.warrantyDate}
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        'db0f6f37ebeb6ea09489124345af2a45',
                                        'Group'
                                    ),
                                    value: entity.group?.name,
                                },
                                {
                                    key: t(
                                        'a98d46e3940dbe816839eca678ea4072',
                                        'Owned by organization'
                                    ),
                                    value: (
                                        <EdsTableState
                                            validState={true}
                                            invalidState={false}
                                            state={entity.isOwnedByOrganization}
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        '911c185c8f3c475cdca2ef9cf12166da',
                                        'Remark'
                                    ),
                                    value: entity.remark,
                                },
                            ],
                            right: [
                                {
                                    key: t(
                                        'c60e59ebafaa058b5da71c131b2364b5',
                                        'Type image'
                                    ),
                                    value: entity.deviceType
                                        ?.hasImageAttached ? (
                                        <Link
                                            href=""
                                            renderIcon={() => (
                                                <ArrowRight aria-label="Arrow Right" />
                                            )}
                                            onClick={(event) => {
                                                event.preventDefault();

                                                showModal({
                                                    title: t(
                                                        'c60e59ebafaa058b5da71c131b2364b5',
                                                        'Type image'
                                                    ),
                                                    children: (
                                                        <UmoImageView
                                                            src={getDeviceTypeImageUrl(
                                                                entity
                                                                    .deviceType
                                                                    .id,
                                                                getAccountId()
                                                            )}
                                                        />
                                                    ),
                                                    secondaryButtonText: t(
                                                        '716f6b30598ba30945d84485e61c1027',
                                                        'Close'
                                                    ),
                                                    size: 'lg',
                                                    fitContent: true,
                                                    hidePrimaryButton: true,
                                                });
                                            }}
                                        >
                                            {t(
                                                '26f90d3f23f9dc643ae4e89379f77f39',
                                                'Show image'
                                            )}
                                        </Link>
                                    ) : (
                                        '-'
                                    ),
                                },
                                {
                                    key: t(
                                        '67daf92c833c41c95db874e18fcb2786',
                                        'Description'
                                    ),
                                    value: entity.description,
                                },
                                {
                                    key: t(
                                        '9b88e58612797d6b989681a16621ad63',
                                        'Phone number'
                                    ),
                                    value: entity.phoneNumber,
                                },
                                {
                                    key: t(
                                        'd5189de027922f81005951e6efe0efd5',
                                        'Location'
                                    ),
                                    value: entity.deviceLocation?.name,
                                },
                                {
                                    key: t(
                                        '6977c293ce5621d4676070ef934ccf23',
                                        'Purchase price'
                                    ),
                                    value: entity.purchasePrice,
                                },
                                {
                                    key: t(
                                        'feb291f0b7bb72df13ea13febd72ea45',
                                        'Battery expire date'
                                    ),
                                    value: (
                                        <EdsTableDate
                                            value={entity?.batteryDate}
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        '1d0258c2440a8d19e716292b231e3190',
                                        'Manager'
                                    ),

                                    value: !_.isNil(
                                        entity.deviceManager?.name
                                    ) ? (
                                        <Link
                                            href=""
                                            renderIcon={() => (
                                                <ArrowRight aria-label="Arrow Right" />
                                            )}
                                            onClick={async (event) => {
                                                event.preventDefault();

                                                const phoneNumbers =
                                                    await getDeviceManagerPhoneNumbers(
                                                        entity.deviceManager?.id
                                                    );

                                                showModal({
                                                    title: t(
                                                        '1d0258c2440a8d19e716292b231e3190',
                                                        'Manager'
                                                    ),
                                                    children: (
                                                        <UmoDeviceManagerDetailGrid
                                                            deviceManager={
                                                                entity.deviceManager
                                                            }
                                                            phoneNumbers={
                                                                phoneNumbers
                                                            }
                                                        />
                                                    ),

                                                    secondaryButtonText: t(
                                                        '716f6b30598ba30945d84485e61c1027',
                                                        'Close'
                                                    ),
                                                    hidePrimaryButton: true,
                                                });
                                            }}
                                        >
                                            {entity.deviceManager?.name}
                                        </Link>
                                    ) : (
                                        '-'
                                    ),
                                },
                            ],
                        },
                    }}
                />
            </EdsContainer>
        </EdsContainerGrid>
    );
};
