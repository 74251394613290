import { Outlet, redirect } from 'react-router';
import { Callback } from '../../features';
import LoginPage from './login-page';
import LogoutPage from './logout-page';
import SelectTenantPage from './select-tenant-page';

const AuthRoutes = {
    path: '/auth',
    element: <Outlet />,
    children: [
        {
            index: true,
            loader: () => redirect('/auth/login'),
        },
        {
            path: 'callback',
            element: <Callback />,
        },
        {
            path: 'login',
            element: <LoginPage />,
        },
        {
            path: 'logout',
            element: <LogoutPage />,
        },
        {
            path: 'select-tenant',
            element: <SelectTenantPage />,
        },
    ],
};

export default AuthRoutes;
