import './eds-error-page.scss';
import { useRouteError } from 'react-router';
import { EdsUISimpleContainer } from '../../../components/';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useEffectOnMount } from '../../../features/react/hooks';
import _ from 'lodash';

export function EdsRouteErrorPage() {
    const { t } = useTranslation();
    const [errorId, setErrorId] = useState('');
    const error = useRouteError();

    let errorText;

    useEffectOnMount(() => {
        if (error.status !== 404) {
            const id = Sentry.captureException(error, { level: 'fatal' });
            setErrorId(id);
        }
    });

    switch (error.status) {
        case 404:
            errorText = t(
                '31a0e60fece8f4bba62738fa5f5bc3a4',
                'Unfortunately, the page you were looking for could not be found.'
            );
            break;
        case 500:
            errorText = t(
                'ff03830ac889464c7bba787bd61fc30d',
                'Internal server error.'
            );
            break;
        default:
            errorText = t(
                '5ce86e44b8dc87f67bc319ee7d30e612',
                'Unknown error: {{description}}',
                {
                    description: error.statusText || error.message,
                }
            );
    }

    return (
        <EdsErrorPage title={error.status} text={errorText} errorId={errorId} />
    );
}

export const EdsErrorPage = ({ title, text, errorId, children }) => {
    const { t } = useTranslation();
    return (
        <div id="eds-error-page">
            <EdsUISimpleContainer logo={true} largeWidth={true}>
                {title && <h1 className="error-title">{title}</h1>}
                {text && <div className="error-text">{text}</div>}
                {errorId && (
                    <div className="error-id">
                        {t(
                            '0e5fe8ff9d36fb595c0259f8ffff0897',
                            'Please include the following reference when reporting this error: {{id}}',
                            { id: errorId }
                        )}
                    </div>
                )}
                <div className="error-img default"></div>

                {!_.isUndefined(children) ? (
                    children
                ) : (
                    <a href="/">
                        {t(
                            '5ffb8e0799174c3f1ee4b2075bb8969d',
                            'Go to the home page'
                        )}
                    </a>
                )}
            </EdsUISimpleContainer>
        </div>
    );
};
