import { useTranslation } from 'react-i18next';
import { Button, ButtonSet, Row } from '@carbon/react';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
    EdsTextArea,
    EdsTextAreaTimestamp,
} from '../../../eds';
import {
    debounce,
    defaultDropdownMappingCallback,
    getAlarmReasonCategories,
    getAlarmReasonCategory,
    getAlarmReasonsByCategory,
    getAlarmTypes,
    getMaintenanceAlarmDetails,
    getRenderedHelpByCategory,
    getRenderedHelpCategories,
    getRenderedHelpCategory,
} from '../../../../features';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useForm } from '../../../eds/eds-form';

export function AdditionalInfoFormGroup({
    prefix,
    compactView = false,
    disabled = false,
    onAdditionalInfoClickCallback,
    onHistoryClickCallback,
    onFollowUpClickCallback,
    ...props
}) {
    const { t } = useTranslation();
    const { checkInputs, form, getFormValue, getMappedForm, updateFormValues } =
        useForm();

    const buttonTimeoutId = useRef(null);
    const alarmReasonCategoryRef = useRef(null);
    const alarmReasonRef = useRef(null);
    const renderedHelpCategoryRef = useRef(null);
    const renderedHelpRef = useRef(null);
    const [defaultCategories, setDefaultCategories] = useState({});
    const [alarmReasonCategoryId, setAlarmReasonCategoryId] = useState();
    const [renderedHelpCategoryId, setRenderedHelpCategoryId] = useState();

    useEffect(() => {
        const setCategories = async () => {
            let requests = [];
            if (!_.isNil(defaultCategories?.reasonCategoryId)) {
                requests.push(
                    getAlarmReasonCategory(defaultCategories?.reasonCategoryId)
                );
            }
            if (!_.isNil(defaultCategories?.renderedHelpCategoryId)) {
                requests.push(
                    getRenderedHelpCategory(
                        defaultCategories?.renderedHelpCategoryId
                    )
                );
            }

            const responses = await Promise.all(requests);

            let renderedHelpCategory;
            if (!_.isNil(defaultCategories?.renderedHelpCategoryId)) {
                renderedHelpCategory = responses.pop();
            }

            let alarmReasonCategory;
            if (!_.isNil(defaultCategories?.reasonCategoryId)) {
                alarmReasonCategory = responses.pop();
            }

            updateFormValues({
                [getPrefixedFieldName('alarmReasonCategoryId')]:
                    defaultDropdownMappingCallback(alarmReasonCategory),
                [getPrefixedFieldName('renderedHelpCategoryId')]:
                    defaultDropdownMappingCallback(renderedHelpCategory),
            });
        };

        if (compactView) {
            updateReasons(defaultCategories?.reasonCategoryId);
            updateRenderedHelp(defaultCategories?.renderedHelpCategoryId);
            setCategories(defaultCategories);
        } else {
            if (!_.isEmpty(alarmReasonCategoryRef?.current?.getItems() ?? [])) {
                alarmReasonCategoryRef?.current?.updateSelectedItem(
                    defaultCategories?.reasonCategoryId
                );
            }

            if (!_.isEmpty(renderedHelpRef?.current?.getItems() ?? [])) {
                renderedHelpCategoryRef?.current?.updateSelectedItem(
                    defaultCategories?.renderedHelpCategoryId
                );
            }
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCategories]);

    useEffect(() => {
        updateReasons(alarmReasonCategoryId);
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alarmReasonCategoryId]);

    useEffect(() => {
        updateRenderedHelp(renderedHelpCategoryId);
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderedHelpCategoryId]);

    const updateSelectedValues = (fieldName, items) => {
        const currentValues = getFormValue(fieldName, prefix) ?? [];
        const newValues = _.filter(currentValues, (value) => {
            return _.find(items, value);
        });

        if (_.size(currentValues) !== _.size(newValues)) {
            updateFormValues({
                [getPrefixedFieldName(fieldName)]: newValues,
            });
        }
    };

    const updateReasons = async (reasonCategoryId) => {
        const reasons = [];
        if (!_.isNil(reasonCategoryId)) {
            const reasonsResponse = await getAlarmReasonsByCategory({
                isActive: true,
                alarmReasonCategoryId: reasonCategoryId,
            });
            if (_.isArray(reasonsResponse)) {
                reasonsResponse.map((data) => {
                    reasons.push(defaultDropdownMappingCallback(data));
                });
            }
        }

        updateSelectedValues('alarmReasonId', reasons);
        alarmReasonRef?.current?.updateItems(reasons);
    };

    const updateRenderedHelp = async (helpCategoryId) => {
        const renderedHelpItems = [];
        if (!_.isNil(helpCategoryId)) {
            const renderedHelpResponse = await getRenderedHelpByCategory({
                inactive: false,
                renderedHelpCategoryId: helpCategoryId,
            });
            if (_.isArray(renderedHelpResponse)) {
                renderedHelpResponse.map((data) => {
                    renderedHelpItems.push(
                        defaultDropdownMappingCallback(data)
                    );
                });
            }
        }

        updateSelectedValues('renderedHelpId', renderedHelpItems);
        renderedHelpRef?.current?.updateItems(renderedHelpItems);
    };

    const clearAlarmTypeDependentFields = () => {
        updateFormValues(
            {
                [getPrefixedFieldName('alarmTypeId')]: {},
                [getPrefixedFieldName('alarmReasonCategoryId')]: {},
                [getPrefixedFieldName('alarmReasonId')]: [],
                [getPrefixedFieldName('renderedHelpCategoryId')]: {},
                [getPrefixedFieldName('renderedHelpId')]: [],
            },
            true
        );
    };

    const getPrefixedFieldName = (name) => {
        return _.isNil(prefix) ? name : `${prefix}.${name}`;
    };

    const testValueRequired = (value, testContext, alarmTypeProp) => {
        const form = testContext?.options?.context?.form;
        const isRequired =
            (_.isNil(prefix)
                ? form?.alarmTypeId?.[alarmTypeProp]
                : form?.[prefix]?.alarmTypeId?.[alarmTypeProp]) ?? false;

        if (!isRequired) {
            return true;
        }

        return _.isArray(value) ? !_.isEmpty(value) : !_.isNil(value?.id);
    };

    const formDefinition = {
        alarmTypeId: {
            validation: {
                type: 'dropdown',
                required: true,
            },
            mapping: 'id',
            dependents: ['alarmReasonId', 'renderedHelpId', 'alarmDetailId'],
        },
        alarmReasonCategoryId: {
            validation: {
                type: 'dropdown',
                required: false,
            },
            mapping: 'id',
        },
        alarmReasonId: {
            validation: {
                type: 'multiselect',
                required: false,
                tests: [
                    {
                        name: 'alarm-reason-required',
                        message: t(
                            '3f5f7f01887d47e3f71bf06e5f475e41',
                            'Field is required'
                        ),
                        func: (value, testContext) => {
                            return testValueRequired(
                                value,
                                testContext,
                                'isReasonRequired'
                            );
                        },
                    },
                ],
            },
            mapping: 'id',
        },
        renderedHelpCategoryId: {
            validation: {
                type: 'dropdown',
                required: false,
            },
            mapping: 'id',
        },
        renderedHelpId: {
            validation: {
                type: 'multiselect',
                required: false,
                tests: [
                    {
                        name: 'rendered-help-required',
                        message: t(
                            '3f5f7f01887d47e3f71bf06e5f475e41',
                            'Field is required'
                        ),
                        func: (value, testContext) => {
                            return testValueRequired(
                                value,
                                testContext,
                                'isRenderedHelpRequired'
                            );
                        },
                    },
                ],
            },
            mapping: 'id',
        },
        alarmDetailId: {
            validation: {
                type: 'multiselect',
                required: false,
                tests: [
                    {
                        name: 'alarm-detail-required',
                        message: t(
                            '3f5f7f01887d47e3f71bf06e5f475e41',
                            'Field is required'
                        ),
                        func: (value, testContext) => {
                            return testValueRequired(
                                value,
                                testContext,
                                'isAlarmDetailRequired'
                            );
                        },
                    },
                ],
            },
            mapping: 'id',
        },
        operatorRemark: {
            validation: {
                required: false,
            },
        },
        memo: {
            validation: {
                required: false,
            },
        },
    };

    const alarmTypeMappginCallback = (data) => {
        return {
            id: data.id,
            text: data.name,
            defaultAlarmReasonCategoryId: data.defaultAlarmReasonCategoryId,
            defaultAlarmRenderedHelpCategoryId:
                data.defaultAlarmRenderedHelpCategoryId,
            isReasonRequired: data.isReasonRequired ?? false,
            isRenderedHelpRequired: data.isRenderedHelpRequired ?? false,
            isAlarmDetailRequired: data.isParticularsRequired ?? false,
        };
    };

    const isAdditionalInfoRequired = () => {
        return (
            compactView &&
            ((getFormFieldValue('alarmTypeId', 'isReasonRequired') &&
                _.isNil(
                    getFormFieldValue(
                        'alarmTypeId',
                        'defaultAlarmReasonCategoryId'
                    )
                )) ||
                (getFormFieldValue('alarmTypeId', 'isRenderedHelpRequired') &&
                    _.isNil(
                        getFormFieldValue(
                            'alarmTypeId',
                            'defaultAlarmRenderedHelpCategoryId'
                        )
                    )) ||
                getFormFieldValue('alarmTypeId', 'isAlarmDetailRequired'))
        );
    };

    const getFormFieldValue = (fieldName, propertyName) => {
        const fieldValue = getFormValue(fieldName, prefix);
        if (_.isNil(propertyName)) {
            return fieldValue;
        } else {
            return fieldValue?.[propertyName];
        }
    };

    const resolveButtonHandler = async (resolve, func, callbackFunc) => {
        return resolve(func(callbackFunc));
    };

    const buttonHandler = async (func, callbackFunc) => {
        return new Promise((resolve) => {
            debounce(buttonTimeoutId, resolveButtonHandler, {
                params: [resolve, func, callbackFunc],
            });
        });
    };

    const handleOnCallbackClick = async (callback) => {
        const isValid = await checkInputs();
        if (_.isFunction(callback)) {
            const mappedForm = await getMappedForm(form);
            callback(mappedForm, isValid);
        }
    };

    return (
        <EdsFormGroup
            prefix={prefix}
            formDefinition={formDefinition}
            inStep={props.wizardStepId ?? false}
            disabled={disabled}
        >
            <Row>
                <EdsFormColumn fullWidth>
                    <EdsDropdown
                        type={EdsDropdownType.ComboBox}
                        hideTitleText={compactView}
                        name={'alarmTypeId'}
                        label={
                            !compactView
                                ? t(
                                      '906eb66e47713fb446120ba0c8622552',
                                      'Alarm type'
                                  )
                                : undefined
                        }
                        placeholder={
                            compactView
                                ? t(
                                      '906eb66e47713fb446120ba0c8622552',
                                      'Alarm type'
                                  )
                                : undefined
                        }
                        getDataCallback={() =>
                            getAlarmTypes({
                                isExcludedFromInsertList: false,
                            })
                        }
                        mappingCallback={alarmTypeMappginCallback}
                        onChangeCallback={(event) => {
                            const alarmType = event?.selectedItem;

                            if (_.isNil(alarmType)) {
                                clearAlarmTypeDependentFields();
                            }

                            setDefaultCategories({
                                reasonCategoryId:
                                    alarmType?.defaultAlarmReasonCategoryId,
                                renderedHelpCategoryId:
                                    alarmType?.defaultAlarmRenderedHelpCategoryId,
                            });
                        }}
                    />
                </EdsFormColumn>
            </Row>

            {(!compactView ||
                _.isNumber(
                    getFormFieldValue(
                        'alarmTypeId',
                        'defaultAlarmReasonCategoryId'
                    )
                )) && (
                <Row>
                    {!compactView && (
                        <EdsFormColumn>
                            <EdsDropdown
                                ref={alarmReasonCategoryRef}
                                type={EdsDropdownType.ComboBox}
                                name={'alarmReasonCategoryId'}
                                label={t(
                                    '7557ed82568c94a202cf9e2ecf4895e8',
                                    'Alarm reason category'
                                )}
                                getDataCallback={() =>
                                    getAlarmReasonCategories({
                                        inactive: false,
                                    })
                                }
                                onChangeCallback={(event) => {
                                    setAlarmReasonCategoryId(
                                        event?.selectedItem?.id
                                    );
                                }}
                            />
                        </EdsFormColumn>
                    )}
                    <EdsFormColumn fullWidth={compactView}>
                        <EdsDropdown
                            ref={alarmReasonRef}
                            type={EdsDropdownType.FilterableMultiSelect}
                            hideTitleText={compactView}
                            name={'alarmReasonId'}
                            label={
                                !compactView
                                    ? t(
                                          'a7539bfca5391465163bec6c759ac873',
                                          'Alarm reason'
                                      )
                                    : undefined
                            }
                            placeholder={
                                compactView
                                    ? t(
                                          'a7539bfca5391465163bec6c759ac873',
                                          'Alarm reason'
                                      )
                                    : undefined
                            }
                            disabledOnEmpty={true}
                            forceRequired={getFormFieldValue(
                                'alarmTypeId',
                                'isReasonRequired'
                            )}
                        ></EdsDropdown>
                    </EdsFormColumn>
                </Row>
            )}

            {(!compactView ||
                _.isNumber(
                    getFormFieldValue(
                        'alarmTypeId',
                        'defaultAlarmRenderedHelpCategoryId'
                    )
                )) && (
                <Row>
                    {!compactView && (
                        <EdsFormColumn>
                            <EdsDropdown
                                ref={renderedHelpCategoryRef}
                                type={EdsDropdownType.ComboBox}
                                name={'renderedHelpCategoryId'}
                                label={t(
                                    '19c835c0f02d474eb12306e9cd43cc28',
                                    'Rendered help category'
                                )}
                                getDataCallback={() =>
                                    getRenderedHelpCategories({
                                        inactive: false,
                                    })
                                }
                                onChangeCallback={(event) => {
                                    setRenderedHelpCategoryId(
                                        event?.selectedItem?.id
                                    );
                                }}
                            />
                        </EdsFormColumn>
                    )}
                    <EdsFormColumn fullWidth={compactView}>
                        <EdsDropdown
                            ref={renderedHelpRef}
                            type={EdsDropdownType.FilterableMultiSelect}
                            name={'renderedHelpId'}
                            label={
                                !compactView
                                    ? t(
                                          '8f6e3b3231c5b56e32bd446303a5b6fe',
                                          'Rendered help'
                                      )
                                    : undefined
                            }
                            placeholder={
                                compactView
                                    ? t(
                                          '8f6e3b3231c5b56e32bd446303a5b6fe',
                                          'Rendered help'
                                      )
                                    : undefined
                            }
                            disabledOnEmpty={true}
                            forceRequired={getFormFieldValue(
                                'alarmTypeId',
                                'isRenderedHelpRequired'
                            )}
                        ></EdsDropdown>
                    </EdsFormColumn>
                </Row>
            )}
            {!compactView && (
                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsDropdown
                            type={EdsDropdownType.FilterableMultiSelect}
                            name={'alarmDetailId'}
                            label={t(
                                '21652abf3ad4366058a823e21404fd88',
                                'Detailed information'
                            )}
                            getDataCallback={getMaintenanceAlarmDetails}
                            forceRequired={getFormFieldValue(
                                'alarmTypeId',
                                'isAlarmDetailRequired'
                            )}
                        />
                    </EdsFormColumn>
                </Row>
            )}

            <Row>
                <EdsFormColumn fullWidth>
                    <EdsTextArea
                        name="operatorRemark"
                        label={
                            !compactView
                                ? t(
                                      '911c185c8f3c475cdca2ef9cf12166da',
                                      'Remark'
                                  )
                                : undefined
                        }
                        placeholder={
                            compactView
                                ? t(
                                      '911c185c8f3c475cdca2ef9cf12166da',
                                      'Remark'
                                  )
                                : undefined
                        }
                        rows={compactView ? 3 : 1}
                    ></EdsTextArea>
                </EdsFormColumn>
            </Row>
            {!compactView && (
                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsTextAreaTimestamp
                            name="memo"
                            label={t(
                                'aad653ca3ee669635f2938b73098b6d7',
                                'Note'
                            )}
                            rows={4}
                        ></EdsTextAreaTimestamp>
                    </EdsFormColumn>
                </Row>
            )}
            {compactView && !_.isNil(getFormFieldValue('alarmTypeId')) && (
                <ButtonSet stacked={true}>
                    <Button
                        kind={isAdditionalInfoRequired() ? 'primary' : 'ghost'}
                        onClick={async () =>
                            buttonHandler(onAdditionalInfoClickCallback)
                        }
                    >
                        {t(
                            'a0449f5787441db5a9a25e917d52fbce',
                            'Add additional information'
                        ) + (isAdditionalInfoRequired() ? ' *' : '')}
                    </Button>
                    <Button
                        kind={
                            !isAdditionalInfoRequired() ? 'primary' : 'tertiary'
                        }
                        onClick={async () =>
                            buttonHandler(
                                handleOnCallbackClick,
                                onHistoryClickCallback
                            )
                        }
                    >
                        {t('3cd15f8f2940aff879df34df4e5c2cd1', 'History')}
                    </Button>
                    <Button
                        kind="tertiary"
                        onClick={async () =>
                            buttonHandler(
                                handleOnCallbackClick,
                                onFollowUpClickCallback
                            )
                        }
                    >
                        {t('ea50dd49e3f04fc0b08874465c0568ed', 'Follow up')}
                    </Button>
                </ButtonSet>
            )}
        </EdsFormGroup>
    );
}
