import './umo-control-closing-information.scss';
import { useTranslation } from 'react-i18next';
import { Alarm } from '@carbon/icons-react';
import { UmoControlPanelComponent } from '../umo-control-panel-component/umo-control-panel-component';
import { EdsForm } from '../../..';
import { useRef } from 'react';
import {
    accountIdHeaderName,
    debounce,
    getLogger,
    scanI18n,
    useAlarms,
    useAuth,
    useModal,
    useNavigate,
    useNotifications,
} from '../../../../features';
import AdditionalInfoForm from './additional-info-form';
import { AdditionalInfoFormGroup } from './additional-info-form-group';
import _ from 'lodash';
import {
    postAlarmDetail,
    postAlarmReason,
    postAlarmRenderedHelp,
    postCloseAlarmToFollowUp,
    postCloseAlarmToHistory,
    putAlarm,
} from '../../../../features/uds/alarm';
import { DateTime } from 'luxon';
import { useIdleTimerContext } from 'react-idle-timer';

const logger = getLogger('UmoControlClosingInformation');

export const EdsCloseActionType = Object.freeze({
    Save: {
        label: scanI18n('43781db5c40ecc39fd718685594f0956', 'Save'),
    },
    History: {
        name: 'Closed',
        label: scanI18n('3cd15f8f2940aff879df34df4e5c2cd1', 'History'),
    },
    FollowUp: {
        name: 'FollowUp',
        label: scanI18n('ea50dd49e3f04fc0b08874465c0568ed', 'Follow up'),
    },
});

export function UmoAlarmControlClosingInformation() {
    const { t } = useTranslation();
    const idleTimer = useIdleTimerContext();
    const navigate = useNavigate();
    const { showSuccess, showError } = useNotifications();
    const { showFormModal } = useModal();
    const { operableAlarm } = useAlarms();
    const { attributes } = useAuth();
    const formRef = useRef(null);
    const modalFormRef = useRef(null);
    const validateFormTimeoutId = useRef(null);

    const showAdditionalInfoModal = (closeAction = EdsCloseActionType.Save) => {
        const formData = { info: formRef?.current?.getState() };

        showFormModal({
            title: t(
                '4eec452dee7d15acfb78a023b487bf19',
                'Additional information'
            ),
            formData: formData,
            formRef: modalFormRef,
            primaryButtonText: t(closeAction?.label),
            onShow:
                closeAction !== EdsCloseActionType.Save
                    ? () => {
                          debounce(
                              validateFormTimeoutId,
                              () => modalFormRef?.current?.validateForm(),
                              {
                                  trailing: true,
                                  delay: 300,
                              }
                          );
                      }
                    : undefined,
            onSubmitFormResponse: async ({ mappedForm, form }, removeModal) => {
                if (form?.info) {
                    formRef?.current?.setSavedState(form.info);

                    debounce(
                        validateFormTimeoutId,
                        () => formRef?.current?.validateForm(),
                        {
                            trailing: true,
                            delay: 300,
                        }
                    );
                }
                removeModal();

                saveAlarm(closeAction, mappedForm?.info, true);
            },
            children: (
                <AdditionalInfoForm
                    ref={modalFormRef}
                    prefix="info"
                    initValues={formData}
                    hideDefaultSubmit={true}
                />
            ),
        });
    };

    // TDOD UMO-723 - Close alarm | use new UDS request to save to history and follow-up
    const saveAlarm = async (closeAction, form, isValid) => {
        if (!isValid) {
            showAdditionalInfoModal(closeAction);
            return;
        }

        if (
            _.isNil(form) ||
            ![EdsCloseActionType.History, EdsCloseActionType.FollowUp].includes(
                closeAction
            )
        ) {
            return;
        }

        const headers = {
            common: {
                [accountIdHeaderName]: umoAccountIdentifierToId(
                    operableAlarm?.data?.account_id
                ),
            },
        };

        const timestamp = DateTime.now().toJSON();
        const operatorName = attributes?.user?.name;
        let requests = [];

        try {
            // Alarm
            requests.push(
                putAlarm(
                    {
                        id: operableAlarm?.id,
                        alarmTypeId: form?.alarmTypeId,
                        operatorRemarks: form?.operatorRemark,
                        memo: form?.memo,
                        status: closeAction.name,
                    },
                    headers
                )
            );

            // Reasons
            requests = requests.concat(
                form?.alarmReasonId?.map((reasonId) =>
                    postAlarmReason(
                        operableAlarm?.id,
                        {
                            reasonId,
                            operatorName,
                            timestamp,
                        },
                        headers
                    )
                )
            );

            // Rendered help
            requests = requests.concat(
                form?.renderedHelpId?.map((renderedHelpId) =>
                    postAlarmRenderedHelp(
                        operableAlarm?.id,
                        {
                            renderedHelpId,
                            operatorName,
                            timestamp,
                        },
                        headers
                    )
                )
            );

            // Details
            requests = requests.concat(
                form?.alarmDetailId?.map((alarmDetailId) =>
                    postAlarmDetail(
                        operableAlarm?.id,
                        {
                            alarmDetailId,
                            operatorName,
                            timestamp,
                        },
                        headers
                    )
                )
            );

            // Save closing information in UDS
            await Promise.all(requests);

            // Close RTA alarm
            if (closeAction == EdsCloseActionType.History) {
                await postCloseAlarmToHistory(operableAlarm?.id);
            } else if (closeAction == EdsCloseActionType.FollowUp) {
                await postCloseAlarmToFollowUp(operableAlarm?.id);
            }

            idleTimer.resume();
            logger.log('Resumed idleTimer');

            showSuccess({
                title: 'Closed',
                content: t(
                    'cfeb52464fe9a3d3e5e16c7e12042c40',
                    'Alarm is sent to {{closeAction}}',

                    {
                        closeAction: t(closeAction?.label),
                    }
                ),
            });

            formRef?.current?.resetForm();
            navigate('/alarm');
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    const umoAccountIdentifierToId = (umoAccountIdentifier) => {
        const account = attributes?.accounts?.find(
            (item) => `${item.umoAccountIdentifier}` === umoAccountIdentifier
        );
        return account?.id;
    };

    return (
        <UmoControlPanelComponent
            icon={Alarm}
            title={t(
                '45bd120933702f2d0a5067a38e184650',
                'Alarm closing information'
            )}
            className="alarm-control-closing-information"
        >
            <EdsForm hideDefaultSubmit={true} initValues={{}} ref={formRef}>
                <AdditionalInfoFormGroup
                    compactView={true}
                    disabled={_.isNil(operableAlarm?.id)}
                    onAdditionalInfoClickCallback={() =>
                        showAdditionalInfoModal()
                    }
                    onHistoryClickCallback={async (form, isValid) =>
                        saveAlarm(EdsCloseActionType.History, form, isValid)
                    }
                    onFollowUpClickCallback={async (form, isValid) =>
                        saveAlarm(EdsCloseActionType.FollowUp, form, isValid)
                    }
                />
            </EdsForm>
        </UmoControlPanelComponent>
    );
}
