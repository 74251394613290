import { useRouteLoaderData } from 'react-router';
import {
    EdsContainerGrid,
    UmoAlarmsTable,
    UmoDeviceAliveCalls,
    UmoDeviceAliveGrid,
    UmoDeviceDetailGrid,
} from '../../../components';
import { getDeviceAliveCalls } from '../../../features';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

function DevicesDetailsTab() {
    const { t } = useTranslation();
    const { accountId, device } = useRouteLoaderData(
        'alarm-detail/devices/uid'
    );

    if (_.isNil(device) || _.isEmpty(device)) {
        return <EdsContainerGrid />;
    }

    return (
        <EdsContainerGrid>
            <UmoDeviceDetailGrid
                accountId={accountId}
                entity={device}
                onHeaderEditClick={undefined}
            />

            <UmoAlarmsTable
                title={t('c71231a6be114b5166980b8e7484402e', 'Alarm history')}
                baseFilter={`deviceId eq ${device.id}`}
            />

            <UmoDeviceAliveGrid entity={device} />

            <UmoDeviceAliveCalls
                getDataCallback={() => getDeviceAliveCalls(device.id)}
            />
        </EdsContainerGrid>
    );
}

export default DevicesDetailsTab;
