import { forwardRef, useState } from 'react';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsNumberInput,
    EdsTextInput,
} from '../../../eds';
import { useTranslation } from 'react-i18next';
import {
    getExternalIdentifierTypes,
    putExternalIdentifier,
    testSocialSecurityNumber,
    useNotifications,
} from '../../../../features';
import { Row } from '@carbon/react';
import _ from 'lodash';

const UmoExternalIdentifiersForm = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { showSuccess, showError } = useNotifications();
    const [validationMethodDescription, setValidationMethodDescription] =
        useState('');

    const externalIdentifierTypeMappingCallback = (data) => {
        return {
            id: data.id,
            text: data.description,
            isUnique: data?.isUnique ?? false,
            validationMethodId: data?.validationMethod?.id ?? null,
            validationMethodDescription:
                data?.validationMethod?.description ?? '',
        };
    };

    const formDefinition = {
        sortIndex: {
            value: '',
            validation: {
                type: 'number',
                min: 0,
                required: false,
            },
        },
        externalIdentifierTypeId: {
            validation: {
                type: 'dropdown',
                required: true,
            },
            dependents: ['value'],
            mapping: 'id',
        },
        value: {
            validation: {
                required: true,
                max: 20,
                tests: [
                    {
                        name: 'social-security-number-validation',
                        message: '', // Custom error using testContext.createError(...)
                        func: async (value, testContext) => {
                            const externalIdentifierType =
                                testContext?.options?.context?.form
                                    ?.externalIdentifierTypeId;

                            if (_.isUndefined(externalIdentifierType)) {
                                return true;
                            }

                            const validationConfig = {
                                isUnique: externalIdentifierType?.isUnique,
                                externalIdentifierTypeId:
                                    externalIdentifierType?.id,
                                validationMethodId:
                                    externalIdentifierType?.validationMethodId,
                                validationMethodDescription:
                                    externalIdentifierType?.validationMethodDescription,
                            };

                            const errorMessage = await testSocialSecurityNumber(
                                value,
                                validationConfig,
                                t
                            );

                            if (_.isEmpty(errorMessage)) {
                                return true;
                            } else {
                                return testContext.createError({
                                    message: errorMessage,
                                });
                            }
                        },
                    },
                ],
            },
        },
    };

    const saveData = async (form) => {
        if (_.isEmpty(form.sortIndex)) {
            //sortIndex can be empty but when empty it must be set to 0 for UDS
            form.sortIndex = 0;
        }

        if (!form.id) {
            return await props.postDataCallback(form);
        } else {
            return await putExternalIdentifier(form);
        }
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }
        try {
            const response = await saveData(form);
            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: t(
                            'c0859fe7549ab1695430620d2db009ce',
                            'External identifier'
                        ),
                        name: form.subject,
                    }
                ),
            });

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn>
                        <EdsNumberInput
                            name="sortIndex"
                            label={t(
                                '70a17ffa722a3985b86d30b034ad06d7',
                                'Order'
                            )}
                        ></EdsNumberInput>
                    </EdsFormColumn>
                </Row>
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            name="externalIdentifierTypeId"
                            label={t(
                                '599dcce2998a6b40b1e38e8c6006cb0a',
                                'Type'
                            )}
                            type={EdsDropdownType.ComboBox}
                            getDataCallback={() =>
                                getExternalIdentifierTypes({
                                    isInactive: false,
                                })
                            }
                            mappingCallback={
                                externalIdentifierTypeMappingCallback
                            }
                            onChangeCallback={(event) => {
                                setValidationMethodDescription(
                                    event?.selectedItem
                                        ?.validationMethodDescription ?? ''
                                );
                            }}
                        />
                    </EdsFormColumn>
                    {!_.isEmpty(validationMethodDescription) && (
                        <EdsFormColumn>
                            <EdsTextInput
                                name="validationMethodDescription"
                                label={t(
                                    '859ada64eceea53401403388f935a67e',
                                    'Validation method'
                                )}
                                uncontrolledValue={validationMethodDescription}
                                readOnly={true}
                            />
                        </EdsFormColumn>
                    )}
                </Row>
                <Row>
                    <EdsFormColumn>
                        <EdsTextInput name="value" label={'Value'} />
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});
UmoExternalIdentifiersForm.displayName = 'ExternalIdentifiersForm';

export default UmoExternalIdentifiersForm;
