import _ from 'lodash';
import { createSearchParams } from 'react-router';
import { scanI18n } from '.';
import { stateManagement as careTeamStateManagement } from '../uds/care-team';
import { stateManagement as clientStateManagement } from '../uds/client';
import { stateManagement as deviceStateManagement } from '../uds/device';
import { stateManagement as peripheralStateManagement } from '../uds/peripheral';
import { stateManagement as professionalCaregiverStateManagement } from '../uds/professional-caregiver';
import { stateManagement as residenceStateManagement } from '../uds/residence';
import { stateManagement as schemeStateManagement } from '../uds/scheme';

export const EdsOriginSearchParam = Object.freeze({
    OriginType: 'ot',
    OriginId: 'oi',
    OriginalOriginId: 'ooi',
    TargetOriginType: 'tot',
    TargetData: 'td',
    OpenAction: 'open',
});

export const EdsOriginType = Object.freeze({
    CareTeamWizard: {
        name: 'ctw',
        path: '/crm/care-team/new',
        labels: {
            type: scanI18n('6e9de986c9d59a1e3f6284d77adbcb3e', 'Care team'),
            wizard: scanI18n(
                '7ef58b168f52676b019f93b0a93e2cbc',
                'care team wizard'
            ),
        },
        stateManagement: {
            name: 'care-teams',
            api: careTeamStateManagement,
        },
    },
    ClientWizard: {
        name: 'cw',
        path: '/crm/client/new',
        labels: {
            type: scanI18n('62608e08adc29a8d6dbc9754e659f125', 'Client'),
            wizard: scanI18n(
                'e126b5aab5d56e41808d9bad27a7bf8a',
                'client wizard'
            ),
        },
        stateManagement: {
            name: 'clients',
            api: clientStateManagement,
        },
    },
    DeviceWizard: {
        name: 'dw',
        path: '/crm/device/new',
        labels: {
            type: scanI18n('913f9c49dcb544e2087cee284f4a00b7', 'Device'),
            wizard: scanI18n(
                '486b059ba70f77fde2c64f2eec97030e',
                'device wizard'
            ),
        },
        stateManagement: {
            name: 'devices',
            api: deviceStateManagement,
        },
    },
    PeripheralWizard: {
        name: 'pw',
        path: '/crm/peripheral/new',
        labels: {
            type: scanI18n('dfef988e5e51db5a86acc045d907c1c7', 'Peripheral'),
            wizard: scanI18n(
                'f70600b871dafc2fb08049b767d3ca64',
                'peripheral wizard'
            ),
        },
        stateManagement: {
            name: 'peripherals',
            api: peripheralStateManagement,
        },
    },
    ProfessionalCaregiverWizard: {
        name: 'pcw',
        path: '/crm/professional-caregiver/new',
        labels: {
            type: scanI18n(
                '599bfff4492f40356f6e8c9eb47f6996',
                'Professional caregiver'
            ),
            wizard: scanI18n(
                'e55fa5ab0aea7807edbb723cc30b4e37',
                'professional caregiver wizard'
            ),
        },
        stateManagement: {
            name: 'professional-caregivers',
            api: professionalCaregiverStateManagement,
        },
    },
    ResidenceWizard: {
        name: 'rw',
        path: '/crm/residence/new',
        labels: {
            type: scanI18n('cad4811efcdb1000aae7a6841d995713', 'Residence'),
            wizard: scanI18n(
                '4bfe3acfe1100e0709a8db970e382a20',
                'residence wizard'
            ),
        },
        stateManagement: {
            name: 'residences',
            api: residenceStateManagement,
        },
    },
    SchemeWizard: {
        name: 'sw',
        path: '/crm/scheme/new',
        labels: {
            type: scanI18n('41323917ef8089432959a3c33269debf', 'Scheme'),
            wizard: scanI18n(
                'ae399b6f91d9c836db6c13b5f212de33',
                'scheme wizard'
            ),
        },
        stateManagement: {
            name: 'schemes',
            api: schemeStateManagement,
        },
    },
    CareTeamLinkProfessionalCaregiver: {
        name: 'ctlp',
        path: '/crm/care-team/{{id}}/members',
        search: { [EdsOriginSearchParam.OpenAction]: 'ctlp' },
        labels: {
            type: scanI18n(
                '11cd56f1f286da92eb16b7c515b6da3f',
                'Care team to professional caregiver link'
            ),
        },
    },
    ClientLinkDevice: {
        name: 'cld',
        path: '/crm/client/{{id}}/devices',
        search: { [EdsOriginSearchParam.OpenAction]: 'cld' },
        labels: {
            type: scanI18n(
                '8474af7de5d006e823cdd412a0b6650a',
                'Client to device link'
            ),
        },
    },
    ClientLinkProfessionalCaregiver: {
        name: 'clp',
        path: '/crm/client/{{id}}/caregivers',
        search: { [EdsOriginSearchParam.OpenAction]: 'clp' },
        labels: {
            type: scanI18n(
                '4a74e85d97ae5178c123ca986a367e87',
                'Client to professional caregiver link'
            ),
        },
    },
    ClientLinkResidence: {
        name: 'clr',
        path: '/crm/client/{{id}}',
        search: { [EdsOriginSearchParam.OpenAction]: 'clr' },
        labels: {
            type: scanI18n(
                'd9ee18caa6c51a2c94bc390166820bc3',
                'Client to residence link'
            ),
        },
    },
    DeviceLinkPeripheral: {
        name: 'dlp',
        path: '/crm/device/{{id}}/peripherals',
        search: { [EdsOriginSearchParam.OpenAction]: 'dlp' },
        labels: {
            type: scanI18n(
                'f9760e735ed7234479b6ebf88db90a0d',
                'Device to peripheral link'
            ),
        },
    },
    ResidenceLinkDevice: {
        name: 'rld',
        path: '/crm/residence/{{id}}/devices',
        search: { [EdsOriginSearchParam.OpenAction]: 'rld' },
        labels: {
            type: scanI18n(
                '4774f9e9e45df03ff8190db4df123529',
                'Residence to device link'
            ),
        },
    },
    SchemeLinkResidence: {
        name: 'slr',
        path: '/crm/scheme/{{id}}/residences',
        search: { [EdsOriginSearchParam.OpenAction]: 'slr' },
        labels: {
            type: scanI18n(
                'e6d4ca9cec46ccbee684d0396214be21',
                'Scheme to residence link'
            ),
        },
    },
});

export const getEdsOriginTypeByName = (name) => {
    return _.keys(EdsOriginType).reduce((obj, key) => {
        if (EdsOriginType[key]?.name === name) {
            obj = EdsOriginType[key];
        }
        return obj;
    }, undefined);
};

export const getEdsOriginTypeLabelByName = (t, originName, labelName) => {
    const originType = getEdsOriginTypeByName(originName);
    return originType ? t(originType.labels[labelName]) : '';
};

export const getNavigateToTarget = (toPath, wizardIdData) => {
    return {
        pathname: toPath,
        search: `${createSearchParams({
            // origin
            [EdsOriginSearchParam.OriginType]: wizardIdData?.typeName,
            [EdsOriginSearchParam.OriginId]: wizardIdData?.id,
        })}`,
    };
};

const getOriginNavigation = (wizardIdData) => {
    if (_.isNil(wizardIdData?.originTypeName)) {
        return;
    }

    const originType = getEdsOriginTypeByName(wizardIdData?.originTypeName);
    if (_.isNil(originType)) {
        return;
    }

    let path = originType?.path;
    if (path.indexOf('{{id}}') !== -1) {
        path = path.replace(/\{\{id\}\}/, wizardIdData?.originId);
    }

    return {
        path,
        search: originType?.search,
    };
};

export const getNavigateToOrigin = (to, wizardIdData, targetData) => {
    if (!_.isNil(wizardIdData?.originTypeName)) {
        const originNavigation = getOriginNavigation(wizardIdData);
        return {
            pathname: originNavigation?.path,
            search: `${createSearchParams({
                //  origin id to match (back to origin)
                [EdsOriginSearchParam.OriginalOriginId]: wizardIdData?.originId,
                // target wizard (back to origin, coming from target)
                [EdsOriginSearchParam.TargetOriginType]: wizardIdData?.typeName,
                ...(!_.isNil(targetData) && {
                    [EdsOriginSearchParam.TargetData]:
                        JSON.stringify(targetData),
                }),
                ...originNavigation?.search,
            })}`,
        };
    } else {
        return {
            pathname: to + (_.isNil(targetData?.id) ? '' : `/${targetData.id}`),
        };
    }
};

export const createDeviceFilterFromTargetData = (targetData) => {
    if (_.isNil(targetData?.deviceCode)) {
        return;
    }

    return {
        deviceCode: { f: targetData.deviceCode, t: targetData.deviceCode },
    };
};

export const createPeripheralFilterFromTargetData = (targetData) => {
    if (_.isNil(targetData?.serialNumber)) {
        return;
    }

    return {
        'peripheralType/id': { v: [targetData?.peripheralTypeId] },
        serialNumber: { v: targetData?.serialNumber },
        'deviceState/id': { v: [targetData?.deviceStateId] },
    };
};

export const createProfessionalCaregiverFilterFromTargetData = (targetData) => {
    if (_.isNil(targetData?.lastName)) {
        return;
    }

    return {
        'person/lastName': { v: targetData?.lastName },
        caregiverTypeId: { v: [targetData?.caregiverTypeId] },
        'address/': {
            v: {
                country: targetData.address?.countrId,
                city: targetData.address?.cityId,
            },
        },
        ...(!_.isEmpty(targetData.address?.houseNumber) && {
            'address/houseNumber': {
                v: targetData.address?.houseNumber,
            },
        }),
    };
};

export const createResidenceFilterFromTargetData = (targetData) => {
    if (_.isNil(targetData?.address)) {
        return;
    }

    return {
        'address/streetName': { v: targetData.address?.streetName },
        'address/': {
            v: {
                country: targetData.address?.countrId,
                city: targetData.address?.cityId,
            },
        },
        ...(!_.isEmpty(targetData.address?.houseNumber) && {
            'address/houseNumber': {
                f: targetData.address?.houseNumber,
                t: targetData.address?.houseNumber,
            },
        }),
    };
};

export const deleteAllOriginSearchParams = (searchParams) => {
    _.forOwn(EdsOriginSearchParam, (value) => {
        searchParams.delete(value);
    });

    return searchParams;
};
