import { useEffect } from 'react';
import {
    getLogger,
    useAuth,
    alarmDetailsRouterDataId,
} from '../../../features';
import { Outlet, useRouteLoaderData } from 'react-router';
import _ from 'lodash';

const logger = getLogger('AlarmAccountSetter');
/*
 * Set the AccountId in the AuthContext for the current open alarm
 */
export const AlarmAccountSetter = () => {
    const routerData = useRouteLoaderData(alarmDetailsRouterDataId);
    const { accountId, selectAccount } = useAuth();

    useEffect(() => {
        if (!_.isUndefined(routerData) && accountId !== routerData.accountId) {
            logger.log('SetAccountId', routerData.accountId);
            selectAccount(routerData.accountId);
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerData.accountId]);

    return <Outlet />;
};
