import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../../components/eds/eds-form';
import _ from 'lodash';
import {
    EdsFormGroup,
    EdsNotification,
    EdsWizardStep,
    UmoSearchClientTable,
} from '../../../../components';

function ClientSearchStep({ id, selectedRow, baseFilter, ...props }) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(selectedRow);
    const { updateFormValues, isInvalid } = useForm();
    const tableRef = useRef(null);

    useEffect(() => {
        props.selectedRowCallback(selected);
    }, [props, selected]);

    useEffect(() => {
        if (!_.isUndefined(selected) && _.isString(selected)) {
            const rowData = tableRef?.current?.getRowData(selected);
            let accountId = rowData?.accountId;
            if (_.isNil(accountId)) {
                return;
            }
            let defaultDeviceId = rowData?.defaultDevice?.props?.id;
            let residenceId = rowData?.address?.props?.id;
            updateFormValues({
                ['client.clientId']: selected,
                ['client.accountId']: accountId,
                ['client.defaultDeviceId']: defaultDeviceId,
                ['client.residenceId']: residenceId,
            });
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const formDefinition = {
        wizardStepId: id,
        clientId: {
            validation: {
                required: true,
            },
        },
    };

    return (
        <EdsWizardStep
            id={id}
            label={t('467ac6e261eefe408b7bd81ce93ba08c', 'Search client')}
        >
            <EdsFormGroup
                formDefinition={formDefinition}
                inStep={true}
                prefix="client"
            >
                {isInvalid('client.clientId') && (
                    <EdsNotification
                        notification={{
                            hideCloseButton: true,
                            kind: 'warning',
                            title: t(
                                '4a4473eb1bd9a915db896c7320fccf86',
                                'Please select a client to create an alarm for'
                            ),
                            inline: true,
                        }}
                    />
                )}
                <UmoSearchClientTable
                    ref={tableRef}
                    onRowClick={(row) => {
                        if (row.id !== selected) {
                            setSelected(row.id);
                        }
                    }}
                    onSelectionButtonClick={(row) => {
                        if (row.id !== selected) {
                            setSelected(row.id);
                        }
                    }}
                    selected={[selected]}
                    baseFilter={baseFilter}
                    searchAllAccounts={true}
                    enableRadioSelection={true}
                    size="lg"
                    enableSearchParams={false}
                    searchFilterStore={undefined}
                    fetchServerSideDataOnInit={true}
                    enableSearch={true}
                />
            </EdsFormGroup>
        </EdsWizardStep>
    );
}

export default ClientSearchStep;
