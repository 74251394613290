import { scanI18n } from '.';
// Use full path for this import to prevent a circular reference error in the headless browsers unit tests
import { EdsTableStates } from '../../components/eds/eds-table/eds-table-state/eds-table-states';

export const EdsAliveStates = Object.freeze({
    NoMonitoring: {
        name: 'NoMonitoring',
        state: EdsTableStates.None,
        label: scanI18n('20824b689a875de180b13becc2ea2a2d', 'No monitoring'),
    },
    AliveReceived: {
        name: 'AliveReceived',
        state: EdsTableStates.Valid,
        label: scanI18n('bee02d0a63e1579a9cb97b506d668376', 'Alive received'),
    },
    NoAliveReceived: {
        name: 'NoAliveReceived',
        state: EdsTableStates.Invalid,
        label: scanI18n(
            '71d941c06bf060b5daebf8cd321422b8',
            'No alive received'
        ),
    },
    WaitingForFirstAlive: {
        name: 'WaitingForFirstAlive',
        state: EdsTableStates.Indeterminate,
        label: scanI18n(
            '18c3f502b0abbb09ddece9cf6e9587ba',
            'Waiting for first alive'
        ),
    },
});
